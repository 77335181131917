export const getStyles = theme => {
    return {
        container: {
            height: "calc(100vh - 64px)",
            padding: "20px",
            width: "90%",
            margin: "0 auto",
            [theme.breakpoints.down('md')]: {
                height: "auto"
            },
            [theme.breakpoints.down('sm')]: {
                display: "block",
            },
            [theme.breakpoints.down('xs')]: {
                padding: "5px 0px",
            },
        },
        left: {
            height: "100%",
            display: "flex",
            direction: "column",
            justifyContent: "center",
            [theme.breakpoints.down('md')]: {
                height: "auto",
            },
        },
        right: {
            display: "flex",
            direction: "row",
            height: "100%",
            [theme.breakpoints.down('md')]: {
                height: "auto",
                display: "block",
                marginTop: 0,
            },
        },
        screen: {
            height: "70%",
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            border: '1px solid lightgray',
            borderRadius: 8,
            [theme.breakpoints.down('md')]: {
                height: "auto",
                display: "block",
                minHeight: "35vh",
            },
            [theme.breakpoints.down('sm')]: {
                minHeight: "24vh",
            }
        },
        videoContainer: {
            position: "relative",
            minWidth: "99%",
            height: "99%"
        },
        infoContainer: {
            height: "30%",
            paddingTop: "20px",
            margin: "10px, 0px",
            justifyContent: "space-between",
            [theme.breakpoints.down('md')]: {
                position: "fixed",
                bottom: 100,
                marginBottom: 0,
                zIndex: 2,
                overflowX: "auto",
                width: "85%",
                height: "auto",
                paddingTop: 0,
            },
        },
        classBox: {
            border: "1px solid lightgray",
            borderRadius: "8px",
            height: "100%",
            overflowY: "auto",
            [theme.breakpoints.down('md')]: {
                display: "none",
                position: "relative",
                zIndex: 0,
                backgroundColor: "#fff",
            },
        },
        box: {
            borderRadius: 8,
            height: "100%",
            [theme.breakpoints.down('md')]: {
                display: "none",
                position: "relative",
                zIndex: 0,
                backgroundColor: "#fff",
            },
        },
        commonBox:{
            height: "100%",
            overflow: "hidden",
            overflowY: "auto",
            borderRadius: 8,
            [theme.breakpoints.down('md')]: {
                height: "auto",
                width: "100%",
                minHeight: 100
            }
        },
        infoBox: {
            border: "1px solid lightgray"
        },
        highlightBox:{
            border: `1px solid ${theme.palette.primary.main}`,
            backgroundColor: "rgba(4, 159, 227, 0.1)"
        },
        screenshotBox: {
            borderRadius: "8px",
            height: "100%",
            border: "1px solid lightgray",
            [theme.breakpoints.down('md')]: {
                //    display: "none",
                position: "relative",
                //    zIndex: 2,
                //   maxHeight: "40vh",
            },
            [theme.breakpoints.down('sm')]: {
                //  display: "none",
                position: "relative",
                //  zIndex: 2,
            },

        },
        rightContainer: {
            display: "flex",
            direction: "column",
            gap: "20px",
            height: "100%",
            [theme.breakpoints.down('lg')]: {
                gap: 0,
                display: "block",
                margin: "5px auto",
                position: "relative",
            },
        },
        smallScreen: {
            width: "100%",
            height: "30%",
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            border: "1px solid lightgray",
            borderRadius: 8,
            position:"relative",
            [theme.breakpoints.down('md')]: {
                minHeight: "60vh",
            },
            [theme.breakpoints.down('sm')]: {
                minHeight: "24vh",
            }
        },
        chat: {
            border: "1px solid lightgray",
            width: "100%",
            height: "calc(70% - 20px)",
            borderRadius: 8,
            [theme.breakpoints.down('md')]: {
                display: "none",
                border: "0px solid lightgray",
                position: "fixed",
                bottom: 100,
                left: "7.5%",
                zIndex: 0,
                width: "85%",
            },
        },
        switch: {
            height: "calc(100vh - 169px)",
            margin: "0 auto",
            [theme.breakpoints.down('md')]: {
                display: "none",
            },
        },
        switchButton: {
            marginTop: "9vh",
            width: "70%",
        },
        iconSwitchButton: {
            fontSize: '3rem',
        },
        videoShort: {
            zIndex: 2,
        },
        footer: {
            marginBottom: 80,
        }
    }
}