import React from "react";
import './ChatBoxStyles.css';
import SendRoundedIcon from '@material-ui/icons/Send';
import {IconButton} from "@material-ui/core";
import {useIntl} from "react-intl";
import {styles} from "./ChatBoxStyles";
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => styles(theme));

export default function ChatBoxComponent({text, handleTextChange, sendMessage}) {
    const intl = useIntl();
    const classes = useStyles();
    return (
        <div className={classes.chatContainer}>
            <input
                type="text"
                value={text}
                placeholder={intl.formatMessage({id: 'chat_send_helper'})}
                className={classes.formControl}
                onChange={handleTextChange}
                onKeyDown={handleTextChange}
            />
            <IconButton onClick={() => sendMessage()}>
                <SendRoundedIcon/>
            </IconButton>
        </div>
    )
}

ChatBoxComponent.propTypes = {
    text: PropTypes.string,
    handleTextChange: PropTypes.func.isRequired,
};