import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => {
    return {
        root: {
            textAlign: 'left',
            margin: '24px',
            marginLeft: '24px',
            marginBottom: '40px',
            [theme.breakpoints.down('md')]: {
                textAlign: 'center',
                margin: '34px 0'
            }
        },
        titlePage: {
            fontSize: '1.5rem',
            [theme.breakpoints.down('md')]: {
                fontSize: '2rem',
            }
        },
        subtitle: {
            fontSize: '0.7rem',
            color: '#5c5c5c',
            textTransform: "uppercase",
        }
    }
});