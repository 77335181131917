import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux'

import {ReactKeycloakProvider} from '@react-keycloak/web'
import keycloak from './utils/keycloak'

import './index.css';
import App from './App';

import {EDUCAM_VERSION} from "./utils/constants";

import store from './store';

global.educam_version = EDUCAM_VERSION;
render(
    <ReactKeycloakProvider authClient={keycloak}>
        <Provider store={store}>
                <App/>
        </Provider>
    </ReactKeycloakProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
