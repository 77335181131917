import React, {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom';
import {useIntl} from "react-intl";
import {useKeycloak} from "@react-keycloak/web";
import {buildUrl, get} from "../../utils/axiosRequests";
import {
    ROLES_NOT_STUDENT,
    SEVERITY_ERROR,
    TIIVII_API_GET_INSTITUTIONS,
} from "../../utils/constants";
import {showMessage} from "../common/NotificationSnack";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import withAuthorization from "../common/auth/withAuthorization";
import {ROUTE_INSTITUTION} from "../../utils/routes";
import PageHeaderComponent from "../common/pageHeaderComponent/PageHeaderComponent";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "../../AppStyles";
import {Hidden} from "@material-ui/core";

const useStyles = makeStyles(theme => styles(theme));


function InstitutionsListComponent() {
    const [institutions, setInstitutions] = useState([]);
    const {keycloak, initialized} = useKeycloak();
    const intl = useIntl();
    const history = useHistory();
    const classes = useStyles();

    useEffect(() => {
        if (initialized && keycloak.authenticated) {
            get(TIIVII_API_GET_INSTITUTIONS, {})
                .then(response => {
                    setInstitutions(response.data);
                })
                .catch(error => showMessage(error, SEVERITY_ERROR));
        }
    }, [initialized, keycloak]);


    const NAME_COLUMN = 'institution';
    const ID_COLUMN = 'description';
    const ALIGN_COLUMN = 'left';
    const WIDTH_COLUMN = 'auto';
    const SECTION_TITTLE = intl.formatMessage({id: 'institution_list'});
    const columns = [
        {id: ID_COLUMN, label: NAME_COLUMN, align: ALIGN_COLUMN, minWidth: WIDTH_COLUMN},
    ];

    const handleGoToInstitution = (institutionId) => history.push(buildUrl(ROUTE_INSTITUTION, {institution_id: institutionId}));

    return (
        <div className={'pageContainer'}>
            <PageHeaderComponent title={SECTION_TITTLE}/>
            <Paper elevation={5} className={classes.tableList}>
                <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader aria-label="sticky table">
                        <Hidden mdDown>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{minWidth: column.minWidth}}
                                            className={classes.tableHeader}
                                        >
                                            {intl.formatMessage({id: column.label})}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                        </Hidden>
                        <TableBody>
                            {institutions.map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}
                                              className={classes.tableRow}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id + row.id} align={column.align}
                                                           onClick={() => handleGoToInstitution(row.id)}
                                                           className={classes.tableCell}>
                                                    {column.format && typeof value === 'number' ? column.format(value) : value}

                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}


export default withAuthorization(ROLES_NOT_STUDENT)(InstitutionsListComponent)
