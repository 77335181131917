import React, {useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {useKeycloak} from "@react-keycloak/web";

import {hasRole} from "../../utils/keycloak";
import {ROLES_ADMIN_MANAGER} from "../../utils/constants";
import {ROUTE_INSTITUTIONS, ROUTE_ROOMS} from "../../utils/routes";
import LoginComponent from "./components/loginComponent/LoginComponent";


function LandingComponent() {
    const {keycloak, initialized} = useKeycloak();
    const history = useHistory();

    const isAdmin = hasRole(ROLES_ADMIN_MANAGER);

    useEffect(() => {
        if (initialized) {
            if (isAdmin) {
                history.push(ROUTE_INSTITUTIONS);
            } else if(keycloak.authenticated) {
                history.push(ROUTE_ROOMS);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialized, keycloak]);

    return <LoginComponent/>
}

export default LandingComponent;
