import { useKeycloak } from '@react-keycloak/web';
import PropTypes from "prop-types";
import {hasRoles} from "../../../utils/keycloak";


export default function AuthorizedElement({ roles, children }) {
    const {keycloak} = useKeycloak();
    const isAuthorized = () => {
        if (keycloak && roles) {
            return hasRoles(roles);
        }
        return false;
    }

    return isAuthorized() && children
}

AuthorizedElement.propTypes = {
    roles: PropTypes.array.isRequired,
    children: PropTypes.any.isRequired,
};
