import React, {useEffect, useState} from "react";
import {buildUrl, get} from "../../../../utils/axiosRequests";
import {TIIVII_API_ROOM_EVENT} from "../../../../utils/constants";
import {useIntl} from "react-intl";
import {DataGrid} from "@material-ui/data-grid";
import {ALIGN_COLUMN_CENTER, HISTORY_TABLA_PAGE_ITEMS} from "../../HistoryTableConstants";
import EmptyTableComponent from "../emptyTableComponent/EmptyTableComponent";
import {makeStyles} from "@material-ui/core/styles";
import {formatTime} from "../../../../utils/utils";

import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
    chatPage: {
        minHeight: '65vh',
        width: '100%',
        margin: '0 auto',
    },
    root: {
        '& .MuiDataGrid-colCellTitle': {
            fontWeight: 'bold'
        },
        '& .MuiDataGrid-colCellWrapper ': {
            backgroundColor: '#fafafa'
        },
        '& .MuiDataGrid-colCell-draggable, .MuiDataGrid-cellCenter': {
            justifyContent: 'center'
        }
    },
}));

function ChatModalContentComponent({roomId, eventId, columnWidth}) {
    const [chatData, setChatData] = useState([]);
    const intl = useIntl();
    const classes = useStyles();
    const getData = () => {
        get(buildUrl(TIIVII_API_ROOM_EVENT, {room_id: roomId, event_id: eventId}), {})
            .then(response => {
                setChatData(response.data.chat_messages)
            })
            .catch(error => console.log(error));

    }
    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomId, eventId]);

    const {regularColumn, buttonColumn} = columnWidth

    const columns = [
        {
            field: 'timestamp',
            label: 'timestamp',
            headerName: intl.formatMessage({id: 'chat_modal_timestamp'}).toUpperCase(),
            align: ALIGN_COLUMN_CENTER, width: regularColumn,
            renderCell: (params) => {
                return <span>{params.data.timestamp ? formatTime(params.data.timestamp) : "-"}</span>;
            }
        },
        {
            field: 'user',
            label: 'user',
            headerName: intl.formatMessage({id: 'chat_modal_user'}).toUpperCase(),
            align: ALIGN_COLUMN_CENTER, width: regularColumn,
        },
        {
            field: 'text',
            label: 'text',
            headerName: intl.formatMessage({id: 'chat_modal_text'}).toUpperCase(),
            align: ALIGN_COLUMN_CENTER, width: buttonColumn
        },

    ];

    return <div>
        <div className={classes.chatPage}>
            <h3>{intl.formatMessage({id: 'chat_modal_title_conversation'}).toUpperCase()}</h3>
            {chatData.length ?
                <DataGrid className={classes.root} rows={chatData} columns={columns} pageSize={HISTORY_TABLA_PAGE_ITEMS}
                          classes={{colCell: {backgroundColor: "black"}}} hideFooterSelectedRowCount/> :
                <EmptyTableComponent/>

            }
        </div>
    </div>
}

ChatModalContentComponent.propTypes = {
    roomId: PropTypes.string.isRequired,
    eventId: PropTypes.number.isRequired
}

export default ChatModalContentComponent;

