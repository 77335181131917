import React, {useCallback, useEffect, useState} from "react";
import ClassroomComponent from "./components/classroomComponent/ClassroomComponent";
import WaitingClassComponent from "./components/waitingClassComponent/WaitingClassComponent"
import {buildUrl, post} from "../../utils/axiosRequests";
import {
    ROLE_ADMIN,
    ROLE_MANAGER, ROLES_ALL,
    ROOM_STILL_HERE_TIMEOUT,
    SEVERITY_ERROR,
    TIIVII_API_ROOM_STILL_HERE, USER_STATUS_ADMIN, USER_STATUS_APPROVED, USER_STATUS_REJECTED, USER_STATUS_STARTING
} from "../../utils/constants";
import {showMessage} from "../common/NotificationSnack";
import {useKeycloak} from "@react-keycloak/web";
import withAuthorization from "../common/auth/withAuthorization";
import ErrorComponent from "../common/ErrorComponent";
import {FormattedMessage} from "react-intl";

let user = null;
let admin = null;
let startingUserId = null;
let currentEvent = null;

function AccessClassComponent(props) {

    const {keycloak, initialized} = useKeycloak();

    const roomId = props.match.params.room_id;
    const [autoApproveUsers, setAutoApproveUsers] = useState(null);
    const [status, setStatus] = useState(null);
    const [classTitle, setClassTitle] = useState("");
    const [showError, setShowError] = useState(false)

    const setAccessState = useCallback(data => {
        setClassTitle(`${data.room_description} - ${data.current_event.class_description}`)
        setAutoApproveUsers(data.current_event.auto_approve_users);
        currentEvent = data.current_event;
        checkUserStatus(data);
    }, [])


    const getRoomInfo = useCallback((loading) => {
        post(buildUrl(TIIVII_API_ROOM_STILL_HERE, {roomId}), {}, {}, loading)
            .then(response => {
                if (currentEvent && !response.data.current_event) {
                    setShowError(true)
                } else {
                    setAccessState(response.data);
                }
            })
            .catch(error => showMessage(error, SEVERITY_ERROR));
    }, [roomId, setAccessState])


    useEffect(() => {
        initialized && keycloak.loadUserProfile().then(response => {
            const role = keycloak.realmAccess.roles.find(role => role === ROLE_ADMIN || role === ROLE_MANAGER)
            admin = role === ROLE_ADMIN || role === ROLE_MANAGER;
            user = response.username;
            getRoomInfo(false);
        }).catch(error => showMessage(error, SEVERITY_ERROR));
    }, [keycloak, initialized, getRoomInfo]);


    const checkUserStatus = data => {
        const fullUser = data.active_users.find(activeUser => activeUser.user_name === user);
        startingUserId = data.current_event.started_by_user_id

        if (!admin) {
            setStatus(fullUser.status);
        } else if (!admin && fullUser.id === startingUserId) {
            setStatus(USER_STATUS_STARTING)
        } else {
            setStatus(USER_STATUS_ADMIN)
        }
    }


    const conditionsToClass = () => {
        const info = autoApproveUsers !== null && status !== null && admin !== null;
        return !!(info && (admin || (!admin && (status === USER_STATUS_APPROVED || status === USER_STATUS_STARTING)) || (autoApproveUsers && status !== USER_STATUS_REJECTED)));
    }


    const conditions = conditionsToClass();


    useEffect(() => {
        const classInterval = setInterval(() => {
            getRoomInfo(false)
        }, ROOM_STILL_HERE_TIMEOUT);
        if (conditionsToClass()) {
            clearInterval(classInterval);
        }
        return () => clearInterval(classInterval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conditions]);


    if (showError) {
        return <ErrorComponent error={<FormattedMessage id="classroom_canceled"/>}/>
    } else {
        return (
            <div className={'pageContainer'}>
                {conditions ?
                    <ClassroomComponent {...props} user={user} getRoomInfo={getRoomInfo}
                                        checkUserStatus={checkUserStatus}/> :
                    <WaitingClassComponent key={new Date().getMilliseconds()} getRoomInfo={getRoomInfo}
                                           classTitle={classTitle} status={status} autoApproveUsers={autoApproveUsers}/>}
            </div>
        )
    }
}

export default withAuthorization(ROLES_ALL)(AccessClassComponent);