import axios from 'axios';
import {turnOffLoadingActionCreator, turnOnLoadingActionCreator} from "../actions/commonActions";
import store from '../store';
import keycloak from "./keycloak";
import {showMessage} from "../components/common/NotificationSnack";

const getOptions = (config) => {
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${(!!keycloak && !!keycloak.token) ? keycloak.token : undefined}`,
        },
        ...config
    }
};

const resolvePromise = (response, loading) => new Promise((resolve, reject) => {
    if (loading) {
        store.dispatch(turnOffLoadingActionCreator());
    }
    resolve(response);
})
const rejectPromise = (error, loading, showError) => new Promise((resolve, reject) => {
    if (loading) {
        store.dispatch(turnOffLoadingActionCreator());
    }
    if(showError){
        showMessage(error);
    }
    reject(error)
})

export const put = async (url, data, config, loading = true, showError = true) => {
    if (loading) {
        store.dispatch(turnOnLoadingActionCreator());
    }
    return await axios.put(url, data, getOptions(config))
        .then(response => resolvePromise(response, loading))
        .catch(error => rejectPromise(error, loading, showError));
}

export const post = async (url, data, config, loading, showError = true) => {
    if (loading) {
        store.dispatch(turnOnLoadingActionCreator());
    }
    return await axios.post(url, data, getOptions(config))
        .then(response => resolvePromise(response, loading))
        .catch(error => rejectPromise(error, loading, showError));
}

export const patch = async (url, data, config, loading, showError = true) => {
    if (loading) {
        store.dispatch(turnOnLoadingActionCreator());
    }
    return await axios.patch(url, data, getOptions(config))
        .then(response => resolvePromise(response, loading))
        .catch(error => rejectPromise(error, loading, showError));
}

export const get = async (url, config, loading = true, showError = true) => {
    if (loading) {
        store.dispatch(turnOnLoadingActionCreator());
    }
    return await axios.get(url, getOptions(config))
        .then(response => resolvePromise(response, loading))
        .catch(error => rejectPromise(error, loading, showError));
}

/**
 * Dado un valor para url = 'https://bert.tiivii.com/api/studio/:id/event/:event_id' y un valor para variables = {
 *   id: "un_id", event_id: "un_event_id"}, devolvería https://bert.tiivii.com/api/studio/un_id/event/un_event_id
 * @param url Url con placeholders
 * @param variables objeto con las substituciones a realizar
 * @returns {string} con la url generada a partir de las substituciones.
 */
export const buildUrl = (url, variables) =>
    url.replace(/:(\w+)/g, (match, p1) => p1 in variables ? variables[p1] : match);


