import React from 'react';
import {useKeycloak} from "@react-keycloak/web";
import {useIntl} from "react-intl";

import {Button, Divider, Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import {styles} from './LoginStyles'
import camedu_logo from '../../../../images/cam_edu_logo.png';

import clsx from "clsx";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles(theme => styles(theme));

function LoginComponent() {
    const {keycloak} = useKeycloak();
    const intl = useIntl();
    const classes = useStyles();
    return (
        <div className={clsx("pageContainer", classes.root)}>
            <img className={classes.logo} alt="Cam Edu logo" src={camedu_logo}/>
            <Paper className={classes.paper}>
                <Typography className={classes.title} variant={"h4"}>{intl.formatMessage({id: "welcome"})}</Typography>
                <Typography className={classes.text}
                            variant={"subtitle2"}>{intl.formatMessage({id: "login_terms"})}</Typography>
                <Typography className={classes.text}
                            variant={"subtitle2"}>{intl.formatMessage({id: "cookies_terms"})}</Typography>
                <Link target="_blank" rel="noreferrer"
                      href={'https://www.xunta.gal/aviso-legal-do-portal-da-xunta#cookies'}
                      className={classes.link}>{intl.formatMessage({id: "cookies_link"})}</Link>
                <Divider className={classes.divider}/>
                <Button className={classes.button} size="large" onClick={() => keycloak.login()} variant="contained"
                        color="primary">
                    {intl.formatMessage({id: 'login'})}
                </Button>
            </Paper>
        </div>
    );
}

export default LoginComponent;
