import {buildUrl, get, post, put} from "../utils/axiosRequests";
import {
    DEFAULT_HTTP_TIMEOUT,
    TIIVII_API_ACCEPT_USER,
    TIIVII_API_ASK_FOR_ACCESS,
    TIIVII_API_GET_USER, TIIVII_API_REJECT_USER,
    TIIVII_API_ROOM_SEND_LOG,
} from "../utils/constants";

export const getUserDataService = (user_name) => {
    return get(buildUrl(TIIVII_API_GET_USER, {user_name}), {
        timeout: DEFAULT_HTTP_TIMEOUT
    }, true)
}

export const postSendLogService = (data) => {
    return post(TIIVII_API_ROOM_SEND_LOG, data, {
        timeout: DEFAULT_HTTP_TIMEOUT
    });
}

export const postAskForAccessService = (room_id) => {
    const url = buildUrl(TIIVII_API_ASK_FOR_ACCESS, {room_id});
    return post(url, {}, {
        timeout: DEFAULT_HTTP_TIMEOUT
    }, false)
}

export const putAcceptUserService = (room_id, users) => {
    const url = buildUrl(TIIVII_API_ACCEPT_USER, {room_id});
    return put(url, users, {
        timeout: DEFAULT_HTTP_TIMEOUT
    }, false)
}

export const putRejectUserService = (room_id, users) => {
    const url = buildUrl(TIIVII_API_REJECT_USER, {room_id});
    return put(url, users, {
        timeout: DEFAULT_HTTP_TIMEOUT
    }, false)
}