import React from 'react'
import PageHeaderComponent from "../common/pageHeaderComponent/PageHeaderComponent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useIntl} from "react-intl";
import {LANGUAGES} from "../appBarComponent/AppBarConstants";
import image5a from "../../images/5A.png";
import image5b from "../../images/5B.png";
import image11a from "../../images/11A.jpg";
import image11b from "../../images/11B.jpg";
import image11c from "../../images/11C.png";
import image12a from "../../images/12A.jpg";
import image12b from "../../images/12B.jpg";
import image12c from "../../images/12C.jpg";
import image12d from "../../images/12D.jpg";
import image12e from "../../images/12E.jpg";
import image12f from "../../images/12F.jpg";
import image12g from "../../images/12G.jpg";
import {
    CHANGELOG_TASKS_1_2_0,
    CHANGELOG_TASKS_1_3_0,
    CHANGELOG_TASKS_1_3_1,
    CHANGELOG_TASKS_1_4_0, CHANGELOG_TASKS_1_4_1,
    VERSION_1_2_0,
    VERSION_1_3_0,
    VERSION_1_3_1,
    VERSION_1_4_0, VERSION_1_4_1
} from "./HelpConstants";
import {PDFES, PDFGL, VIDEO_URL} from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
    helpPage: {
        textAlign: 'left !important',
        [theme.breakpoints.down('md')]: {
            textAlign: 'left !important',
        }
    },
    helpContainer: {
        textAlign: 'left !important',
        padding: theme.spacing(24),
        paddingTop: 0,
        marginTop: -20,
        [theme.breakpoints.down('md')]: {
            padding: 0
        }
    },
    helpSection: {
        padding: 24,
    },
    videoSection: {
        padding: 24,
        width: "50%",
        [theme.breakpoints.down('md')]: {
            width: "100%"
        }
    },
    link: {
        color: "black"
    },
    listItem: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
    },
    imagesContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexWrap: "wrap",
        [theme.breakpoints.down('md')]: {
            justifyContent: "center",
        }
    },
    images: {
        maxWidth: "30%",
        margin: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            maxWidth: "90%",
            margin: 0,
        }
    },
    version: {
        marginLeft: theme.spacing(4)
    },
    taskBlock: {
        marginBottom: theme.spacing(3)
    }
}));

const imageObject = {
    1: [],
    2: [],
    3: [],
    4: [],
    5: [image5a, image5b],
    6: [],
    7: [],
    8: [],
    9: [],
    10: [],
    11: [image11a, image11b, image11c],
    12: [image12a],
    13: [image12b],
    14: [image12c, image12d],
    15: [image12e],
    16: [image12f],
    17: [image12g],
    18: []
}

const fullQuestionItems = (intl, classes) => {
    const numberOfQuestions = 18;
    let items = [];
    for (let question = 1; question <= numberOfQuestions; question++) {
        const textQuestion = "help_question" + question;
        const textAnswer = "help_answer" + question;
        items.push(<ListItem key={`question${question}`} className={classes.listItem}>
            <ListItemText
                primary={<Typography dangerouslySetInnerHTML={{__html: intl.formatMessage({id: textQuestion})}}/>}
                secondary={<Typography dangerouslySetInnerHTML={{__html: intl.formatMessage({id: textAnswer})}}/>}
            />
            {imageObject[question].length > 0 && <div className={classes.imagesContainer}>
                {imageObject[question].map((image, index) =>
                    <div className={classes.images} key={`image${index}`}>
                        <img src={image} width={"100%"} alt={intl.formatMessage({id: "help_image"})}/>
                    </div>
                )}
            </div>}
        </ListItem>);
    }
    return items;
}

const changelogItems = (intl, classes) => {
    return <div>
        <div className={classes.taskBlock}>
            <Typography variant={"h6"} className={classes.version}>{VERSION_1_4_1}</Typography>
            <ul>
                {CHANGELOG_TASKS_1_4_1.map((task, index) =>
                    <li key={index}>
                        <Typography dangerouslySetInnerHTML={{__html: intl.formatMessage({id: task})}}/>
                    </li>
                )}
            </ul>
        </div>
        <div className={classes.taskBlock}>
            <Typography variant={"h6"} className={classes.version}>{VERSION_1_4_0}</Typography>
            <ul>
                {CHANGELOG_TASKS_1_4_0.map((task, index) =>
                    <li key={index}>
                        <Typography dangerouslySetInnerHTML={{__html: intl.formatMessage({id: task})}}/>
                    </li>
                )}
            </ul>
        </div>
        <div className={classes.taskBlock}>
            <Typography variant={"h6"} className={classes.version}>{VERSION_1_3_1}</Typography>
            <ul>
                {CHANGELOG_TASKS_1_3_1.map((task, index) =>
                    <li key={index}>
                        <Typography dangerouslySetInnerHTML={{__html: intl.formatMessage({id: task})}}/>
                    </li>
                )}
            </ul>
        </div>
        <div className={classes.taskBlock}>
            <Typography variant={"h6"} className={classes.version}>{VERSION_1_3_0}</Typography>
            <ul>
                {CHANGELOG_TASKS_1_3_0.map((task, index) =>
                    <li key={index}>
                        <Typography dangerouslySetInnerHTML={{__html: intl.formatMessage({id: task})}}/>
                    </li>
                )}
            </ul>
        </div>
        <div className={classes.taskBlock}>
            <Typography variant={"h6"} className={classes.version}>{VERSION_1_2_0}</Typography>
            <ul>
                {CHANGELOG_TASKS_1_2_0.map((task, index) =>
                    <li key={index}>
                        <Typography dangerouslySetInnerHTML={{__html: intl.formatMessage({id: task})}}/>
                    </li>
                )}
            </ul>
        </div>
    </div>
}

function HelpComponent() {
    const classes = useStyles();
    const intl = useIntl();
    const SECTION_TITLE = intl.formatMessage({id: 'help'});
    const language = localStorage.getItem("locale") || navigator.language.split(/[-_]/)[0];

    return (
        <div className={classes.helpPage}>
            <PageHeaderComponent title={SECTION_TITLE}/>
            <Grid container className={classes.helpContainer}>
                <Grid item xs={12} className={classes.helpSection}>
                    <Typography variant="h5" className={classes.title}>
                        {intl.formatMessage({id: 'user_guide'})}
                    </Typography>
                    <List>
                        <ListItem key="user_guide">
                            <a className={classes.link} href={language === LANGUAGES.GL ? PDFGL : PDFES} target="_blank"
                               rel="noopener noreferrer">
                                {intl.formatMessage({id: 'user_guide'})}
                            </a>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} className={classes.videoSection}>
                    <Typography variant="h5" className={classes.title}>
                        {intl.formatMessage({id: 'video_tutorial'})}
                    </Typography>
                    <List>
                        <ListItem key="video_tutorial">
                            <video
                                id={"video_tutorial"}
                                src={VIDEO_URL}
                                controls={true}
                                height={"100%"}
                                width={"100%"}
                            />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} className={classes.helpSection}>
                    <Typography variant="h5" className={classes.title}>
                        {intl.formatMessage({id: 'help_faq'})}
                    </Typography>
                    <List>
                        {
                            fullQuestionItems(intl, classes)
                        }
                    </List>
                </Grid>
                <Grid item xs={12} className={classes.helpSection}>
                    <Typography variant="h5" className={classes.title}>
                        {intl.formatMessage({id: 'help_contact'})}
                    </Typography>
                    <List>
                        <ListItem key="unidade">
                            Unidade de Atención a Centros (UAC)
                        </ListItem>
                        <ListItem key="tel">
                            <Typography>{intl.formatMessage({id: "telephone"})}: 881 99 77 01</Typography>
                        </ListItem>
                        <ListItem key="email">
                            <Typography>email: <a href="mailto:uac@edu.xunta.gal"
                                                  className={classes.link}>uac@edu.xunta.gal</a>
                            </Typography>
                        </ListItem>
                        <ListItem key="www">
                            <Typography> {intl.formatMessage({id: "log_modal_more_info"})}: <a
                                href="https://www.edu.xunta.gal/portal/es/uac" className={classes.link}>
                                https://www.edu.xunta.gal/portal/es/uac</a></Typography>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} className={classes.helpSection}>
                    <Typography variant="h5" className={classes.title}>
                        {intl.formatMessage({id: 'current_version'})}
                    </Typography>
                    <List>
                        <ListItem>
                            <Typography>{VERSION_1_4_1}</Typography>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} className={classes.helpSection}>
                    <Typography variant="h5" className={classes.title}>
                        {intl.formatMessage({id: 'version_changes'})}
                    </Typography>
                    <List>
                        {changelogItems(intl, classes)}
                    </List>
                </Grid>
            </Grid>
        </div>
    );
}

export default HelpComponent;