import React, {Component} from "react";
import Pusher from 'pusher-js';
import {PropTypes} from "prop-types";

import {withStyles, Grid} from "@material-ui/core";

import ChatListComponent from "./components/chatListComponent/ChatListComponent";
import ChatBoxComponent from "./components/chatBoxComponent/ChatBoxComponent";
import {getStyles} from "./ChatStyles";
import {buildUrl, get, post} from '../../../../../../utils/axiosRequests'
import {TIIVII_API_CHAT, TIIVII_APP_KEY, TIIVII_TENANT} from "../../../../../../utils/constants";
import audio from '../../../../../../audio/chat-notification-sound.mp3'

const styles = theme => (getStyles(theme));

const pusher = new Pusher(TIIVII_APP_KEY, {
    cluster: "eu",
})

class ChatComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            text: '',
            messages: [],
        };
        this.messageListRef = React.createRef();
        this.audioRef = React.createRef();
    }

    receiveMessage = message => {
        const {username} = this.props;
        if (this.audioRef.current) {
            if (message.username !== username) {
                this.audioRef.current.play();
            }
        }
        this.setState(prevState => ({
            messages: [...prevState.messages, {...message, user: message.username}]
        }));
    }

    getMessages() {
        const {roomId} = this.props;
        get(buildUrl(TIIVII_API_CHAT, {room_id: roomId}))
            .then(response => {
                    if (response.data) {
                        this.setState({messages: response.data || [], text: ''})
                    }
                }
            )
    }

    chatSetUp(){
        const {eventId} = this.props;
        const channel = pusher.subscribe(`tiivii-edustream-${TIIVII_TENANT}`);
        channel.bind(`event-${eventId}`, this.receiveMessage);
        this.getMessages();
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        const list = this.messageListRef.current;
        if (!!list && prevState.messages.length < this.state.messages.length) {
            return list.scrollHeight - list.scrollTop;
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const list = this.messageListRef.current;
        if (!!snapshot) {
            list.scrollTop = list.scrollHeight - snapshot;
        } else if (!!list && prevState.messages.length < this.state.messages.length) {
            list.scrollTop = list.scrollHeight - list.clientHeight;
        }
        if(prevProps.eventId !== this.props.eventId && this.props.eventId){
            this.chatSetUp();
        }
    }

    handleTextChange = (e) => {
        if (e.keyCode === 13 && this.state.text !== "" && this.state.text !== " ") {
            this.sendMessage()
        } else {
            this.setState({text: e.target.value})
        }
    }

    sendMessage = () => {
        const {roomId} = this.props;
        const payload = {
            text: this.state.text,
        }
        post(buildUrl(TIIVII_API_CHAT, {room_id: roomId}), payload)
            .then(() => this.setState({text: ''}))
            .catch(error => console.error(error));
    }

    render() {
        const {classes, username} = this.props;
        const {messages, text} = this.state;

        return (
            <Grid container className={classes.chatContainer}>
                <Grid item className={classes.extend} ref={this.messageListRef}>
                    <ChatListComponent messages={messages} username={username} ref={this.messageListRef}/>
                </Grid>
                <Grid item xs={12}>
                    <ChatBoxComponent
                        text={text}
                        handleTextChange={this.handleTextChange}
                        sendMessage={this.sendMessage}
                        className={classes.chatInput}
                    />
                    <audio src={audio} ref={this.audioRef}/>
                </Grid>
            </Grid>
        )
    }
}

ChatComponent.propTypes = {
    roomId: PropTypes.string.isRequired,
    eventId: PropTypes.any,
    username: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ChatComponent)
