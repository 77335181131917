export const getStyles = theme => {
    return {
        list: {
            textAlign: "left",
            overflowY: "auto",
            padding: theme.spacing(1, 0),
        },
        simpleList: {
            textAlign: "left",
            overflowY: "auto",
            padding: theme.spacing(3, 0),
        },
        header: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        headerButtons: {},
        usersButton: {
            backgroundColor: theme.palette.primary.main,
            color: "white",
            borderRadius: "100%",
            width: 30,
            height: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        pendingUsers: {
            backgroundColor: theme.palette.primary.main,
            color: "white",
            borderRadius: "100%",
            width: 20,
            height: 20,
        },
        userRow: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        user: {
            display: "flex",
            alignItems: "center",
            marginLeft: theme.spacing(2),
            flex: 1
        },
        pending: {
            color: theme.palette.primary.main
        },
        approved: {
            color: "green"
        },
        rejected: {
            color: "red"
        },
        accept:{
            color: "green"
        },
        icon: {
            marginRight: theme.spacing(2)
        },
        ellipsis: {
            width: 140,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        },
        ellipsisWide: {
            width: 220,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        }
    }
}
