import React, {useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {getStyles} from "./ClassroomFormStyles";
import {FormControl, FormLabel, withStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {buildUrl, patch, post} from "../../../utils/axiosRequests";
import {
    CREATE_ROOM_HTTP_TIMEOUT, ROOM_STATUS_STARTED,
    SEVERITY_ERROR,
    TIIVII_API_GET_ROOMS_BY_ID,
    TIIVII_API_START_ROOM
} from "../../../utils/constants";
import {showMessage} from "../NotificationSnack";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from '@material-ui/core/Checkbox';
import Typography from "@material-ui/core/Typography";

import PropTypes from "prop-types";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";

const styles = theme => (getStyles(theme));

function ClassroomFormComponent(props) {

    const intl = useIntl();

    const {open, handleClose, handleGoToRoom, classes, roomDataToEdit, handleUpdateEncoder} = props;
    const {roomId, classDescription, roomDescription, classTeacher, classEncoderActive, classEncoderAvailable, autoApproveUsers} = roomDataToEdit

    const [classroomName, setClassroomName] = useState(classDescription);
    const [classroomEncoder, setClassroomEncoder] = useState(classEncoderActive);
    const [classroomTeacherName, setClassroomTeacherName] = useState(classTeacher);
    const [validateForm, setValidateForm] = useState(true);
    const [editClass, setEditClass] = useState(!!classDescription);
    const [allowAutomaticAccess, setAllowAutomaticAccess] = useState((autoApproveUsers && autoApproveUsers.toString()) || "false");


    useEffect(() => {
        const initialValueClassroomDescription = !!classDescription ? classDescription : "";
        const initialValueClassroomTeacherName = !!classTeacher ? classTeacher : "";
        setClassroomName(initialValueClassroomDescription);
        setClassroomTeacherName(initialValueClassroomTeacherName);
        setEditClass(!!initialValueClassroomDescription);
        setClassroomEncoder(classEncoderActive === 1);
        // eslint-disable-next-line
    }, [roomDataToEdit])

    const handleAllowAutomaticAccess = (event) => {
        setAllowAutomaticAccess(event.target.value);
        setValidateForm(!allowAutomaticAccess);
    }

    const onChangeClassroomName = event => {
        const value = event.target.value
        setClassroomName(value);
        setValidateForm(!(!!value && !!classroomTeacherName));
    }
    const onChangeTeacherName = event => {
        const value = event.target.value
        setClassroomTeacherName(value);
        setValidateForm(!(!!value && classroomName));
    }

    const handleCheckbox = event => {
        setClassroomEncoder(event.target.checked)
        setValidateForm(!classroomName);
    }

    // Función recursiva para que en caso de que el post tenga el estado de la clase "pending" siga pidiendolo hasta que cambie
    // La función se llama así misma hasta que se consiga el resultado deseado
    const handleSendAndClose = () => {
        const body = {
            "class_description": classroomName,
            "teacher_name": classroomTeacherName,
            "encoder_active": classroomEncoder ? 1 : 0,
            "auto_approve_users": allowAutomaticAccess === 'true'
        };

        setValidateForm(true);
        if (editClass) {
            patch(buildUrl(TIIVII_API_GET_ROOMS_BY_ID, {room_id: roomId}), body)
                .then(onClassUpdateSuccess)
                .catch(onError);
        } else {
            post(buildUrl(TIIVII_API_START_ROOM, {roomId}), body, {
                timeout: CREATE_ROOM_HTTP_TIMEOUT
            }, true)
                .then(onClassUpdateSuccess)
                .catch(onError);
        }
    }

    const onClassUpdateSuccess = res => {
        handleClose();
        if (res.data.current_event.event_status !== ROOM_STATUS_STARTED) {
            props.getListRooms();
        } else {
            if (!editClass) {
                handleGoToRoom(res.data.id)
            } else {
                handleUpdateEncoder()
            }
        }
    }

    const onError = error => {
        showMessage(error, SEVERITY_ERROR);
    }
    
    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogContent>
                <form className={classes.containerForm}>
                    <Grid container>
                        <Grid item container xs={12} className={classes.alignedInputs}>
                            <Typography variant="h5" className={classes.title}>
                                {roomDescription}
                            </Typography>
                        </Grid>
                        <Grid item container xs={12} className={classes.alignedInputs}>
                            <Grid item xs={12} md={8} className={classes.paper}>
                                <TextField id="classroomName"
                                           label={intl.formatMessage({id: 'classroom_name'})}
                                           value={classroomName} fullWidth
                                           onChange={onChangeClassroomName}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} className={`${classes.paper} ${classes.paperCheck}`}>
                                <FormControlLabel label={intl.formatMessage({id: 'proyector'})}
                                                  control={<Checkbox color="primary" onChange={handleCheckbox}
                                                                     checked={classroomEncoder}
                                                                     disabled={classEncoderAvailable === 0}/>}
                                                  labelPlacement="start"/>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.paper}>
                            <TextField id="teacherName"
                                       label={intl.formatMessage({id: 'teacher'})}
                                       value={classroomTeacherName} fullWidth
                                       onChange={onChangeTeacherName}/>
                        </Grid>
                        <Grid item xs={12} md={12} className={classes.paper}>
                            <FormControl style={{display: "flex"}}>
                                <FormLabel>{intl.formatMessage({id: "user_control"})}</FormLabel>
                                <RadioGroup aria-label="gender" name="gender1" value={allowAutomaticAccess}
                                            onChange={handleAllowAutomaticAccess}>
                                    <FormControlLabel value={"true"} control={<Radio/>}
                                                      label={intl.formatMessage({id: "user_control_allow"})}/>
                                    <FormControlLabel value={"false"} control={<Radio/>}
                                                      label={intl.formatMessage({id: "user_control_ask"})}/>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions className={classes.buttonsGroupMobile}>
                <Button onClick={handleClose} color="secondary" variant="contained"
                        style={{fontWeight: 'bold', color: '#fff'}}>
                    {intl.formatMessage({id: 'cancel'})}
                </Button>
                <Button onClick={handleSendAndClose} color="primary" disabled={validateForm} variant="contained"
                        style={{fontWeight: 'bold', color: '#fff'}}>
                    {intl.formatMessage({id: 'save'})}
                </Button>
            </DialogActions>
        </Dialog>

    )
}

ClassroomFormComponent.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    roomDataToEdit: PropTypes.shape({
        roomId: PropTypes.any,
        classDescription: PropTypes.string,
        roomDescription: PropTypes.string,
        classTeacher: PropTypes.string,
        classEncoderActive: PropTypes.any,
        classEncoderAvailable: PropTypes.any
    }).isRequired,
    handleGoToRoom: PropTypes.func,
    getListRooms: PropTypes.func
}

export default withStyles(styles)(ClassroomFormComponent);
