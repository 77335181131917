export const getStyles = theme => {
    return {
        containerForm: {

        },
        textField: {

        },
        title: {
          paddingLeft: 10,
        },
        alignedInputs: {
            display:"flex",
            alignItems:"flex-end",
        },
        paper: {
            padding: 10,
            marginTop: 20,
            [theme.breakpoints.down('md')]: {
                marginTop: 0,
            }
        },
        paperCheck: {
            [theme.breakpoints.down('md')]: {
                marginLeft: -16
            }
        },
        buttonsGroupMobile: {
            [theme.breakpoints.down('md')]: {
                justifyContent: "space-evenly",
            }
        }

    }
}