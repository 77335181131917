import React from 'react'
import MenuItem from "@material-ui/core/MenuItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import {Link} from "react-router-dom"
import PropTypes from "prop-types";


function AppBarMenuItemComponent(props) {
    const {icon, language, text, link, ...rest} = props;
    const ItemLink = React.forwardRef((props, ref) =>
        <Link ref={ref} to={link}{...props} />);
    
    return (
        <MenuItem button {...rest} component={ItemLink}>
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            <ListItemText primary={text}/>
        </MenuItem>
    );
}

AppBarMenuItemComponent.propTypes = {
    icon: PropTypes.object,
    language: PropTypes.string,
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
}

export default AppBarMenuItemComponent;
