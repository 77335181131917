export const EDUCAM_VERSION = process.env.REACT_APP_VERSION;

/**
 * KEYCLOAK
 */
export const KEYCLOAK_URL = process.env.REACT_APP_KC_URL;
export const KEYCLOAK_REALM = process.env.REACT_APP_KC_REALM;
export const KEYCLOAK_CLIENT_ID = process.env.REACT_APP_KC_CLIENT_ID;

/**
 * BACKEND API
 */
export const TIIVII_TENANT = process.env.REACT_APP_TIIVII_TENANT;
export const TIIVII_APP_KEY = process.env.REACT_APP_KEY;
export const TIIVII_API_BASE_URL = process.env.REACT_APP_TIIVII_API_BASE_URL;
export const TIIVII_API_GET_INSTITUTIONS = `${TIIVII_API_BASE_URL}${TIIVII_TENANT}/institutions/`;
export const TIIVII_API_GET_ROOMS = `${TIIVII_API_BASE_URL}${TIIVII_TENANT}/rooms/`;
export const TIIVII_API_GET_ROOMS_BY_ID = `${TIIVII_API_BASE_URL}${TIIVII_TENANT}/rooms/:room_id`;
export const TIIVII_API_GET_ROOMS_BY_INSTITUTION = `${TIIVII_API_BASE_URL}${TIIVII_TENANT}/rooms/?institution_id=:institutionId`;
export const TIIVII_API_GET_ROOM = `${TIIVII_API_BASE_URL}${TIIVII_TENANT}/rooms/:roomId`;
export const TIIVII_API_GET_USER = `${TIIVII_API_BASE_URL}${TIIVII_TENANT}/users/:user_name`;
export const TIIVII_API_STOP_ROOM = `${TIIVII_API_BASE_URL}${TIIVII_TENANT}/rooms/:room_id/stop_edustream`;
export const TIIVII_API_START_ROOM = `${TIIVII_API_BASE_URL}${TIIVII_TENANT}/rooms/:roomId/start_edustream`;
export const TIIVII_API_ROOM_STILL_HERE = `${TIIVII_API_BASE_URL}${TIIVII_TENANT}/rooms/:roomId/still_here`;
export const TIIVII_API_CHAT = `${TIIVII_API_BASE_URL}${TIIVII_TENANT}/rooms/:room_id/chat`;
export const TIIVII_API_ENCODER = `${TIIVII_API_BASE_URL}${TIIVII_TENANT}/rooms/:room_id/encoder`;
export const TIIVII_API_ROOM_EVENTS = `${TIIVII_API_BASE_URL}${TIIVII_TENANT}/rooms/:room_id/events`;
export const TIIVII_API_ROOM_EVENT = `${TIIVII_API_BASE_URL}${TIIVII_TENANT}/rooms/:room_id/events/:event_id`;
export const TIIVII_API_ROOM_LOG = `${TIIVII_API_BASE_URL}${TIIVII_TENANT}/log/event/:event_id`;

export const TIIVII_API_GET_CAPTURES = `${TIIVII_API_BASE_URL}${TIIVII_TENANT}/rooms/:room_id/blackboard_captures`;
export const TIIVII_API_GET_CAPTURE = `${TIIVII_API_GET_CAPTURES}/:url_hashed`;

export const TIIVII_API_ROOM_SEND_LOG = `${TIIVII_API_BASE_URL}${TIIVII_TENANT}/log`;

export const TIIVII_API_ASK_FOR_ACCESS = `${TIIVII_API_BASE_URL}${TIIVII_TENANT}/rooms/:room_id/ask_for_access`;
export const TIIVII_API_ACCEPT_USER = `${TIIVII_API_BASE_URL}${TIIVII_TENANT}/rooms/:room_id/accept_user`;
export const TIIVII_API_REJECT_USER = `${TIIVII_API_BASE_URL}${TIIVII_TENANT}/rooms/:room_id/reject_user`;


export const TIME_TO_RELOAD_ROOMS_LIST = 10000
export const CREATE_ROOM_HTTP_TIMEOUT = 180000
export const DEFAULT_HTTP_TIMEOUT = 5000;

/**
 * ROLES
 */
export const ROLE_ADMIN = "admin";
export const ROLE_MANAGER = "manager";
export const ROLE_TEACHER = "teacher";
export const ROLE_STUDENT = "student";
export const ROLES_NOT_STUDENT = [ROLE_ADMIN, ROLE_MANAGER, ROLE_TEACHER];
export const ROLES_ALL = [ROLE_ADMIN, ROLE_MANAGER, ROLE_TEACHER, ROLE_STUDENT];
export const ROLES_ADMIN_MANAGER = [ROLE_ADMIN, ROLE_MANAGER];
export const ROLES_STUDENT_TEACHER = [ROLE_STUDENT, ROLE_TEACHER];

/**
 * NOTIFICATION SEVERITY
 */
export const SEVERITY_SUCCESS = 'success';
export const SEVERITY_INFO = 'info';
export const SEVERITY_WARNING = 'warning';
export const SEVERITY_ERROR = 'error';

/**
 * BACKEND POLLING
 */
export const ROOM_STILL_HERE_TIMEOUT = 15 * 1000; // Fifteen seconds
export const ROOM_INACTIVITY_WARNING_TIMEOUT = 10 * 60 * 1000; // Ten minutes
export const ROOM_INACTIVITY_DISCONNECT_TIMEOUT = 2 * 60 * 1000; // Two minutes
export const ROOM_LIST_RELOAD_TIMEOUT = 10 * 1000; // Ten seconds
export const ROOM_IMAGE_RELOAD_TIMEOUT = 60 * 1000 // Sixty seconds

/**
 * CLASSROOM STATUS
 */
export const ROOM_STATUS_STARTED = "started"

/**
 * USER STATUS
 */
export const USER_STATUS_PENDING = "PENDING";
export const USER_STATUS_APPROVED = "APPROVED";
export const USER_STATUS_REJECTED = "REJECTED";
export const USER_STATUS_STARTING = "STARTING";
export const USER_STATUS_ADMIN = "ADMIN";
export const USER_STATUS_REJECTED_TIMEOUT = 60; // Sixty seconds
export const USER_STATUS_PENDING_TIMEOUT = 5 * 60 * 1000; // Five minutes
export const USER_STATUS_PENDING_NOTIFICATION = 5000; // Five seconds

/**
 * FILE LINKS
 */
export const PDFES = "https://edumedios.tiivii.com/files/Manual%20de%20Usuario%20Cam%20Edu_v.1.4_ES.pdf";
export const PDFGL = "https://edumedios.tiivii.com/files/Manual%20de%20Usuario%20Cam%20Edu_v.1.4_GL.pdf";
export const VIDEO_URL = "https://edumedios.tiivii.com/videos/video_edustream_xunta.mp4";

/**
 * LANGUAGES
 */
export const LANGUAGE_ES = 'es';
export const LANGUAGE_GL = 'gl';
export const LANGUAGE_EN = 'en';


export const ROUTE_PATH_PRIVACY_POLICY = 'privacy-policy';