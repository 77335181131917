import React from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import clsx from "clsx";
import {styles} from "./ChatListStyles";

const useStyles = makeStyles(theme => styles(theme));

const ChatListComponent = React.forwardRef(({messages, username}, ref) => {
    const classes = useStyles();

    return (
        <div className={classes.chatList} ref={ref}>
            {messages.map((message, index) =>
                <div key={message.user + message.text + message.timestamp + index} className={classes.chatMessage}>
                    <Typography className={clsx(classes.sender, username === message.user && classes.ownMessage)}
                                display={"inline"}>
                        {message.user}:
                    </Typography>
                    <Typography variant={"body2"} className={classes.message}
                                display={"inline"}>{message.text}</Typography>
                </div>
            )}
        </div>
    );
});

ChatListComponent.propTypes = {
    messages: PropTypes.array.isRequired,
    username: PropTypes.string.isRequired,
};

export default ChatListComponent;
