import React, {useEffect, useState} from "react";
import {USER_STATUS_PENDING, USER_STATUS_REJECTED, USER_STATUS_REJECTED_TIMEOUT} from "../../../../utils/constants";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./WaitingClassStyles";
import {postAskForAccessService} from "../../../../services/commonServices";
import {useParams} from "react-router-dom";
import {useIntl} from "react-intl";
import {showMessage} from "../../../common/NotificationSnack";

const useStyles = makeStyles(theme => styles(theme));

const WaitingClassComponent = ({status, getRoomInfo, classTitle, autoApproveUsers}) => {

    const intl = useIntl();
    const classes = useStyles();
    const {room_id} = useParams();
    const [seconds, setSeconds] = useState(USER_STATUS_REJECTED_TIMEOUT);

    useEffect(() => {
        let timer = null;
        if (status === USER_STATUS_REJECTED && seconds > 0) {
            timer = setInterval(() => {
                setSeconds(seconds => seconds - 1);
            }, 1000);
        }
        if (seconds === 0) {
            clearInterval(timer)
            timer = null;
        }
        return () => {
            clearInterval(timer)
            timer = null;
        }
    }, [status, seconds]);

    const handleGoToRoom = roomId => {
        postAskForAccessService(roomId).then(() => {
            getRoomInfo(false)
        }).catch(error => showMessage(error));
    };

    return (
        <div className={'pageContainer'}>
            {status === USER_STATUS_PENDING && <div className={classes.container}>
                <Typography variant={"h4"} className={classes.title}>
                    {intl.formatMessage({id: 'access_requested'})}</Typography>
                <Typography variant={"h6"}
                            className={classes.subtitle}> {intl.formatMessage({id: 'access_requested_message'})}</Typography>
                <Typography variant={"h5"}>{classTitle}</Typography>
            </div>}
            {status === USER_STATUS_REJECTED && (autoApproveUsers ?
                <div className={classes.container}>
                    <Typography variant={"h4"} className={classes.title}>
                        {intl.formatMessage({id: 'access_rejected'})}</Typography>
                    <Typography variant={"h6"}
                                className={classes.subtitle}> {intl.formatMessage({id: 'access_rejected_message'})}</Typography>
                </div> :
                <div className={classes.container}>
                    <Typography variant={"h4"} className={classes.title}>
                        {intl.formatMessage({id: 'access_denied'})}</Typography>
                    {seconds > 0 && <Typography variant={"body1"} className={classes.subtitle}>
                        {intl.formatMessage({id: 'access_denied_message1'})} {seconds} {intl.formatMessage({id: 'access_denied_message2'})}</Typography>}
                    <Button variant={"contained"} className={classes.button} disabled={seconds > 0}
                            onClick={() => handleGoToRoom(room_id)}>
                        {intl.formatMessage({id: 'access_request'})}</Button>
                </div>)}
        </div>
    );
}


export default WaitingClassComponent;