import {
    SET_CLASSROOM_DATA
} from "../actions/actionTypes";

const INITIAL_STATE = {
    roomData: null,
};

function classroomListState(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_CLASSROOM_DATA: {
            return {...state, roomData: action.data}
        }
        default:
            return state;
    }
}

export default classroomListState;