export const styles = (theme) => {
    return {
        gridContainer: {
            height: 650,
            width: '95%',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        root: {
            '& .MuiDataGrid-colCellTitle': {
                fontWeight: 'bold'
            },
            '& .MuiDataGrid-colCellWrapper ': {
                backgroundColor: '#fafafa'
            },
            '& .MuiDataGrid-colCell-draggable, .MuiDataGrid-cellCenter': {
                justifyContent: 'center'
            }
        },
        link: {
            textDecoration: 'none',
            color: "black",
            '&:hover': {
                textDecoration: 'underline',
            }
        },
        modalContainer: {
            border:"none",
            width: "90%",
            height: "75vh",
            margin: 'auto',
            marginTop: "6%",
            backgroundColor: "white",
            overflowY: "auto",
            overflowX: "hidden",
            padding: 10,
            position: 'relative'
        },
        containerButtons: {
            width: '100%'
        },
        button: {
            marginRight: 5,
            backgroundColor: '#049fe3',
            color: 'white'
        },
        closeModal: {
            position: 'absolute',
            top: 0,
            right: 0
        },
    }
}