export const getStyles = theme => {
    return {
        chatContainer: {
            display: "flex",
            direction: "column",
            height: "100%",
            padding:theme.spacing(2),
            [theme.breakpoints.down('md')]: {
                backgroundColor: "white",
                zIndex: 2,
                border: "1px solid lightgray",
                borderRadius: 8,
            }
        },
        extend: {
            overflowY: "scroll",
            width: "100%",
            height: "85%",
            [theme.breakpoints.down('md')]: {
                backgroundColor: "white",
            }
        },
        chatInput: {
            [theme.breakpoints.down('md')]: {
                marginBottom: 10,
            }
        }

    }
}