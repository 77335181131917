export const styles = theme => {
    return {
        container: {
            paddingTop: theme.spacing(24)
        },
        title:{
            marginBottom: theme.spacing(2)
        },
        subtitle:{
            marginBottom: theme.spacing(1)
        },
        button:{
            backgroundColor: theme.palette.primary.main,
            color: "white"
        }
    }
}