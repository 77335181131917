import React, {useEffect, useState} from "react";
import {buildUrl, get} from "../../utils/axiosRequests";
import {
    TIIVII_API_GET_ROOMS_BY_ID,
    ROLES_ADMIN_MANAGER,
    TIIVII_API_ROOM_EVENTS
} from "../../utils/constants";
import PageHeaderComponent from "../common/pageHeaderComponent/PageHeaderComponent";
import AuthorizedElement from "../common/auth/AuthorizedElement";
import {Button, IconButton} from "@material-ui/core";
import {useIntl} from "react-intl";
import {useParams} from "react-router-dom";
import {useKeycloak} from "@react-keycloak/web";
import {makeStyles} from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import LogModalContentComponent
    from "./components/logModalContentComponent/LogModalContentComponent";
import UsersModalContentComponent
    from "./components/usersModalContentComponent/UsersModalContentComponent";
import ChatModalContentComponent
    from "./components/chatModalContentComponent/ChatModalContentComponent";
import {Link} from "react-router-dom"
import {DataGrid} from "@material-ui/data-grid";
import {
    ALIGN_COLUMN_LEFT,
    HISTORY_TABLA_PAGE_ITEMS,
    ALIGN_COLUMN_CENTER,
    regularColumn,
    buttonColumn,
    descriptionColumn,
    narrowColumn, wideColumn, largeColumn
} from "./HistoryTableConstants";
import {styles} from './HistoryTableStyles';
import {formatDate, formatTime} from "../../utils/utils";

import {
    ROUTE_INSTITUTION,
    ROUTE_INSTITUTIONS,
} from "../../utils/routes";
import CloseIcon from "@material-ui/icons/Close";
import HistoryRoundedIcon from '@material-ui/icons/HistoryRounded';
import PersonOutlineRoundedIcon from '@material-ui/icons/PersonOutlineRounded';
import ChatBubbleOutlineRoundedIcon from '@material-ui/icons/ChatBubbleOutlineRounded';
import {setClassroomData} from "../../actions/classroomListActions";
import {compose} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import withAuthorization from "../common/auth/withAuthorization";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(theme => styles(theme));

function HistoryTableComponent(props) {

    const [events, setEvents] = useState(null);
    const [offset, setOffset] = useState(0);
    const [columnWidth, setColumnWidth] = useState({
        regularColumn: regularColumn(window.innerWidth),
        descriptionColumn: descriptionColumn(window.innerWidth),
        buttonColumn: buttonColumn(window.innerWidth),
        narrowColumn: narrowColumn(window.innerWidth),
        largeColumn: largeColumn(window.innerWidth),
        wideColumn: wideColumn(window.innerWidth)
    });
    const [iconMode, setIconMode] = useState(false);

    const intl = useIntl();
    const {institution_id, room_id} = useParams();
    const {keycloak, initialized} = useKeycloak();
    const classes = useStyles();
    const {roomData, setClassroomData} = props;
    const getRoom = () => {
        get(buildUrl(TIIVII_API_GET_ROOMS_BY_ID, {room_id}))
            .then(res => setClassroomData(res.data)).catch((error) => console.log(error))
    }

    const getEvents = (page) => {
        if ((offset) !== page) {
            get(`${buildUrl(TIIVII_API_ROOM_EVENTS, {room_id})}?offset=${page - 1}&limit=10`, {}).then(response => {
                setOffset(page)
                setEvents(response.data)
            });
        }
    }

    useEffect(() => {
        if (initialized && keycloak.authenticated) {
            getEvents(1);
        }
        if (!roomData) {
            getRoom();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialized, keycloak]);


    useEffect(() => {

        function handleResize() {
            setColumnWidth({
                regularColumn: regularColumn(window.innerWidth),
                descriptionColumn: descriptionColumn(window.innerWidth),
                buttonColumn: buttonColumn(window.innerWidth),
                narrowColumn: narrowColumn(window.innerWidth),
                largeColumn: largeColumn(window.innerWidth),
                wideColumn: wideColumn(window.innerWidth)
            })
        }

        setIconMode(window.innerWidth < 1000)

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.innerWidth]);

    const BREAD_CRUMBS = <AuthorizedElement roles={ROLES_ADMIN_MANAGER}> <span>
                                <Link className={classes.link} to={ROUTE_INSTITUTIONS}
                                      underline="none">{intl.formatMessage({id: 'institution_list'})}</Link>
                                <span> > </span>
                                <Link className={classes.link}
                                      to={buildUrl(ROUTE_INSTITUTION, {institution_id})}
                                      underline="none"> {intl.formatMessage({id: 'classroom_list'})}</Link></span>
    </AuthorizedElement>
    const SECTION_TITTLE = ""
    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState(null);

    const columns = [
        {
            field: 'started_at',
            label: 'started_at',
            headerName: intl.formatMessage({id: 'history_started_at'}).toUpperCase(),
            align: ALIGN_COLUMN_CENTER, width: columnWidth.regularColumn,
            renderCell: (params) => {
                return <span>{params.data.started_at ? formatDate(params.data.started_at * 1000) + ' - ' + formatTime(params.data.started_at) : "-"} / {params.data.stopped_at ?
                    formatTime(params.data.stopped_at) : intl.formatMessage({id: 'history_in_progress'})}</span>;
            }
        },
        {
            field: 'class_description',
            label: 'history_class_description',
            headerName: intl.formatMessage({id: 'history_class_description'}).toUpperCase(),
            align: ALIGN_COLUMN_CENTER, width: columnWidth.descriptionColumn
        },
        {
            field: 'teacher_name',
            label: 'history_teacher_name',
            headerName: intl.formatMessage({id: 'history_teacher_name'}).toUpperCase(),
            align: ALIGN_COLUMN_CENTER, width: columnWidth.descriptionColumn
        },
        {
            field: 'encoder_active', label: 'history_encoder_active',
            headerName: intl.formatMessage({id: 'history_encoder_active'}).toUpperCase(),
            align: ALIGN_COLUMN_CENTER,
            width: columnWidth.regularColumn
        },
        {
            field: "",
            headerName: "",
            disableClickEventBubbling: true, align: ALIGN_COLUMN_LEFT,
            width: columnWidth.buttonColumn,
            renderCell: (params) => {
                const handleClick = (data) => {
                    data.event = params.data;
                    setModalData(data);
                    setOpenModal(true)
                };

                return <div className={classes.containerButtons}>
                    {iconMode ? <div>
                        <div>
                            <Tooltip title={intl.formatMessage({id: 'history_class_button_log'})}>
                                <IconButton onClick={() => handleClick({button: "log"})}>
                                    <HistoryRoundedIcon color={"primary"}/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={intl.formatMessage({id: 'history_class_button_users'})}>
                                <IconButton onClick={() => handleClick({button: "users"})}>
                                    <PersonOutlineRoundedIcon color={"primary"}
                                                              />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={intl.formatMessage({id: 'history_class_button_chat'})}>
                                <IconButton onClick={() => handleClick({button: "chat"})}>
                                    <ChatBubbleOutlineRoundedIcon color={"primary"}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div> : <div>
                        <Button className={classes.button} variant="contained"
                                onClick={() => handleClick({button: "log"})}>{intl.formatMessage({id: 'history_class_button_log'}).toUpperCase()}</Button>
                        <Button className={classes.button} variant="contained"
                                onClick={() => handleClick({button: "users"})}>{intl.formatMessage({id: 'history_class_button_users'}).toUpperCase()}</Button>
                        <Button className={classes.button} variant="contained"
                                onClick={() => handleClick({button: "chat"})}>{intl.formatMessage({id: 'history_class_button_chat'}).toUpperCase()}</Button>
                    </div>}
                </div>;
            }
        }
    ];


    const getRows = () => {
        return events && events.elements.map(event => {
            event.encoder_avaliable = event.encoder_avaliable === 1 ? "Activo" : "desactivado"
            event.encoder_active = event.encoder_active === 1 ? "Activo" : "desactivado"
            return event
        });
    }

    const rows = getRows();

    return (<div>
        <PageHeaderComponent title={SECTION_TITTLE} breadCrumbs={BREAD_CRUMBS}/>
        {roomData && <h2>
            {intl.formatMessage({id: 'history_title'}, {
                room: roomData.room_description,
                institution: roomData.institution_description
            })}
        </h2>}
        <div className={'pageContainer'}>
            <AuthorizedElement roles={ROLES_ADMIN_MANAGER}>
                <Modal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div className={classes.modalContainer}>
                        {window.innerWidth && modalData !== null && (modalData.button === "log" ?
                            <LogModalContentComponent eventId={modalData.event.id}
                                                      onClose={() => setOpenModal(false)}
                                                      columnWidth={columnWidth}/> : modalData.button === "users" ?
                                <UsersModalContentComponent roomId={room_id} eventId={modalData.event.id}
                                                            onClose={() => setOpenModal(false)}
                                                            columnWidth={columnWidth}/> :
                                <ChatModalContentComponent roomId={room_id} eventId={modalData.event.id}
                                                           onClose={() => setOpenModal(false)}
                                                           columnWidth={columnWidth}/>)}
                        <div className={classes.closeModal}>
                            <IconButton onClick={() => setOpenModal(false)}>
                                <CloseIcon/>
                            </IconButton>
                        </div>
                    </div>
                </Modal>
                <div className={classes.gridContainer}>
                    {events && <DataGrid rows={rows} columns={columns} pageSize={HISTORY_TABLA_PAGE_ITEMS}
                                         className={classes.root}
                                         onPageChange={(params) => {
                                             getEvents(params.page);
                                         }} hideFooterSelectedRowCount/>}
                </div>

            </AuthorizedElement>
        </div>
    </div>)
}

const mapDispatchToProps = (dispatch) => ({
    setClassroomData: (data) => dispatch(setClassroomData(data)),
});
const mapStateToProps = ({classroomListState}) => ({
    roomData: classroomListState.roomData
});

HistoryTableComponent.propTypes = {
    roomData: PropTypes.shape({
        room_description: PropTypes.string,
        institution_description: PropTypes.string
    }),
    setClassroomData: PropTypes.func.isRequired
}

export default compose(
    withAuthorization(ROLES_ADMIN_MANAGER),
    connect(mapStateToProps, mapDispatchToProps))
(HistoryTableComponent)

