import React, {useState} from "react";
import VideoControlsComponent from "./components/videoControlsComponent/VideoControlsComponent";
import HandleHlsComponent from "../../../../../common/handleHlsComoponent/HandleHlsComponent";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        width: "100%",
        position: "relative"
    }
}));

export const VideoComponent = ({handleAutoplayWarning, handleDisableSwitch, handlePlayingState, handleMute, mute, playing, value, video, width, show, size}) => {

    const classes = useStyles();
    const [controls, setControls] = useState(false);

    const handleMouseOver = value => {
        setControls(value);
    };

    if (video && show) {
        return <div className={classes.root}
                    onMouseEnter={() => handleMouseOver(true)}
                    onMouseLeave={() => handleMouseOver(false)}>
            <VideoControlsComponent show={controls}
                                    handleMute={handleMute}
                                    handlePlayingState={handlePlayingState}
                                    mute={mute} playing={playing} value={value}/>
            <HandleHlsComponent width={width} video={video} mute={mute}
                                play={playing} handlePlayingState={handlePlayingState}
                                handleAutoplayWarning={handleAutoplayWarning}
                                size={size}
                                handleDisableSwitch={handleDisableSwitch}/>
        </div>
    } else {
        return <></>
    }
}