export const styles = theme => {
    return {
        chatList: {
            listStyleType: 'none',
        },
        chatMessage: {
            position: 'relative',
            marginTop: theme.spacing(2),
            width: '100%',
            display: 'table',
            textAlign: 'left',
        },
        sender: {
            fontWeight: "bold",
        },
        ownMessage: {
            fontStyle: "italic",
        },
        message: {
            color: '#444',
            marginLeft: theme.spacing(2),
        },
    }
}