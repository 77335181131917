import React from "react";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import PropTypes from "prop-types";

export default function AlertDialog({buttonCloseText, buttonAcceptText, description, onClose, onAccept, open, title}) {

    return (<Dialog
        open={open}
        keepMounted
        onClose={onClose}
        aria-labelledby="alert-dialog-slide-title"
    >
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        <DialogContent>
            {description}
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="secondary">
                {buttonCloseText}
            </Button>
            {buttonAcceptText && <Button onClick={onAccept} color="primary">
                {buttonAcceptText}
            </Button>}
        </DialogActions>
    </Dialog>)
}

AlertDialog.propTypes = {
    buttonCloseText: PropTypes.object.isRequired,
    description: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    title: PropTypes.object.isRequired
}