import React from "react";
import  svg from "./FlagIconContants"
import {styles} from "./FlagIconStyles";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => styles(theme));
export default function FlagIcon({flag}) {

    const classes = useStyles();
    return (
        <div className={classes.root}>
           <img alt='' className={classes.img} src={svg[flag]}/>
        </div>
    );
}