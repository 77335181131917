import React from "react";
import AuthorizedElement from "../../../common/auth/AuthorizedElement";
import {
    ROLES_ADMIN_MANAGER,
    ROLES_NOT_STUDENT,
    ROLES_STUDENT_TEACHER
} from "../../../../utils/constants";
import {
    Button,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import {ROUTE_INSTITUTION} from "../../../../utils/routes";
import {buildUrl} from "../../../../utils/axiosRequests";
import {useIntl} from "react-intl";

import {styles} from "../../../../AppStyles";
import {makeStyles} from "@material-ui/core/styles";

import CastRoundedIcon from '@material-ui/icons/CastRounded';
import VideocamRoundedIcon from '@material-ui/icons/VideocamRounded';
import PanoramaRoundedIcon from '@material-ui/icons/PanoramaRounded';
import SettingsInputHdmiRoundedIcon from '@material-ui/icons/SettingsInputHdmiRounded';

import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import VisibilityOffRoundedIcon from '@material-ui/icons/VisibilityOffRounded';
import Tooltip from "@material-ui/core/Tooltip";
import {setIconColor, setIconTooltip, setNotAvailableTooltip} from "../../../../utils/utils";

const ALIGN_COLUMN_LEFT = 'left';
const ALIGN_COLUMN_CENTER = 'center';
const WIDTH_COLUMN_AUTO = 'auto';
const WIDTH_COLUMN_WIDE = 180;

const columns = [
    {id: 'institution', label: 'institution', align: ALIGN_COLUMN_LEFT, minWidth: WIDTH_COLUMN_AUTO},
    {id: 'room', label: 'room', align: ALIGN_COLUMN_LEFT, minWidth: WIDTH_COLUMN_AUTO},
    {id: 'emissionInfo', label: 'emissionInfo', align: ALIGN_COLUMN_CENTER, minWidth: WIDTH_COLUMN_AUTO},
    {id: 'emissionStatus', label: 'emissionStatus', align: ALIGN_COLUMN_CENTER, minWidth: WIDTH_COLUMN_WIDE},
    {id: 'actions', label: 'actions', align: ALIGN_COLUMN_CENTER, minWidth: WIDTH_COLUMN_WIDE},
];

const useStyles = makeStyles(theme => styles(theme));

export default function TableListComponent({handleGoToHistory, handleGoToRoom, handleOpenToCreateRoom, rooms}) {

    const intl = useIntl();
    const classes = useStyles();


    return (
        <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label="aria-label">
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                key={column.id}
                                align={column.align}
                                style={{minWidth: column.minWidth}}
                                className={classes.tableHeader}
                            >
                                {intl.formatMessage({id: column.label})}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rooms.map((room) => (
                        <TableRow
                            key={room.room_id}
                            className={classes.tableRow}>
                            <TableCell align={ALIGN_COLUMN_LEFT} className={classes.tableCell}
                                       style={{minWidth: WIDTH_COLUMN_AUTO}}>
                                <Link
                                    href={buildUrl(ROUTE_INSTITUTION, {institution_id: room.institution_id})}
                                    className={classes.link}>
                                    {room.institution_description}
                                </Link>
                            </TableCell>
                            <TableCell className={classes.tableCell}
                                       style={{minWidth: WIDTH_COLUMN_AUTO}}>
                                <Typography variant={"body2"} align={"left"} className={classes.ellipsis}>
                                    {room.room_description}
                                </Typography>
                            </TableCell>
                            {room.event_id ?
                                <TableCell
                                    align="center" className={classes.tableCell}
                                    style={{minWidth: WIDTH_COLUMN_AUTO}}>{room.class_description} | {room.teacher_name}</TableCell> :
                                <TableCell
                                    align="center" className={classes.tableCell}
                                    style={{minWidth: WIDTH_COLUMN_AUTO}}>{intl.formatMessage({id: 'withoutService'})}</TableCell>
                            }
                            <AuthorizedElement roles={ROLES_ADMIN_MANAGER}>
                                <TableCell align="center" className={classes.tableCell}
                                           style={{minWidth: WIDTH_COLUMN_AUTO}}>
                                    <Tooltip arrow title={room.status_json && room.status_json.camera_config ?
                                        setIconTooltip("class_icon_camera_config", room.status_json.last_check, room.status_json.camera_config, intl)
                                        : setNotAvailableTooltip("class_icon_camera_config", intl)}>
                                        <CastRoundedIcon
                                            color={room.status_json && room.status_json.camera_config ? setIconColor(room.status_json.camera_config) : "disabled"}
                                            className={classes.icon}/>
                                    </Tooltip>
                                    <Tooltip arrow title={room.status_json && room.status_json.camera_rtsp ?
                                        setIconTooltip("class_icon_camera_rtsp", room.status_json.last_check, room.status_json.camera_rtsp, intl)
                                        : setNotAvailableTooltip("class_icon_camera_rtsp", intl)}>
                                        <VideocamRoundedIcon
                                            color={room.status_json && room.status_json.camera_rtsp ? setIconColor(room.status_json.camera_rtsp) : "disabled"}
                                            className={classes.icon}/>
                                    </Tooltip>
                                    <Tooltip arrow title={room.status_json && room.status_json.camera_jpg_capture ?
                                        setIconTooltip("class_icon_camera_jpg_capture", room.status_json.last_check, room.status_json.camera_jpg_capture, intl)
                                        : setNotAvailableTooltip("class_icon_camera_jpg_capture", intl)}>
                                        <PanoramaRoundedIcon
                                            color={room.status_json && room.status_json.camera_jpg_capture ? setIconColor(room.status_json.camera_jpg_capture) : "disabled"}
                                            className={classes.icon}/>
                                    </Tooltip>
                                    <Tooltip arrow title={room.status_json && room.status_json.encoder_rtsp ?
                                        setIconTooltip("class_icon_encoder_rtsp", room.status_json.last_check, room.status_json.encoder_rtsp, intl)
                                        : setNotAvailableTooltip("class_icon_encoder_rtsp", intl)}>
                                        <SettingsInputHdmiRoundedIcon
                                            color={room.status_json && room.status_json.encoder_rtsp ? setIconColor(room.status_json.encoder_rtsp) : "disabled"}
                                            className={classes.icon}/>
                                    </Tooltip>
                                    {!room.hidden ?
                                        <Tooltip arrow title={intl.formatMessage({id: 'class_icon_visibility_on'})}>
                                            <VisibilityRoundedIcon color={"primary"} className={classes.icon}/>
                                        </Tooltip> :
                                        <Tooltip arrow title={intl.formatMessage({id: 'class_icon_visibility_off'})}>
                                            <VisibilityOffRoundedIcon color={"secondary"} className={classes.icon}/>
                                        </Tooltip>}
                                </TableCell>
                            </AuthorizedElement>
                            <AuthorizedElement roles={ROLES_STUDENT_TEACHER}>
                                <TableCell align="center" className={classes.tableCell}
                                           style={{minWidth: WIDTH_COLUMN_AUTO}}>
                                    {intl.formatMessage({id: "class_icon_not_available"})}
                                </TableCell>
                            </AuthorizedElement>

                            <TableCell align="center" className={classes.tableCell}
                                       style={{minWidth: WIDTH_COLUMN_AUTO}}>
                                {room.event_status === "started" ?
                                    <Button variant="contained" color={"primary"} className={classes.boton}
                                            onClick={() => {
                                                handleGoToRoom(room.room_id)
                                            }}>
                                        {intl.formatMessage({id: 'goto'})}
                                    </Button>
                                    :
                                    <AuthorizedElement roles={ROLES_NOT_STUDENT}>
                                        <Button variant="contained" color={"secondary"}
                                                className={classes.boton}
                                                onClick={() => handleOpenToCreateRoom(
                                                    room.room_id,
                                                    room.room_description,
                                                    room.class_description,
                                                    room.event_id,
                                                )}
                                                disabled={room.event_status === "pending"}>
                                            {intl.formatMessage({id: room.event_status === "pending" ? 'loading_description' : "create"})}
                                        </Button>
                                    </AuthorizedElement>}
                                <AuthorizedElement roles={ROLES_ADMIN_MANAGER}>
                                    <Button variant="contained" className={classes.gotohistory}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                handleGoToHistory(room)
                                            }}>
                                        {intl.formatMessage({id: 'go_history'})}
                                    </Button>
                                </AuthorizedElement>
                            </TableCell>
                        </TableRow>))}
                </TableBody>
            </Table>
        </TableContainer>)
}