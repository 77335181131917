import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {getStyles} from "./ClassroomStyles"
import {Grid, IconButton, withStyles} from '@material-ui/core';
import {compose} from "redux";
import SyncAltRoundedIcon from "@material-ui/icons/SyncAltRounded";

import ChatComponent from "./components/chatComponent/ChatComponent";
import ScreenshotCarouselComponent from "./components/screenShotCarouselComponent/ScreenshotCarouselComponent";
import {buildUrl, post} from "../../../../utils/axiosRequests";
import {
    ROLE_STUDENT,
    ROLES_NOT_STUDENT,
    ROOM_STILL_HERE_TIMEOUT,
    SEVERITY_ERROR,
    TIIVII_API_ENCODER,
    TIIVII_API_ROOM_STILL_HERE
} from "../../../../utils/constants";
import {showMessage} from "../../../common/NotificationSnack";
import AuthorizedElement from "../../../common/auth/AuthorizedElement";
import ClassroomFormComponent from "../../../common/classroomFormComponent/ClassroomFormComponent";
import ErrorComponent from "../../../common/ErrorComponent";

import keycloak from '../../../../utils/keycloak';
import PropTypes from "prop-types";
import ButtonMobileScreenComponent from "./components/buttonsMobileScreenComponent/ButtonMobileScreenComponent";
import Hidden from "@material-ui/core/Hidden";
import {FooterComponent} from "../../../common/footerComponent/FooterComponent";
import {
    CLASS_CAPTURES_REF,
    CLASS_CHAT_REF,
    CLASS_INFO_CONNECTION_REF,
    CLASS_INFO_FOOTER_REF,
    CLASS_INFO_REF,
    CLASS_INFO_SETTINGS_REF,
    CLASS_SCREENSHOT_CAROUSEL_REF
} from "./ClassroomConstants";
import {connect} from "react-redux";
import {
    setClassroomActiveUserCreator, setLoggedUserActionCreator,
    turnOffLoadingActionCreator,
    turnOnLoadingActionCreator
} from "../../../../actions/commonActions";
import AlertDialog from "../../../common/alertDialog/AlertDialog";
import Typography from "@material-ui/core/Typography";
import {VideoComponent} from "./components/videoComponent/VideoComponent";
import RoomInfoComponent from "./components/roomInfoComponent/RoomInfoComponent";
import StudentStatusComponent from "./components/studentStatusComponent/StudentStatusComponent";
import {ControlStudentContainer} from "./components/controlStudentList/ControlStudentContainer";

const styles = theme => (getStyles(theme));

const INITIAL_STATE = {
    role: null,
    activeUsers: null,
    roomInfo: null,
    currentEvent: null,
    classInfo: null,
    statusJson: null,
    teacherCam: true,
    keepAlive: true,
    roomDataToEdit: {},
    openModal: false,
    username: '',
    keycloak: null,
    authenticated: false,
    video1: null,
    video2: null,
    mute1: false,
    mute2: true,
    playing: true,
    selectedButtonInformationMobile: null,
    autoplayWarning: false,
    disableSwitch: false,
    loadVideoError: {},
    event_id: null,
};

class ClassroomComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE};
        this.intervalRoomActive = null;
        this.styleInterval = null;
        this.room_id = this.props.match.params.room_id
        this[CLASS_INFO_REF] = React.createRef();
        this[CLASS_INFO_CONNECTION_REF] = React.createRef();
        this[CLASS_INFO_SETTINGS_REF] = React.createRef();
        this[CLASS_CAPTURES_REF] = React.createRef();
        this[CLASS_CHAT_REF] = React.createRef();
        this[CLASS_INFO_FOOTER_REF] = React.createRef();
        this[CLASS_SCREENSHOT_CAROUSEL_REF] = React.createRef();
    };

    componentDidMount() {
        const {handleChangePutGlobalFooter} = this.props;
        if (keycloak && keycloak.authenticated) {
            keycloak.loadUserProfile().then(response => {
                this.setState({username: response.username});
            }).catch(error => showMessage(error, SEVERITY_ERROR));
            this.setState({keycloak, authenticated: true});
            this.getRoom(true);
            keycloak.realmAccess.roles.find(role => role === "teacher") ?
                this.setState({
                    mute1: true,
                    mute2: true,
                    role: keycloak.realmAccess.roles[0]
                }) : this.setState({mute1: false, mute2: true, role: keycloak.realmAccess.roles[0]})
        }
        handleChangePutGlobalFooter(false);
        this.createIntervalRoomActive();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {currentEvent} = this.state;
        const {getRoomInfo, loggedUser} = this.props;
        if (!prevState.currentEvent && currentEvent) {
            this.loadVideoSource();
            this.loadEventId();
        }
        if (prevState.currentEvent && ((prevState.currentEvent.encoderActive === 0 && currentEvent.encoderActive === 1) || (prevState.currentEvent.encoderActive === 1 && currentEvent.encoderActive === 0))) {
            this.setEncoderHLSURL()
        }
        if (!this.props.loading && this.props.classroomPending.find(item => item === this.room_id)) {
            this.props.turnOnLoadingActionCreator()
        }
        if (this.props.loading && prevProps.classroomPending.find(item => item === this.room_id) && !this.props.classroomPending.find(item => item === this.room_id)) {
            this.props.turnOffLoadingActionCreator()
        }
        if (loggedUser && prevProps.loggedUser && loggedUser.status !== prevProps.loggedUser.status) {
            getRoomInfo();
        }
    }

    componentWillUnmount() {
        const {handleChangePutGlobalFooter} = this.props;
        handleChangePutGlobalFooter(true);
        clearInterval(this.intervalRoomActive);
    };

    setRoomState(data) {
        const {classInfo, currentEvent, roomInfo, statusJson} = this.state;
        const {setActiveUsers, setLoggedUser, user} = this.props;

        if (roomInfo === null || data.current_event_id !== roomInfo.currentEventId ||
            data.hidden !== roomInfo.hidden ||
            data.institution_id !== roomInfo.institutionId ||
            data.roomDescription !== roomInfo.room_description
        ) {
            this.setState({
                roomInfo: {
                    currentEventId: data.current_event_id,
                    hidden: data.hidden,
                    institutionId: data.institution_id,
                    roomDescription: data.room_description,
                    roomId: data.id
                }
            });
        }
        if (classInfo === null && data.status_json !== statusJson) {
            this.setState({statusJson: data.status_json})
        }
        if ((classInfo === null && data.current_event) || (classInfo && (data.current_event.class_description !== classInfo.classDescription ||
            data.current_event.teacher_name !== classInfo.teacherName))
        ) {
            this.setState({
                classInfo: {
                    teacherName: data.current_event.teacher_name,
                    classDescription: data.current_event.class_description
                }
            })
        }
        if ((currentEvent === null && data.current_event) || data.current_event.camera_hls_url !== currentEvent.cameraHlsUrl ||
            data.current_event.encoder_active !== currentEvent.encoderActive ||
            data.current_event.encoder_hls_url !== currentEvent.encoderHlsUrl ||
            data.current_event.encoder_available !== currentEvent.encoderAvailable || data.current_event.auto_approve_users !== currentEvent.autoApproveUsers
        ) {
            this.setState({
                currentEvent: {
                    cameraHlsUrl: data.current_event.camera_hls_url,
                    encoderActive: data.current_event.encoder_active,
                    encoderHlsUrl: data.current_event.encoder_hls_url,
                    encoderAvailable: data.current_event.encoder_available,
                    autoApproveUsers: data.current_event.auto_approve_users
                }
            })
        }
        setActiveUsers(data.active_users);
        setLoggedUser(data.active_users.find(activeUser => activeUser.user_name === user));
    }

    getRoom = (loading, updateEncoder = false) => {
        const {currentEvent} = this.state;
        post(buildUrl(TIIVII_API_ROOM_STILL_HERE, {roomId: this.room_id}), {}, {}, loading)
            .then(response => {
                if (updateEncoder) {
                    this.setState(
                        {
                            video2: response.data.current_event.encoder_hls_url || null,
                            currentEvent: {
                                ...currentEvent,
                                encoderActive: response.data.current_event.encoder_active,
                                encoderHlsUrl: response.data.current_event.encoder_hls_url
                            }
                        })
                } else {
                    this.setRoomState(response.data);
                }
            })
            .catch(error => showMessage(error, SEVERITY_ERROR));
    };

    setEncoderHLSURL = () => {
        const {currentEvent} = this.state;
        this.setState({
            video2: currentEvent.encoderHlsUrl
        });
    }

    handleDisableSwitch = (video, error) => {
        const {loadVideoError} = this.state;
        const videoError = {...loadVideoError, [video]: error}
        const disabled = Object.values(videoError).indexOf(true) > -1;
        this.setState({loadVideoError: videoError})
        this.setState({disableSwitch: disabled});
        if (disabled) {
            this.setState({teacherCam: true})
        }
    }

    handleUpdateEncoder = () => {
        this.setState({teacherCam: true})
    }

    handleSwitchVideoPosition = () => {
        const {teacherCam} = this.state;

        this.setState({
            teacherCam: !teacherCam,
            mute1: this.state.mute2,
            mute2: this.state.mute1
        })
    }

    handleEncoder = value => {
        const {currentEvent} = this.state;
        const putLoader = false;
        if (!value) {
            this.setState({video2: null})
        }
        post(buildUrl(TIIVII_API_ENCODER, {room_id: this.room_id}), {active: value}, putLoader)
            .then(() => {
                value ?
                    this.getRoom(putLoader, true) :
                    this.setState({
                        teacherCam: true,
                        video2: null,
                        currentEvent: {
                            ...currentEvent,
                            encoderActive: 0,
                            encoderHlsUrl: null
                        }
                    })
            })
            .catch(error => showMessage(error, SEVERITY_ERROR));
    };

    handleOpenModalToEditClassroom = () => {
        const {classInfo, roomInfo, currentEvent} = this.state;
        this.setState({
            roomDataToEdit: {
                "roomId": this.room_id,
                "roomDescription": roomInfo.roomDescription || "",
                "classDescription": classInfo.classDescription || "",
                "classTeacher": classInfo.teacherName || "",
                "classEncoderActive": currentEvent.encoderActive || "",
                "classEncoderAvailable": currentEvent.encoderAvailable || "",
                "autoApproveUsers": currentEvent.autoApproveUsers || ""
            }
        })
        this.setState({openModal: true});
        const putLoader = false;
        this.getRoom(putLoader);
    };

    handleClose = () => {
        this.setState({openModal: false})
        const putLoader = false;
        this.getRoom(putLoader);
    };

    createIntervalRoomActive = () => {
        this.intervalRoomActive = setInterval(() => this.getRoom(false), ROOM_STILL_HERE_TIMEOUT);
    };

    stopIntervalRoomActive = () => {
        clearInterval(this.intervalRoomActive);
    };

    setStateKeepAlive = (value) => {
        this.setState({keepAlive: value});
        value ? this.createIntervalRoomActive() : this.stopIntervalRoomActive();
    };

    displayVideo = () => {
        const {currentEvent} = this.state;
        return !!(currentEvent);
    }

    loadEventId = () => {
        const {roomInfo} = this.state;
        this.setState({event_id: roomInfo ? roomInfo.currentEventId : null})
    }

    loadVideoSource = () => {
        const {currentEvent} = this.state;
        this.setState({
            video1: currentEvent.cameraHlsUrl,
            video2: currentEvent.encoderHlsUrl || null
        })
    }

    handleMute = (audio, value) => {
        audio === 1 ? this.setState({mute1: !value}) : this.setState({mute2: !value})
    }

    handlePlayingState = value => {
        this.setState({playing: value})
    }

    handleAutoplayWarning = value => {
        this.setState({autoplayWarning: value})
    }

    // Mobile version
    handleOpenClassroomInformation = (refContent) => {
        if (this[refContent].current.style.display === "flex") {
            this[refContent].current.style.display = "none";
            this[refContent].current.style.zIndex = 0;
            this[CLASS_SCREENSHOT_CAROUSEL_REF].current.style.zIndex = 1;
            this.setState({selectedButtonInformationMobile: undefined});
        } else {
            this.clearInformationBox();
            this[refContent].current.style.display = "flex";
            this[refContent].current.style.zIndex = 5;
            this[CLASS_SCREENSHOT_CAROUSEL_REF].current.style.zIndex = 0;
            this.setState({selectedButtonInformationMobile: refContent});
        }
    }

    clearInformationBox = () => {
        const refsToShowWithButtons = ["classInfoRef", "infoConnectionRef", "chatRef"];
        refsToShowWithButtons.forEach(ref => this[ref].current.style.display = "none");
        refsToShowWithButtons.forEach(ref => this[ref].current.style.zIndex = 0);
        this[CLASS_SCREENSHOT_CAROUSEL_REF].current.style.zIndex = 1;
    }

    getMainVideo = () => {
        const {keepAlive, mute1, playing, teacherCam, video1, video2} = this.state;
        if (keepAlive) {
            if (this.displayVideo() && video1 && teacherCam) {
                return {mute: mute1, playing: playing, video: video1}

            } else {
                return {mute: mute1, playing: playing, video: video2}
            }
        } else {
            return {mute: null, playing: false, video: null}
        }
    }

    getEncoderVideo = () => {
        const {keepAlive, mute2, playing, teacherCam, video1, video2} = this.state;
        if (keepAlive && video2 !== null) {
            if (this.displayVideo() && video1 && teacherCam) {
                return {mute: mute2, playing: playing, video: video2}
            } else {
                return {mute: mute2, playing: playing, video: video1}
            }
        } else {
            return {mute: null, playing: false, video: null}
        }
    }

    render() {
        const {classes, intl, setActiveUsers, loggedUser} = this.props;
        const {
            activeUsers, roomInfo, classInfo, currentEvent, keepAlive, username, openModal, roomDataToEdit,
            autoplayWarning, disableSwitch, statusJson, video2, event_id
        } = this.state;
        if (!!roomInfo && !roomInfo.currentEventId) {
            this.stopIntervalRoomActive();
            return <ErrorComponent error={<FormattedMessage id="classroom_canceled"/>}/>
        }

        const video1Show = this.getMainVideo();
        const video2Show = this.getEncoderVideo();
        

        return (
            <div>
                <Grid container className={classes.container}>
                    <Grid item container xs={12} lg={8} className={classes.left}>
                        <Grid item xs={12} className={classes.screen}>
                            <VideoComponent
                                key={"longVideo"}
                                handleMute={this.handleMute}
                                handlePlayingState={this.handlePlayingState}
                                handleDisableSwitch={this.handleDisableSwitch}
                                handleAutoplayWarning={this.handleAutoplayWarning} value={1}
                                mute={video1Show.mute}
                                playing={video1Show.playing}
                                video={video1Show.video}
                                width={"99%"} size={"long"}
                                show={true}
                            />
                        </Grid>
                        <Grid container className={classes.infoContainer}>
                            <Grid className={classes.box} item xs={12} lg={4} ref={this[CLASS_INFO_REF]}>
                                {currentEvent && roomInfo &&
                                <ControlStudentContainer classes={classes}
                                                         roomInfo={roomInfo}
                                                         activeUsers={activeUsers}
                                                         setActiveUsers={setActiveUsers}
                                                         loggedUser={loggedUser}/>}
                            </Grid>
                            <Grid item className={classes.classBox} xs={12} lg={4}
                                  ref={this[CLASS_INFO_CONNECTION_REF]}>
                                <AuthorizedElement roles={[ROLE_STUDENT]}>
                                    <StudentStatusComponent setKeepAlive={this.setStateKeepAlive}
                                                            handleOpenClassroomInformation={this.handleOpenClassroomInformation}
                                                            roomInfo={roomInfo} classInfo={classInfo}
                                                            stopIntervalRoomActive={this.stopIntervalRoomActive}/>
                                </AuthorizedElement>
                                {roomInfo && currentEvent &&
                                <AuthorizedElement roles={ROLES_NOT_STUDENT}>
                                    <RoomInfoComponent
                                        encoderAvailable={currentEvent.encoderAvailable}
                                        encoder={currentEvent.encoderActive}
                                        hidden={roomInfo.hidden}
                                        setEncoder={this.handleEncoder} roomId={this.room_id}
                                        institutionId={roomInfo && roomInfo.institutionId}
                                        handleOpenModalToEditClassroom={this.handleOpenModalToEditClassroom}
                                        statusJson={statusJson}
                                        stopIntervalRoomActive={this.stopIntervalRoomActive}
                                        roomInfo={roomInfo}
                                        classInfo={classInfo}/>
                                </AuthorizedElement>}
                            </Grid>
                            <Hidden mdDown>
                                <Grid item className={classes.screenshotBox} xs={12} lg={4}>
                                    {currentEvent &&
                                    <ScreenshotCarouselComponent roomId={this.room_id} keepAlive={keepAlive}
                                                                 ref={CLASS_SCREENSHOT_CAROUSEL_REF}/>}
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} lg={4} className={classes.right}>
                        <Grid item xs={12} lg={2} className={classes.switch}>
                            {this.displayVideo() && currentEvent.encoderActive !== 0 && currentEvent.encoderAvailable !== 0 && video2 &&
                            <IconButton className={classes.switchButton}
                                        onClick={() => this.handleSwitchVideoPosition()}
                                        disabled={disableSwitch}>
                                <SyncAltRoundedIcon className={classes.iconSwitchButton}/>
                            </IconButton>}
                        </Grid>
                        <Grid item xs={12} lg={10} container className={classes.rightContainer}>
                            <Grid item className={classes.smallScreen}>
                                <VideoComponent
                                    key={"shortVideo"}
                                    handleMute={this.handleMute}
                                    handlePlayingState={this.handlePlayingState}
                                    handleDisableSwitch={this.handleDisableSwitch}
                                    handleAutoplayWarning={this.handleAutoplayWarning}
                                    mute={video2Show.mute}
                                    playing={video2Show.playing}
                                    video={video2Show.video}
                                    value={2}
                                    width={"100%"} size={"short"}
                                    show={currentEvent && currentEvent.encoderAvailable !== 0}
                                />
                            </Grid>
                            <Grid item className={classes.chat} ref={this[CLASS_CHAT_REF]}>
                                <ChatComponent roomId={this.room_id}
                                               eventId={event_id}
                                               username={username}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Hidden lgUp>
                        <Grid item xs={12}>
                            <ScreenshotCarouselComponent roomId={this.room_id} keepAlive={keepAlive}
                                                         ref={this[CLASS_SCREENSHOT_CAROUSEL_REF]}/>
                        </Grid>
                    </Hidden>
                    <Hidden lgUp>
                        <Grid item xs={12} className={classes.footer}>
                            <FooterComponent/>
                        </Grid>
                    </Hidden>
                    {Object.keys(roomDataToEdit).length > 0 &&
                    <ClassroomFormComponent open={openModal} roomDataToEdit={roomDataToEdit}
                                            handleClose={this.handleClose}
                                            handleUpdateEncoder={this.handleUpdateEncoder}/>}
                </Grid>
                <Hidden lgUp>
                    <ButtonMobileScreenComponent
                        handleOpenClassroomInformation={this.handleOpenClassroomInformation}
                        selectedButtonInformationMobile={this.state.selectedButtonInformationMobile}
                    />
                </Hidden>
                <Hidden lgDown>
                    <Grid item xs={12}>
                        <FooterComponent/>
                    </Grid>
                </Hidden>
                <AlertDialog buttonCloseText={<FormattedMessage id="global_buttons_close"/>}
                             description={<Typography
                                 dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "autoplay_user_advice"})}}/>}
                             onClose={() => this.handleAutoplayWarning(false)}
                             open={autoplayWarning}
                             title={<FormattedMessage id="autoplay_warning_title"/>}/>
            </div>
        );
    }
}

ClassroomComponent.propTypes = {
    classes: PropTypes.object,
    loading: PropTypes.bool,
    classroomPending: PropTypes.array,
    setClassroomStatus: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
    turnOffLoadingActionCreator: () => dispatch(turnOffLoadingActionCreator()),
    turnOnLoadingActionCreator: () => dispatch(turnOnLoadingActionCreator()),
    setActiveUsers: (data) => dispatch(setClassroomActiveUserCreator(data)),
    setLoggedUser: (data) => dispatch(setLoggedUserActionCreator(data)),
});
const mapStateToProps = ({classroom, commonState}) => ({
    classroomPending: classroom.classroomPending,
    loading: commonState.loading,
    loggedUser: commonState.loggedUser
});
export default compose(
    injectIntl,
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(ClassroomComponent);
