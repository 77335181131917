import React from 'react';

import PropTypes from "prop-types";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import {useIntl} from "react-intl";

export default function ConfirmationDialog({handleOk, title, helper, ...rest}) {
    const [open, setOpen] = React.useState(false);
    const {children} = rest;
    const intl = useIntl();

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = () => {
        handleClose();
        handleOk();
    }
    
    return (
        <>
            {typeof children === "function" ? children(() => setOpen(true)) : children}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {helper}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {intl.formatMessage({id: 'cancel'})}
                    </Button>
                    <Button onClick={handleClick} color="primary" autoFocus>
                        {intl.formatMessage({id: 'continue'})}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}


ConfirmationDialog.propTypes = {
    handleOk: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    helper: PropTypes.string.isRequired,
    children: PropTypes.func.isRequired
}