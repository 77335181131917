export const getStyles = theme => {
    return {
        screenshotBox: {
            display: "flex",
            flexFlow: "nowrap column",
            justifyContent: "center",
            height: "100%",
            position: "relative",
            [theme.breakpoints.down('md')]: {
                zIndex: 1,
            },
        },
        imageContainer: {
            position: "relative",
            width: "100%",
            height: "80%",
            overflow:"hidden",
            marginTop: 5,
        },
        screenshot: {
            width: "98%",
        },
        button: {
            zIndex: "1",
            "&:hover": {
                color: "#049fe3"
            }
        },
        controlsContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "20%",
            backgroundColor:"white"
        },
        modal: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: "auto",
            marginRight: "auto",
            width: "80%",
        },
        modalContainer: {
            position: "relative",
            margin:"auto 0"
        },
        closeButton: {
            zIndex: "1",
            position: "absolute",
            right: "20px",
            top: "20px",
            backgroundColor: "white",
            "&:hover": {
                backgroundColor: "white",
                color: "#049fe3"
            }
        },
        downloadButton: {
            zIndex: "1",
            position: "absolute",
            right: "10px",
            bottom: "10px",
            backgroundColor: "white",
            "&:hover": {
                backgroundColor: "white",
                color: "#049fe3"
            }
        },
        screenButton: {
            zIndex: "1",
            position: "absolute",
            right: "10px",
            top: "10px",
            backgroundColor: "white",
            "&:hover": {
                backgroundColor: "white",
                color: "#049fe3"
            },
            [theme.breakpoints.down('md')]: {
                display: "none",
            },
        },
        screenshotFull: {
            width: "100%",
            outline: "none",
            border: "none",
        }
    }
}
