export const styles = theme => {
    return {
        app: {
            textAlign: "center",
            width: "100%",
        },
        tableList: {
            margin: "0px 24px",
            [theme.breakpoints.down('md')]: {
                margin: "0 auto",
                width: "100%",
            },
        },
        tableContainer: {
            maxHeight: "70vh",
            [theme.breakpoints.down('md')]: {
                maxHeight: "none !important"
            },
        },
        tableHeader: {
            fontWeight: "bold !important",
            textTransform: "uppercase",
        },
        tableRow: {
            '&:hover': {
                backgroundColor: "#1fb0e7 !important",
                color: "white !important",
            },
        },
        tableCell: {
            color: "inherit",
            [theme.breakpoints.down('md')]: {
                textAlign: "center",
                fontSize: "1.5rem"
            }, [theme.breakpoints.down('xs')]: {
                textAlign: "center",
                fontSize: "1rem"
            },
        },
        boton: {
            color: "white !important",
            textTransform: "capitalize !important"
        },
        link: {
            textDecoration: 'none',
            color: "inherit",
            '&:hover': {
                textDecoration: 'underline',
            }
        },
        gotohistory: {
            backgroundColor: "#aeaeae",
            color: "white",
            marginLeft: 10,
            textTransform: "capitalize !important"
        },
        ellipsis: {
            width: 240,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        },
        icon:{
            margin:"0 3%",
            '&:hover': {
                backgroundColor: "#1fb0e7 !important",
                color: "white !important"
            },
        }
    }
};