export const getStyles = theme => {
    return {
        title: {
            marginBottom: "8%"
        },
        controlBox: {
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            height: "calc(100% - 40px)",
        },
        iconsContainer: {
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            margin: theme.spacing(6, 0)
        },
        tooltip: {
        },
        container: {
            display: "flex",
            flexDirection: "column",
        },
        tableRow: {
            display: "flex",
            alignItems: "center",
            margin: theme.spacing(2)
        },
        icon: {
            marginRight: theme.spacing(2)
        }
    }
}
