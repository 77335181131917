import background_image from "../../../../images/cam_edu_background.jpg";

export const styles = theme => {
    return {
        root: {
            background: `url(${background_image})`,
            backgroundSize: "cover",
            display: "flex",
            flexFlow: "nowrap column",
            alignItems: "center",
        },
        title: {
            color: "#444",
            padding: 12
        },
        paper: {
            width: "50%",
            padding: theme.spacing(2),
            margin: theme.spacing(4),
            backgroundColor: 'rgba(255,255,255,0.6)',
            [theme.breakpoints.down('md')]: {
                minWidth: 500
            },
            [theme.breakpoints.down('sm')]: {
                width: '33%',
                minWidth: 240
            }
        },
        logo: {
            marginTop: theme.spacing(4),
            width: "25%",
            [theme.breakpoints.down('sm')]: {
                width: "60%",
                marginTop: "100px"
            },
        },
        divider: {
            margin: theme.spacing(2, 0)
        },
        text: {
            textAlign: "left",
            marginBottom: theme.spacing(2),
        },
        link: {
            cursor: 'pointer',
            '&:hover': {
                color: 'white'
            }
        },
        button: {
            //margin: theme.spacing(4, 1),
            fontWeight: 'bold',
            color: '#fff',
            [theme.breakpoints.down('sm')]: {
                margin: theme.spacing(1)
            },
        }
    }
}