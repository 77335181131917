import React, {useCallback, useEffect, useState} from "react";
import ControlStudentListComponent from "./ControlStudentListComponent";
import {ROLE_STUDENT} from "../../../../../../utils/constants";

export const ControlStudentContainer = ({classes, roomInfo, activeUsers, setActiveUsers, loggedUser}) => {

    const [notificationClass, setNotificationClass] = useState(true);
    const [startInterval, setStartInterval] = useState(false);

    const handleInterval = useCallback(value => {
        setStartInterval(value)
    }, [])

    const toggleClass = useCallback(() => {
        setNotificationClass(notificationClass => !notificationClass)
    }, [])


    useEffect(() => {
        let styleInterval;
        if (startInterval && loggedUser.status !== ROLE_STUDENT) {
            styleInterval = setInterval(toggleClass, 1500);
        }
        return () => {
            setNotificationClass(true)
            clearInterval(styleInterval)
        };
    }, [startInterval, toggleClass, loggedUser])


    return (
        <div className={`${classes.commonBox} ${!notificationClass ? classes.highlightBox : classes.infoBox}`}>
            <ControlStudentListComponent roomInfo={roomInfo}
                                         activeUsers={activeUsers}
                                         handleInterval={handleInterval}
                                         setActiveUsers={setActiveUsers}
                                         loggedUser={loggedUser}/>
        </div>
    )
}