import React from 'react';

import { ReactComponent as Settings } from '../../../../../../images/marketing.svg';
import { ReactComponent as Chat } from '../../../../../../images/list.svg';
import { ReactComponent as Connection } from '../../../../../../images/broken-link.svg';
import { ReactComponent as Teacher } from '../../../../../../images/teacher.svg';

import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core";
import {styles} from "./buttonMobileScreenStyles";
import Typography from "@material-ui/core/Typography";
import {useIntl} from "react-intl";
import {ROLE_STUDENT, ROLES_NOT_STUDENT} from "../../../../../../utils/constants";
import AuthorizedElement from "../../../../../common/auth/AuthorizedElement";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import {CLASS_CHAT_REF, CLASS_INFO_CONNECTION_REF, CLASS_INFO_REF} from "../../ClassroomConstants";

function ButtonMobileScreenComponent (props) {
    const { classes, handleOpenClassroomInformation, selectedButtonInformationMobile } = props;
    const intl = useIntl();
    const handleClickButtonMobil = (boxRefToOpen) => {
        handleOpenClassroomInformation(boxRefToOpen);
    }

    return (
        <ToggleButtonGroup aria-label="primary button group"  className={classes.buttonsGroupMobile}>
            <ToggleButton  value="center"  selected={(selectedButtonInformationMobile === CLASS_INFO_REF)}
                    classes={{label:classes.buttonLabelMobile, selected: classes.buttonSelected}}
                    className={classes.buttonMobile}
                    onClick={(event) => {
                        handleClickButtonMobil(CLASS_INFO_REF)
                    }}
            >
                <SvgIcon component={Teacher} viewBox="0 0 600 600" fontSize="large" />
                <Typography>{intl.formatMessage({id: 'room'})}</Typography>
            </ToggleButton>
            <AuthorizedElement roles={[ROLE_STUDENT]}>
                <ToggleButton  value="center"  selected={(selectedButtonInformationMobile === CLASS_INFO_CONNECTION_REF)}
                        classes={{label:classes.buttonLabelMobile, selected: classes.buttonSelected}} className={classes.buttonMobile}
                        onClick={(event) => {
                                handleClickButtonMobil(CLASS_INFO_CONNECTION_REF)
                        }}
                >
                    <SvgIcon component={Connection} viewBox="0 0 600 476.6" fontSize="large" />
                    <Typography>{intl.formatMessage({id: 'connection'})}</Typography>
                </ToggleButton>
            </AuthorizedElement>
            <ToggleButton  value="center"  selected={(selectedButtonInformationMobile === CLASS_CHAT_REF)}
                          classes={{label:classes.buttonLabelMobile, selected: classes.buttonSelected}} className={classes.buttonMobile}
                          onClick={(event) => {
                              handleClickButtonMobil(CLASS_CHAT_REF)
                          }}
                >
                <SvgIcon component={Chat}  fontSize="large" viewBox="0 0 600 400"/>
                <Typography>{intl.formatMessage({id: 'chat'})}</Typography>
            </ToggleButton>
            <AuthorizedElement roles={ROLES_NOT_STUDENT}>
                <ToggleButton  value="center"  selected={(selectedButtonInformationMobile === CLASS_INFO_CONNECTION_REF)}
                              classes={{label:classes.buttonLabelMobile, selected: classes.buttonSelected}} className={classes.buttonMobile}
                              onClick={(event) => {
                                  handleClickButtonMobil(CLASS_INFO_CONNECTION_REF)
                              }}
                >
                    <SvgIcon component={Settings} viewBox="0 0 600 476.6" fontSize="large" />
                    <Typography>{intl.formatMessage({id: 'settings'})}</Typography>
                </ToggleButton>
            </AuthorizedElement>
        </ToggleButtonGroup>
    )
}

export default withStyles(styles)(ButtonMobileScreenComponent);