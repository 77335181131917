import React from "react";

import {styles} from './NoAllowedStyles';
import {makeStyles} from "@material-ui/core/styles";
import {useIntl} from "react-intl";

const useStyles = makeStyles(theme => (styles(theme)));

export default function NoAllowedComponent({subtitle, title}) {
    const classes = useStyles();
    const intl = useIntl();
    return (
        <div className={classes.textContainer}>
            <h2>{intl.formatMessage({id: `${title}`})}</h2>
            <p>{intl.formatMessage({id: `${subtitle}`})}</p>
        </div>
    )
}