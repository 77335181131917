export const getStyles = theme => {
    return {
        list: {
            height: "calc(100%-40px)",
            textAlign: "left",
            padding: theme.spacing(2)
        },
        listElement: {
            marginBottom: theme.spacing(1),
        },
        iconsContainer: {
            display: "flex",
            justifyContent: "space-around",
            marginTop: theme.spacing(2),
        },
        statusHeader:{
            marginBottom: theme.spacing(2),
        },
        button:{
            marginBottom: theme.spacing(2)
        }
    }
}
