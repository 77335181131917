const TASK1 = "changelog_task_1_1.2.0";
const TASK2 = "changelog_task_2_1.2.0";
const TASK3 = "changelog_task_3_1.2.0";
const TASK4 = "changelog_task_4_1.2.0";
const TASK5 = "changelog_task_5_1.2.0";
const TASK6 = "changelog_task_6_1.2.0";
const TASK7 = "changelog_task_7_1.2.0";
const TASK8 = "changelog_task_8_1.2.0";
const TASK9 = "changelog_task_9_1.2.0";

const TASK10 = "changelog_task_10_1.3.0";
const TASK11 = "changelog_task_11_1.3.0";
const TASK12 = "changelog_task_12_1.3.0";
const TASK13 = "changelog_task_13_1.3.0";
const TASK14 = "changelog_task_14_1.3.0";

const TASK15 = "changelog_task_15_1.3.1";
const TASK16 = "changelog_task_16_1.3.1";
const TASK17 = "changelog_task_17_1.3.1";

const TASK18 = "changelog_task_18_1.4.0";
const TASK19 = "changelog_task_19_1.4.0";

const TASK20 = "changelog_task_20_1.4.1";

export const CHANGELOG_TASKS_1_2_0 = [TASK1, TASK2, TASK3, TASK4, TASK5, TASK6, TASK7, TASK8, TASK9];
export const CHANGELOG_TASKS_1_3_0 = [TASK10, TASK11, TASK12, TASK13, TASK14];
export const CHANGELOG_TASKS_1_3_1 = [TASK15, TASK16, TASK17];
export const CHANGELOG_TASKS_1_4_0 = [TASK18, TASK19];
export const CHANGELOG_TASKS_1_4_1 = [TASK20];

export const VERSION_1_4_1 = "1.4.1";
export const VERSION_1_4_0 = "1.4.0";
export const VERSION_1_3_1 = "1.3.1";
export const VERSION_1_3_0 = "1.3.0";
export const VERSION_1_2_0 = "1.2.0";