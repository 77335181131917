export const styles = theme =>  {
    return{
        root: {
            height: 28,
            width: 28,
            marginLeft: 16
        },
        img: {
            height: '100%',
            width: '100%'
        }
    }
}