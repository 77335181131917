export const getStyles = theme => {
    return {
        controlsContainer: {
            position: "absolute",
            display: "flex",
            width: "90%",
            height: "10%",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            borderRadius: theme.spacing(0.5),
            bottom: theme.spacing(3),
            left:"4%",
            padding: "0 0.6rem",
            opacity: 0
        },
        smallControlsContainer: {
            position: "absolute",
            display: "flex",
            width: "90%",
            height: "20%",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            borderRadius: "2px",
            bottom: "5%",
            left: "3%",
            padding: "0 0.6rem",
            opacity: 0,
            zIndex: 1,
        },
        playerButton: {
            position: "relative",
            zIndex: 1,
            fontSize:"3vh"
        },
        show: {
            transition: "opacity 1s",
            webkitTransition: "opacity 1s",
            opacity: 1
        },
        hide: {
            transition: "opacity 1s",
            webkitTransition: "opacity 1s",
            opacity: 0
        }
    }
}