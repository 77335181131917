import React from 'react';
import logoFooter from "../../../images/Logo_footer.png";
import {useIntl} from "react-intl";
import {styles} from "./FooterStyles"
import {makeStyles} from "@material-ui/core/styles";
import {Hidden} from '@material-ui/core';
import PropTypes from "prop-types";
import {LANGUAGES} from "../../appBarComponent/AppBarConstants";
import {PDFES, PDFGL} from "../../../utils/constants";
import Link from "@material-ui/core/Link";
import {ROUTE_PRIVACY_POLICY_COMPLETE} from "../../../utils/routes";

const useStyles = makeStyles(theme => styles(theme));

export function FooterComponent() {
    const intl = useIntl();
    const classes = useStyles();
    const language = localStorage.getItem("locale") || navigator.language.split(/[-_]/)[0];

    return (
        <footer className={classes.footer}>
            <div>
                <a href="https://www.xunta.gal/cceu" target="_blank" rel="noopener noreferrer">
                    <img className={classes.imageFooter} src={logoFooter}
                         alt={"Xunta de Galicia - Conselleria de cultura, educación e universidade"}/>
                </a>
            </div>
            <div className={classes.linksFooter}>
                <Link className={classes.link} href={ROUTE_PRIVACY_POLICY_COMPLETE} target="_blank"
                      rel="noopener noreferrer">
                    {intl.formatMessage({id: 'policy'})}
                </Link>
                <Hidden smDown>
                    <p className={classes.separation}>|</p>
                </Hidden>
                <Link className={classes.link} href={intl.formatMessage({id: 'contact_link'})} target="_blank"
                      rel="noopener noreferrer">
                    {intl.formatMessage({id: 'contact'})}
                </Link>
                <Hidden smDown>
                    <p className={classes.separation}>|</p>
                </Hidden>
                <Link className={classes.link} href={language === LANGUAGES.GL ? PDFGL : PDFES} target="_blank"
                      rel="noopener noreferrer">
                    {intl.formatMessage({id: 'user_guide'})}
                </Link>
            </div>
        </footer>
    )
}

FooterComponent.propTypes = {
    classes: PropTypes.object
}