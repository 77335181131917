import React from "react";

import {compose} from "redux";

import VolumeUpRoundedIcon from '@material-ui/icons/VolumeUpRounded';
import VolumeOffRoundedIcon from '@material-ui/icons/VolumeOffRounded';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import PauseRoundedIcon from '@material-ui/icons/PauseRounded';

import {IconButton, withStyles} from "@material-ui/core";
import {getStyles} from "./VideoControlsStyles"
import {makeStyles} from "@material-ui/core/styles";

import PropTypes from "prop-types";

const useStyles = makeStyles(theme => getStyles(theme));

const VideoControlsComponent = ({mute, handleMute, playing, handlePlayingState, value, show}) => {
    const classes = useStyles();

    return (
        <div className={`${value === 1 ? classes.controlsContainer : classes.smallControlsContainer} ${show ? classes.show : classes.hide}`}>
            {value === 1 && <IconButton className={classes.playerButton} onClick={() => handlePlayingState(!playing)}>
                {playing ?  <PauseRoundedIcon/> : <PlayArrowRoundedIcon/>}
                </IconButton>}
            {mute ? <IconButton className={classes.playerButton} onClick={() => handleMute(value, mute)}>
                    <VolumeOffRoundedIcon/>
                </IconButton>
                :
                <IconButton className={classes.playerButton} onClick={() => handleMute(value, mute)}>
                    <VolumeUpRoundedIcon/>
                </IconButton>}
        </div>
    )
}

VideoControlsComponent.propTypes = {
    mute: PropTypes.bool.isRequired,
    handleMute: PropTypes.func.isRequired,
    playing: PropTypes.bool.isRequired,
    handlePlayingState: PropTypes.func.isRequired,
    value:PropTypes.number.isRequired,
    show:PropTypes.bool.isRequired
}

export default compose(
    withStyles(getStyles),
)(VideoControlsComponent);
