import React from "react";
import Accordion from "@material-ui/core/Accordion";
import {AccordionSummary, AccordionDetails, Typography, Button} from "@material-ui/core";
import {useIntl} from "react-intl";
import AuthorizedElement from "../../../common/auth/AuthorizedElement";
import {
    ROLES_ADMIN_MANAGER,
    ROLES_NOT_STUDENT
} from "../../../../utils/constants";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./AccordionListStyles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

const useStyles = makeStyles(theme => styles(theme));

export default function AccordionListComponent({handleGoToRoom, handleOpenToCreateRoom, rooms}) {

    const intl = useIntl();
    const classes = useStyles();

    return (
        <div>
            {rooms.map(room =>
                <Accordion key={room.room_id}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>} className={classes.accordionSummary}>
                        <div className={classes.fullRow}>
                            <div className={classes.iconCell}>
                                {room.event_id &&
                                <RadioButtonCheckedIcon color={"primary"} className={classes.eventIcon}/>}
                            </div>
                            <div className={classes.accordionRow}>
                                <AuthorizedElement roles={ROLES_ADMIN_MANAGER}>
                                    <Typography variant="caption"
                                                className={classes.institutionName}>{room.institution_description}</Typography>
                                </AuthorizedElement>
                                <Typography variant="h6"
                                            className={classes.classroomName}>{room.room_description}</Typography>
                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.accordionInfo}>
                            <div className={classes.classInfo}>
                                <Typography variant="button"
                                            className={classes.classTitle}>{intl.formatMessage({id: 'emissionStatus'})}:</Typography>
                                {room.event_id ?
                                    <div>
                                        <Typography
                                            variant="body2"
                                            className={classes.classEvent}>{intl.formatMessage({id: 'classroom'})}: {room.class_description}</Typography>
                                        <Typography
                                            variant="body2"
                                            className={classes.classEvent}>{intl.formatMessage({id: 'teacher'})}: {room.teacher_name}</Typography>
                                    </div>
                                    : <Typography
                                        variant="body2"
                                        className={classes.classEvent}>{intl.formatMessage({id: 'withoutService'})}</Typography>
                                }
                            </div>
                            <div>
                                {room.event_status === "started" ?
                                    <Button variant="contained" color={"primary"} className={classes.button}
                                            onClick={() => {
                                                handleGoToRoom(room.room_id)
                                            }}>
                                        {intl.formatMessage({id: 'goto'})}
                                    </Button>
                                    :
                                    <AuthorizedElement roles={ROLES_NOT_STUDENT}>
                                        <Button variant="contained" color={"secondary"}
                                                className={classes.button}
                                                onClick={() => handleOpenToCreateRoom(
                                                    room.room_id,
                                                    room.room_description,
                                                    room.class_description,
                                                    room.event_id,
                                                )}
                                                disabled={room.event_status === "pending"}>
                                            {intl.formatMessage({id: room.event_status === "pending" ? 'loading_description' : "create"})}
                                        </Button>
                                    </AuthorizedElement>}
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>)}
        </div>
    )
}