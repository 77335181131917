export const HISTORY_TABLA_PAGE_ITEMS = 10;
export const ALIGN_COLUMN_LEFT = 'left';
export const ALIGN_COLUMN_CENTER = 'center';

export function narrowColumn(fullWidth) {
    return fullWidth / 15
}

export function regularColumn(fullWidth) {
    return 2 * (fullWidth / 15)
}

export function descriptionColumn(fullWidth) {
    return 3 * (fullWidth / 15)
}

export function buttonColumn(fullWidth) {
    return 4 * (fullWidth / 15)
}

export function largeColumn(fullWidth){
    return 5 * (fullWidth / 15)
}

export function wideColumn(fullWidth){
    return 6 * (fullWidth / 15)
}
