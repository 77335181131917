import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    main: {
        color: '#888',
        margin: 0,
        marginTop: 24,
        display: 'table',
        width: '100%',
        height: '100%',
        textAlign: 'center',

    },
    fof: {
        display: 'table-cell',
        verticalAlign: 'middle',
        '& h1': {
            fontSize: '50px',
            display: 'inline-block',
            padding: theme.spacing(1.5),
            animation: '$type .5s alternate infinite',
            [theme.breakpoints.down('md')]: {
                fontSize: '25px',
            },
        },
    },
    '@keyframes type': {
        from: {
            boxShadow: 'inset -3px 0px 0px #888',
        },
        to: {
            boxShadow: 'inset -3px 0px 0px transparent',
        },
    },
}));

function ErrorComponent({error}) {
    const classes = useStyles();
    return (
        <div className={clsx("pageContainer", classes.main)}>
            <div className={classes.fof}>
                <h1>{error}</h1>
            </div>
        </div>
    );
}

ErrorComponent.propTypes = {
    error: PropTypes.any.isRequired
};

export default ErrorComponent;
