export const getStyles = theme => {
    return {
        error: {
            backgroundColor: '#b92f2f',
            height: '10%',
            bottom: '20%',
            position: 'absolute',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        loading: {
            backgroundColor: '#049fe3',
            height: '10%',
            bottom: '20%',
            position: 'absolute',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '.short': {
                height: '20%'
            }
        },
        short: {
          height: '15%'
        },
        sign: {
            color: 'white'
        }
    }
}