import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useIntl} from "react-intl";

const useStyles = makeStyles(theme => ({
    emptyTable: {
        textAlign: "center"

    }
}));
function EmptyTableComponent () {
    const classes = useStyles();
    const intl = useIntl();

    return <div className={classes.emptyTable}><h1>{intl.formatMessage({id: 'no_data_to_show'})}</h1></div>
}

export default EmptyTableComponent