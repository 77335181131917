export const styles = theme => {
    return {
        appBar: {
            backgroundColor: 'black',
        },
        flex: {
            flex: 1,
        },
        toolbar: {
            backgroundColor: "white",
            color: 'black',
            padding: theme.spacing(1),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(2)
            },
        },
        imageHeader:{
            [theme.breakpoints.down('sm')]: {
                width:"50%"
            },
        },
        menuButton: {
            position: "absolute",
            right: 0,
            marginLeft: -12,
            marginRight: 20,
            cursor: 'pointer',
        },
        languageButton: {
            position: 'absolute',
            right: 60
        }
    }
};