export const styles = theme => {
    return {
        button: {
            color: "white !important",
            textTransform: "capitalize !important",
            [theme.breakpoints.only('md')]: {
                fontSize: "1.5rem"
            }
        },
        accordionSummary: {
            display: "flex",
        },
        fullRow: {
            width: "100%",
            display: "flex",
            [theme.breakpoints.only('md')]: {
                width: "70%",
                margin: "0 auto"
            }
        },
        accordionRow: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        institutionName: {
            [theme.breakpoints.only('md')]: {
                fontSize: "1.3rem",
            }
        },
        classroomName: {
            textAlign: "center",
            width: 200,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            [theme.breakpoints.only('md')]: {
                width: 500,
                fontSize: "2rem",
            }
        },
        iconCell: {
            width: 20,
            [theme.breakpoints.only('md')]: {
                width: 40,
                fontSize: "2rem",
            }
        },
        eventIcon: {
            width: 20,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            [theme.breakpoints.only('md')]: {
                width: 40,
                fontSize: "2rem",
            }
        },
        accordionInfo: {
            width: "100%",
            [theme.breakpoints.only('md')]: {
                width: "60%",
                margin: "0 auto"
            }
        },
        classInfo: {
            display: "flex",
            gap: "20px",
            marginBottom: "20px",
        },
        classTitle: {
            textAlign: "left",
            [theme.breakpoints.only('md')]: {
                fontSize: "1.5rem",
            }
        },
        classEvent: {
            textAlign: "left",
            [theme.breakpoints.only('md')]: {
                fontSize: "1.5rem",
            },
            [theme.breakpoints.down('sm')]: {
                width: 150,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
            }
        }
    }
}