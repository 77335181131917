export const styles = theme => {
    return {
        chatContainer: {
            display: "flex",
            alignContent: "center",
            justifyContent: "space-between",
            width: "100%",
            height:"100%",
        },
        formControl: {
            backgroundColor: "rgba(150, 150, 150, 0.1)",
            padding: "10px",
            width: "calc(75% - 10px)",
            border:"none"
        }
    }
}