export const styles = theme =>  {
    return{
    root: {
        height: 20,
        width: 20,
        marginLeft: 16
    },
    img: {
        height: '100%',
        width: '100%'
        }
}
}