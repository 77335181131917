import React, {useEffect, useState} from "react";
import {buildUrl, get} from "../../../../utils/axiosRequests";
import {TIIVII_API_ROOM_LOG} from "../../../../utils/constants";
import {makeStyles} from "@material-ui/core/styles";
import {useIntl} from "react-intl";
import {DataGrid} from "@material-ui/data-grid";
import {ALIGN_COLUMN_CENTER, ALIGN_COLUMN_LEFT, HISTORY_TABLA_PAGE_ITEMS} from "../../HistoryTableConstants";
import EmptyTableComponent from "../emptyTableComponent/EmptyTableComponent";
import {formatTime} from "../../../../utils/utils";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        display: 'flex',
        '& .MuiDataGrid-colCellTitle': {
            fontWeight: 'bold'
        },
        '& .MuiDataGrid-colCellWrapper ': {
            backgroundColor: '#fafafa'
        },
        '& .MuiDataGrid-colCell-draggable, .MuiDataGrid-cellCenter': {
            justifyContent: 'center'
        }
    },
}));

function LogModalContentComponent({eventId, columnWidth}) {
    const [logData, setLogData] = useState([]);
    const classes = useStyles();
    const intl = useIntl();
    const getRows = (rows) => {
        return rows && rows.map(log => {
            log.id = log.entry_timestamp + Math.random()
            return log
        });
    }
    const getData = () => {
        get(buildUrl(TIIVII_API_ROOM_LOG, {event_id: eventId}), {})
            .then(response => {
                setLogData(getRows(response.data))
            })
            .catch(error => console.log(error));

    }
    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {narrowColumn, regularColumn, descriptionColumn} = columnWidth

    const columns = [
        {
            field: 'entry_timestamp',
            label: 'entry_timestamp',
            headerName: intl.formatMessage({id: 'log_modal_entry_timestamp'}).toUpperCase(),
            align: ALIGN_COLUMN_LEFT, width: narrowColumn,
            renderCell: (params) => {
                return <span>{params.data.entry_timestamp ? formatTime(params.data.entry_timestamp) : "-"}</span>;
            }
        },
        {
            field: 'event_id',
            label: 'event_id',
            headerName: intl.formatMessage({id: 'log_modal_event_id'}).toUpperCase(),
            align: ALIGN_COLUMN_CENTER, width: narrowColumn,
        },
        {
            field: 'log_entry_type',
            label: 'log_entry_type',
            headerName: intl.formatMessage({id: 'log_modal_log_entry_type'}).toUpperCase(),
            align: ALIGN_COLUMN_CENTER, width: descriptionColumn
        },
        {
            field: 'room_id',
            label: 'room_id',
            headerName: intl.formatMessage({id: 'log_modal_room_id'}).toUpperCase(),
            align: ALIGN_COLUMN_CENTER, width: narrowColumn
        },
        {
            field: 'user_name',
            label: 'user_name',
            headerName: intl.formatMessage({id: 'log_modal_user_name'}).toUpperCase(),
            align: ALIGN_COLUMN_CENTER, width: regularColumn
        },
        {
            field: 'user_type', label: 'user_type',
            headerName: intl.formatMessage({id: 'log_modal_user_type'}).toUpperCase(),
            align: ALIGN_COLUMN_CENTER,
            width: regularColumn
        },
        {
            field: 'more_info', label: 'more_info',
            headerName: intl.formatMessage({id: 'log_modal_more_info'}).toUpperCase(),
            align: ALIGN_COLUMN_CENTER,
            width: descriptionColumn
        }
    ];


    return <div className={classes.root}>

        <div style={{height: 650, width: '100%', marginLeft: 'auto', marginRight: 'auto'}}>

            <h3>{intl.formatMessage({id: 'log_modal_title'}).toUpperCase()}</h3>
            {logData.length ?
                <DataGrid className={classes.root} rows={logData} columns={columns} pageSize={HISTORY_TABLA_PAGE_ITEMS}
                          classes={{colCell: {backgroundColor: "black"}}} hideFooterSelectedRowCount/> :
                <EmptyTableComponent/>
            }
        </div>
    </div>
}

LogModalContentComponent.propTypes = {
    eventId: PropTypes.number.isRequired
}

export default LogModalContentComponent;