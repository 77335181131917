import React, {lazy, Suspense} from 'react';
import {LANGUAGE_EN, LANGUAGE_ES, TIIVII_TENANT} from "../../../utils/constants";
import {makeStyles} from '@material-ui/core/styles';
import LoadingComponent from "../loadingComponent/LoadingComponent";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(8, 24),
        textAlign: 'left',
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(4)
        }
    }
}));


const PrivacyPolicyContainer = () => {


    const language = localStorage.getItem("locale") || navigator.language.split(/[-_]/)[0];
    const languageFile = language === LANGUAGE_ES ? 'PrivacyPolicyESComponent' : language === LANGUAGE_EN ? 'PrivacyPolicyENComponent' : 'PrivacyPolicyGLComponent';
    const Component = lazy(() => import("./components/" + TIIVII_TENANT + "/" + languageFile))
    const classes = useStyles();

    return (<div className={classes.container}>
        <Suspense fallback={<LoadingComponent description={false} open/>}>
            <Component/>
        </Suspense>
    </div>)
};

export default PrivacyPolicyContainer;
