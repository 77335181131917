import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import {withRouter, Link} from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Menu from "@material-ui/core/Menu";
import {compose} from "redux";
import logoHeader from '../../images/Logo_header.png'
import {styles} from "./AppBarStyles";
import glLanguage from '../../images/Icono_Idioma-GL.png'
import esLanguage from '../../images/Icono_Idioma-ES.png'
import enLanguage from '../../images/Icono_Idioma-EN.png'
import AppBarMenuItemComponent from "./components/appBarMenuItemComponent/AppBarMenuItemComponent";
import {makeStyles} from '@material-ui/core/styles'
import {useKeycloak} from "@react-keycloak/web";
import {Divider} from "@material-ui/core";
import FlagIcon from "../common/customIcons/flagIconcomponent/FlagIconComponent";
import {useIntl} from "react-intl";
import {LANGUAGES} from "./AppBarConstants";
import AuthorizedElement from "../common/auth/AuthorizedElement";
import {ROLES_NOT_STUDENT, ROUTE_PATH_PRIVACY_POLICY} from "../../utils/constants";
import ClassroomIconComponent from "../common/customIcons/classroomsIconComponent/ClassroomsIconComponent";
import LogoutIconComponent from "../common/customIcons/logoutIconComponent/LogoutIconComponent";
import LoginIconComponent from "../common/customIcons/loginIconComponent/LoginIconComponent";
import {ROUTE_HELP, ROUTE_INSTITUTIONS, ROUTE_LANDING, ROUTE_ROOMS} from "../../utils/routes";
import {postSendLogService} from "../../services/commonServices";
import HelpIconComponent from "../common/customIcons/helpIconComponent/HelpComponent";
import InstitutionsIconComponent from "../common/customIcons/institutionsIconComponent/InstitutionsIconComponent";

const useStyles = makeStyles(theme => styles(theme));

function AppBarComponent({handleLanguageChanged}) {

    const {keycloak, initialized} = useKeycloak();
    const [anchorMainEl, setAnchorMainEl] = useState(null);
    const [anchorLanguageEl, setAnchorLanguageEl] = useState(null);

    useEffect(() => {
        if (keycloak.authenticated) {
            postSendLogService({log_entry_type: 'LOGIN'}).then(() => console.debug("LOGIN HAS BEEN SENT TO LOG...."))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialized])

    const intl = useIntl();
    const classes = useStyles();

    const handleMainMenu = event => {
        setAnchorMainEl(event.currentTarget);
    };
    const handleLanguagelMenu = event => {
        setAnchorLanguageEl(event.currentTarget);
    };
    const handleMainClose = () => {
        setAnchorMainEl(null);
    };
    const handleLanguageClose = () => {
        setAnchorLanguageEl(null);
    };

    const language = localStorage.getItem("locale") || navigator.language.split(/[-_]/)[0];

    const path = window.location.pathname.split('/')[3];

    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <Link to={ROUTE_LANDING}>
                        <img alt='' src={logoHeader} className={classes.imageHeader} border={0}/>
                    </Link>
                    {path !== ROUTE_PATH_PRIVACY_POLICY && <IconButton
                        color="inherit"
                        aria-label="Language"
                        className={classes.languageButton}
                        onClick={handleLanguagelMenu}>
                        {language === LANGUAGES.GL ? <img alt={intl.formatMessage({id: 'intGl'})}
                                                          src={glLanguage}/> :
                            language === LANGUAGES.ES ? <img alt={intl.formatMessage({id: 'intEs'})}
                                                             src={esLanguage}/> : <img
                                alt={intl.formatMessage({id: 'intEn'})} src={enLanguage}/>
                        }
                    </IconButton>}
                    {path !== ROUTE_PATH_PRIVACY_POLICY && <IconButton
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="Menu"
                        onClick={handleMainMenu}>
                        <MenuIcon/>
                    </IconButton>}
                </Toolbar>
            </AppBar>
            <div>
                <LanguageMenu handleLanguageChanged={handleLanguageChanged} anchor={anchorLanguageEl}
                              handleClose={handleLanguageClose}/>
                <MainMenu anchor={anchorMainEl} handleClose={handleMainClose}/>
            </div>
        </div>
    );
}


const MainMenu = ({anchor, handleClose}) => {
    const {keycloak} = useKeycloak();
    const closeSession = (message) => {
        keycloak.logout(message)
        handleClose();
    }
    const handleLogout = () => {
        postSendLogService({log_entry_type: 'LOGOUT'}).then(() => {
            closeSession("LOGOUT IS SEND TO LOG....")
        }).catch(error => {
            closeSession(error)
        });
    }
    const handleLogin = () => {
        keycloak.login()
        handleClose();
    }

    return (
        <>
            <Menu
                id="simple-menu"
                anchorEl={anchor}
                keepMounted
                open={Boolean(anchor)}
                onClose={handleClose}
            >
                {keycloak.authenticated ?
                    <UserMenu handleClose={handleClose} handleLogout={handleLogout}/>
                    :
                    <AnonymousMenu handleClose={handleClose} handleLogin={handleLogin}/>
                }
            </Menu>
        </>
    );
}

MainMenu.propTypes = {
    handleClose: PropTypes.func.isRequired,
    anchor: PropTypes.any,
};


const LanguageMenu = ({anchor, handleClose, handleLanguageChanged}) => {
    const intl = useIntl();

    const changeLanguage = (event, language) => {
        handleClose();
        localStorage.setItem("locale", language);
        handleLanguageChanged(language);
    }

    return (
        <Menu
            id="simple-menu"
            anchorEl={anchor}
            keepMounted
            open={Boolean(anchor)}
            onClose={handleClose}
        >
            <div> {/* Included as anchor for the menu */}
                <AppBarMenuItemComponent text={intl.formatMessage({id: 'intGl'})}
                                         icon={<FlagIcon flag="gl"/>}
                                         onClick={event => changeLanguage(event, "gl")}
                                         language={"gl"}
                                         link={"#"}
                />
            </div>
            <AppBarMenuItemComponent text={intl.formatMessage({id: 'intEs'})}
                                     icon={<FlagIcon flag="es"/>}
                                     onClick={event => changeLanguage(event, "es")}
                                     language={"es"}
                                     link={"#"}
            />
            <AppBarMenuItemComponent text={intl.formatMessage({id: 'intEn'})}
                                     icon={<FlagIcon flag="en"/>}
                                     onClick={event => changeLanguage(event, "en")}
                                     language={"en"}
                                     link={"#"}
            />
        </Menu>
    );
}

LanguageMenu.propTypes = {
    handleClose: PropTypes.func.isRequired,
    anchor: PropTypes.any,
};

const AnonymousMenu = React.forwardRef(({handleLogin}, ref) => {
    const intl = useIntl();
    return (
        <div ref={ref}>
            <AppBarMenuItemComponent onClick={handleLogin}
                                     text={intl.formatMessage({id: 'login'})}
                                     icon={<LoginIconComponent/>} link={"#"}/>
        </div>
    )
});

AnonymousMenu.propTypes = {
    handleLogin: PropTypes.func.isRequired,
    ref: PropTypes.any
};

const UserMenu = React.forwardRef(({handleClose, handleLogout}, ref) => {
    const intl = useIntl();
    return (
        <div ref={ref}>
            <AuthorizedElement roles={ROLES_NOT_STUDENT}>
                <AppBarMenuItemComponent onClick={handleClose}
                                         text={intl.formatMessage({id: 'institutions'})}
                                         icon={<InstitutionsIconComponent/>} link={ROUTE_INSTITUTIONS}/>
            </AuthorizedElement>
            <AppBarMenuItemComponent onClick={handleClose}
                                     text={intl.formatMessage({id: 'rooms'})}
                                     icon={<ClassroomIconComponent/>}
                                     link={ROUTE_ROOMS}/>
            <Divider/>
            <AppBarMenuItemComponent onClick={handleClose}
                                     text={intl.formatMessage({id: 'help'})}
                                     icon={<HelpIconComponent/>}
                                     link={ROUTE_HELP}/>
            <AppBarMenuItemComponent onClick={handleLogout}
                                     text={intl.formatMessage({id: 'logout'})}
                                     icon={<LogoutIconComponent/>}
                                     link="#"/>
        </div>
    )
});

UserMenu.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleLogout: PropTypes.func.isRequired,
};

AppBarComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default compose(
    withStyles(styles),
    withRouter,
)(AppBarComponent);
