import React, {useEffect, useState} from "react";
import {buildUrl, get} from "../../../../utils/axiosRequests";
import {TIIVII_API_ROOM_EVENT} from "../../../../utils/constants";
import {useIntl} from "react-intl";
import {DataGrid} from "@material-ui/data-grid";
import {ALIGN_COLUMN_CENTER, HISTORY_TABLA_PAGE_ITEMS} from "../../HistoryTableConstants";
import {makeStyles} from "@material-ui/core/styles";
import EmptyTableComponent from "../emptyTableComponent/EmptyTableComponent";
import {formatTime} from "../../../../utils/utils";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiDataGrid-colCellTitle': {
            fontWeight: 'bold'
        },
        '& .MuiDataGrid-colCellWrapper ': {
            backgroundColor: '#fafafa'
        },
        '& .MuiDataGrid-colCell-draggable, .MuiDataGrid-cellCenter': {
            justifyContent: 'center'
        }
    },
}));

function UsersModalContentComponent({roomId, eventId, columnWidth}) {
    const [usersData, setUsersData] = useState([]);
    const intl = useIntl();
    const classes = useStyles();


    const getRows = (rows) => {
        return rows && rows.map(log => {
            log.id = log.first_seen
            return log
        });
    }

    const getUsersData = () => {
        get(buildUrl(TIIVII_API_ROOM_EVENT, {room_id: roomId, event_id: eventId}), {})
            .then(response => setUsersData(getRows(response.data.users)))
            .catch(error => console.log(error));
    }
    useEffect(() => {
        getUsersData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const {buttonColumn, regularColumn, descriptionColumn} = columnWidth

    const columns = [
        {
            field: 'first_seen',
            label: 'first_seen',
            headerName: intl.formatMessage({id: 'users-modal-first-seen'}).toUpperCase(),
            align: ALIGN_COLUMN_CENTER, width: buttonColumn,
            renderCell: (params) => {
                return <span>{params.data.first_seen ? formatTime(params.data.first_seen) : "-"} / {params.data.last_active ?
                    formatTime(params.data.last_active) : intl.formatMessage({id: 'users-modal-in_progress'})}</span>;
            }
        },
        {
            field: 'connected_secs',
            label: 'connected_secs',
            headerName: intl.formatMessage({id: 'users-modal-connected-min'}).toUpperCase(),
            align: ALIGN_COLUMN_CENTER, width: buttonColumn,
            renderCell: params => {
                const minutes = Math.floor((params.data.connected_secs / 60) + 1);
                return <span>{minutes} min.</span>;
            }
        },
        {
            field: 'user_name',
            label: 'user_name',
            headerName: intl.formatMessage({id: 'users-modal-username'}).toUpperCase(),
            align: ALIGN_COLUMN_CENTER, width: descriptionColumn,
        },
        {
            field: 'user_type',
            label: 'user_type',
            headerName: intl.formatMessage({id: 'users-modal-rol'}).toUpperCase(),
            align: ALIGN_COLUMN_CENTER, width: regularColumn
        }
    ];


    return (
        <div style={{height: 650, width: '100%', marginLeft: 'auto', marginRight: 'auto'}}>

            <h3>{intl.formatMessage({id: 'users-modal-last_title'}).toUpperCase()}</h3>
            {usersData.length ? <DataGrid className={classes.root} rows={usersData} columns={columns}
                                          pageSize={HISTORY_TABLA_PAGE_ITEMS} hideFooterSelectedRowCount/> :
                <EmptyTableComponent/>}
        </div>
    )
}

UsersModalContentComponent.propTypes = {
    roomId: PropTypes.string.isRequired,
    eventId: PropTypes.number.isRequired
}

export default UsersModalContentComponent;