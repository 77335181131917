export const getStyles = theme => {
    return {
        title: {
            marginBottom: theme.spacing(1)
        },
        controlBox: {
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            height: "calc(100% - 40px)",
            padding: theme.spacing(3, 2, 1, 2)
        },
        iconsContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flex: 1,
            marginBottom: theme.spacing(2)
        },
        tooltip: {
            maxWidth: 120
        },
        listElement: {
            marginBottom: theme.spacing(1),
            width: 300,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            [theme.breakpoints.down('sm')]: {
                width: "100%",
                overflow: 'none',
            }
        },
    }
}
