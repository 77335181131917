export const styles = theme => {
    return {
        buttonsGroupMobile: {
            position: "fixed",
            zIndex: 10,
            display: "flex",
            justifyContent: "space-around",
            backgroundColor: "#fff",
            bottom: 0,
            width: "100%",
            borderRadius: 0,
            height:100,
        },
        buttonMobile: {
            border:0,
        },
        buttonLabelMobile: {
            display: "flex",
            flexDirection: "column",
            textTransform: "capitalize",
        },
        buttonSelected : {
            backgroundColor: 'white !important',
            color: 'black !important',
        }
    }
}