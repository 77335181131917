import {
    CHANGE_LANGUAGE,
    SET_CLASSROOM_ACTIVE_USERS_ACTION,
    TURN_OFF_LOADING_ACTION,
    TURN_ON_LOADING_ACTION,
    SET_LOGGED_USER_ACTION
} from "../actions/actionTypes";
import {compareUserArrays} from "../utils/utils";

const INITIAL_STATE = {
    activeUsers: null,
    prevActiveUsers: null,
    language: navigator.language.split(/[-_]/)[0],
    loading: false,
    loggedUser: null
};

function commonState(state = INITIAL_STATE, action) {
    switch (action.type) {
        case CHANGE_LANGUAGE: {
            return {...state, language: action.data}
        }
        case TURN_ON_LOADING_ACTION: {
            return {...state, loading: true}
        }
        case TURN_OFF_LOADING_ACTION: {
            return {...state, loading: false}
        }
        case SET_CLASSROOM_ACTIVE_USERS_ACTION: {
            if (!compareUserArrays(action.data, state.activeUsers)) {
                return {...state, prevActiveUsers: state.activeUsers, activeUsers: action.data}
            } else {
                return {...state, activeUsers: action.data}
            }
        }
        case SET_LOGGED_USER_ACTION: {
            return {...state, loggedUser: action.data}
        }
        default:
            return state;
    }
}

export default commonState;