import {USER_STATUS_PENDING, USER_STATUS_REJECTED} from "./constants";

export const no_op = () => undefined;

export const isFunction = obj => !!(obj && obj.constructor && obj.call && obj.apply);

export const isString = string => typeof (string) === 'string' || string instanceof String;

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const formatTime = timeline => {
    const date = new Date(timeline * 1000);
    const hours = date.getHours() >= 10 ? date.getHours() : "0" + date.getHours();
    const minutes = date.getMinutes() >= 10 ? date.getMinutes() : "0" + date.getMinutes();
    const seconds = date.getSeconds() >= 10 ? date.getSeconds() : "0" + date.getSeconds();
    return hours + ':' + minutes + ':' + seconds
}

export const formatDate = timestamp => {
    const date = new Date(timestamp);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1) >= 10 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
    const day = date.getDate();

    return day + "/" + month + "/" + year
}

export const formatFullDate = timestamp => {
    const date = new Date(timestamp);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1) >= 10 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
    const day = date.getDate();
    const time = formatTime(date);
    return time + " - " + day + "/" + month + "/" + year
}

export const setIconColor = (info) => {
    if (!info) {
        return "disabled"
    }
    return info.status_ok ? "primary" : "secondary"
}

export const setIconTooltip = (name, lastCheck, info, intl) => {
    if (info.status_ok === true) {
        if (info.last_ko) {
            return intl.formatMessage({id: name}) + ' - ' + intl.formatMessage({id: "class_icon_last_test"})
                + ': ' + formatFullDate(lastCheck) + ' - ' + intl.formatMessage({id: "class_icon_ok_since"}) + ': ' + formatFullDate(info.last_ok) + ' - ' +
                intl.formatMessage({id: "class_icon_ko_since"}) + ': ' + formatFullDate(info.last_ko)
        } else {
            return intl.formatMessage({id: name}) + ' - ' + intl.formatMessage({id: "class_icon_last_test"})
                + ': ' + formatFullDate(lastCheck) + ' - ' + intl.formatMessage({id: "class_icon_ok_since"}) + ': ' + formatFullDate(info.last_ok)
        }
    } else {
        if (info.last_ok) {
            return intl.formatMessage({id: name}) + ' - ' + intl.formatMessage({id: "class_icon_last_test"})
                + ': ' + formatFullDate(lastCheck) + intl.formatMessage({id: "class_icon_ok_since"}) + ' - '
                + formatFullDate(info.last_ok) + intl.formatMessage({id: "class_icon_ko_since"})
                + ': ' + formatFullDate(info.last_ko)
        } else {
            return intl.formatMessage({id: name}) + ' - ' + intl.formatMessage({id: "class_icon_last_test"})
                + ': ' + formatFullDate(lastCheck) + ' - ' + intl.formatMessage({id: "class_icon_ko_since"})
                + ': ' + formatFullDate(info.last_ko)
        }
    }
}

export const setNotAvailableTooltip = (id, intl) => {
    return intl.formatMessage({id}) + ' - ' + intl.formatMessage({id: "class_icon_not_available"})
}

export const autoplay = (ref, handlePlay, handleWarning, play) => {
    ref && ref.current && ref.current.play().catch(() => {
            if (ref.current && play) {
                handlePlay(false);
                handleWarning(play);
            }
        }
    )
};

export const compareUserArrays = (array1, array2) => {
    let changedUser;
    let newUser;

    if (array2) {
        array1.forEach(item1 => {
            changedUser = !!array2.find(item2 => item2.id === item1.id && item2.status === USER_STATUS_REJECTED && item1.status === USER_STATUS_PENDING);
            newUser = !(!!array2.find(item2 => item2.id === item1.id)) && item1.status === USER_STATUS_PENDING
        })
    }
    return !!changedUser || !!newUser
};