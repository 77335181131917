import React, {useCallback, useEffect, useRef, useState} from "react";
import {getStyles} from "./ControlStudentListStyles";
import {compose} from "redux";
import {Typography, withStyles} from "@material-ui/core";
import {FormattedMessage, useIntl} from "react-intl";
import {PropTypes} from "prop-types";
import {connect} from "react-redux";
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from "@material-ui/core/IconButton";
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import {putAcceptUserService, putRejectUserService} from "../../../../../../services/commonServices";
import SchoolRoundedIcon from '@material-ui/icons/SchoolRounded';
import MenuBookRoundedIcon from '@material-ui/icons/MenuBookRounded';
import SupervisorAccountRoundedIcon from '@material-ui/icons/SupervisorAccountRounded';
import {
    ROLE_ADMIN, ROLE_MANAGER,
    ROLE_STUDENT,
    ROLE_TEACHER,
    ROLES_NOT_STUDENT,
    USER_STATUS_APPROVED,
    USER_STATUS_PENDING, USER_STATUS_PENDING_NOTIFICATION, USER_STATUS_PENDING_TIMEOUT, USER_STATUS_REJECTED
} from "../../../../../../utils/constants";
import Tooltip from "@material-ui/core/Tooltip";
import AuthorizedElement from "../../../../../common/auth/AuthorizedElement";
import {showMessage} from "../../../../../common/NotificationSnack";
import audio from '../../../../../../audio/pending-user-notification.wav'
import AlertDialog from "../../../../../common/alertDialog/AlertDialog";
import Divider from "@material-ui/core/Divider";
import {compareUserArrays} from "../../../../../../utils/utils";

const styles = theme => (getStyles(theme));


function ControlStudentListComponent(props) {

    const intl = useIntl();
    const {activeUsers, classes, roomInfo, handleInterval, setActiveUsers, loggedUser, prevActiveUsers} = props;
    const {roomId} = roomInfo;
    const audioRef = useRef();

    const [selectedUsers, setSelectedUsers] = useState([]);
    const [pendingUsers, setPendingUsers] = useState([]);
    const [orderedUsers, setOrderedUsers] = useState(activeUsers);
    const [pendingAlert, setPendingAlert] = useState(false);

    const handlePendingAlert = useCallback(() => {
        setPendingAlert(pendingAlert => !pendingAlert)
    }, [])

    const displayPendingUsersComponent = () => {
        return <div>
            {pendingUsers && pendingUsers.map(user => <Typography key={user.id}
                                                                  variant={"body1"}>{user.user_name}</Typography>)}
        </div>
    }

    useEffect(() => {
        let notificationInterval;
        if (pendingAlert && loggedUser && loggedUser.user_type !== ROLE_STUDENT) {
            playNotification();
            notificationInterval = setInterval(playNotification, USER_STATUS_PENDING_NOTIFICATION)
        }
        return () => clearInterval(notificationInterval)
    }, [pendingAlert, activeUsers, loggedUser]);

    useEffect(() => {
        if (activeUsers && loggedUser) {
            setPendingUsers(activeUsers.filter(user => user.status === USER_STATUS_PENDING && loggedUser && user.user_name !== loggedUser.user_name));
        }
    }, [activeUsers, loggedUser])

    useEffect(() => {
        let timeout;
        if (pendingUsers.length > 0 && !pendingAlert) {
            timeout = setTimeout(handlePendingAlert, USER_STATUS_PENDING_TIMEOUT)
        }
        return () => {
            clearTimeout(timeout);
        }
    }, [pendingUsers, handlePendingAlert, pendingAlert])


    const orderActiveUsersArray = useCallback(() => {
        const pending = activeUsers.filter(user => user.status === USER_STATUS_PENDING)
        const approved = activeUsers.filter(user => user.status === USER_STATUS_APPROVED)
        const rejected = activeUsers.filter(user => user.status === USER_STATUS_REJECTED)
        setOrderedUsers(pending.concat(approved.concat(rejected)));
    }, [activeUsers]);

    useEffect(() => {
        if (activeUsers) {
            orderActiveUsersArray();
        }
        if (prevActiveUsers && activeUsers && compareUserArrays(activeUsers, prevActiveUsers) && loggedUser && loggedUser.user_type !== ROLE_STUDENT) {
            playNotification();
        }
    }, [prevActiveUsers, activeUsers, orderActiveUsersArray, loggedUser])


    useEffect(() => {
        if (pendingUsers.length > 0) {
            handleInterval(true)
        }
        return () => handleInterval(false);
    }, [pendingUsers.length, handleInterval])

    const handleActionAllUsers = (value, users) => {
        const usersArray = users.map(user => user.id.toString()).filter(user => user !== loggedUser.id.toString());
        const body = {users: usersArray}
        value ?
            putAcceptUserService(roomId, body)
                .then(response => {
                    setSelectedUsers([])
                    setActiveUsers(response.data.active_users)
                })
                .catch(error => showMessage(error)) :
            putRejectUserService(roomId, body)
                .then(response => {
                    setSelectedUsers([])
                    setActiveUsers(response.data.active_users)
                })
                .catch(error => showMessage(error));
    }

    const handleAcceptUser = user => {
        const users = {users: [user.id.toString()]}
        putAcceptUserService(roomId, users).then(response => {
            unselectUser(user)
            setActiveUsers(response.data.active_users)
        }).catch(error => showMessage(error));
    }

    const handleRejectUser = user => {
        const users = {users: [user.id.toString()]}
        putRejectUserService(roomId, users).then(response => {
            unselectUser(user)
            setActiveUsers(response.data.active_users)
        }).catch(error => showMessage(error));
    }

    const isUserSelected = user => {
        return !!selectedUsers.find(element => element.id === user.id)
    }

    const unselectUser = user => {
        const userArray = selectedUsers.filter(element => element.id !== user.id)
        setSelectedUsers([...userArray])
    }

    const selectUser = user => {
        const userArray = [...selectedUsers]
        userArray.push(user)
        setSelectedUsers([...userArray])
    }

    const handleSelectedUser = user => {
        isUserSelected(user) ? unselectUser(user) : selectUser(user)
    };

    const allUsersSelected = () => {
        return !!activeUsers && activeUsers.length === selectedUsers.length
    };

    const handleAllUsers = () => {
        allUsersSelected() ? setSelectedUsers([]) : setSelectedUsers(activeUsers)
    };

    const playNotification = () => {
        if (audioRef.current) {
            audioRef.current.play();
        }
    }

    const allUsersApproved = () => {
        const users = activeUsers.filter(user => user !== loggedUser.id.toString())
        return users.filter(user => user.status === USER_STATUS_APPROVED).length === users.length
    }

    const allUsersRejected = () => {
        const users = activeUsers.filter(user => user !== loggedUser.id.toString())
        return users.filter(user => user.status === USER_STATUS_REJECTED).length === users.length
    }

    const pendingComponent = displayPendingUsersComponent();


    if (orderedUsers) {
        return (
            <div
                className={loggedUser.user_type === ROLE_STUDENT ? classes.simpleList : classes.list}>
                {orderedUsers.length > 0 && <AuthorizedElement roles={ROLES_NOT_STUDENT}>
                    <div className={classes.header}>
                        <Checkbox
                            onChange={() => handleAllUsers()}
                            checked={!!allUsersSelected()}
                            color={"primary"}
                            inputProps={{'aria-label': 'primary checkbox'}}
                            disabled={orderedUsers.length === 1 && orderedUsers[0].user_name === loggedUser.user_name}/>
                        {pendingUsers.length > 0 && <div className={classes.usersButton}>
                            <Typography variant={"subtitle2"}>{pendingUsers.length}</Typography>
                        </div>}
                        <div className={classes.headerButtons}>
                            <IconButton onClick={() => handleActionAllUsers(true, selectedUsers)}
                                        disabled={!selectedUsers.length || allUsersApproved()}
                                        className={classes.accept}>
                                <DoneRoundedIcon/>
                            </IconButton>
                            <IconButton onClick={() => handleActionAllUsers(false, selectedUsers)}
                                        disabled={!selectedUsers.length || allUsersRejected()}
                                        className={classes.rejected}>
                                <CloseRoundedIcon/>
                            </IconButton>
                        </div>
                    </div>
                    <Divider/>
                </AuthorizedElement>}
                <div>
                    {orderedUsers && orderedUsers.map(
                        user => <div className={classes.userRow} key={user.user_name}>
                            <AuthorizedElement roles={ROLES_NOT_STUDENT}>
                                <Checkbox
                                    onChange={() => handleSelectedUser(user)}
                                    checked={!!isUserSelected(user) && user.user_name !== loggedUser.user_name}
                                    color={"primary"}
                                    inputProps={{'aria-label': 'primary checkbox'}}
                                    disabled={user.user_name === loggedUser.user_name}/>
                            </AuthorizedElement>
                            <div className={classes.user}>
                                {user.user_type === ROLE_STUDENT &&
                                <SchoolRoundedIcon className={`${classes.icon} ${user.status === USER_STATUS_PENDING ?
                                    classes.pending :
                                    (user.status === USER_STATUS_REJECTED ? classes.rejected : classes.approved)}`}/>}
                                {user.user_type === ROLE_TEACHER &&
                                <MenuBookRoundedIcon className={`${classes.icon} ${user.status === USER_STATUS_PENDING ?
                                    classes.pending :
                                    (user.status === USER_STATUS_REJECTED ? classes.rejected : classes.approved)}`}/>}
                                {(user.user_type === ROLE_ADMIN || user.user_type === ROLE_MANAGER) &&
                                <SupervisorAccountRoundedIcon
                                    className={`${classes.icon} ${user.status === USER_STATUS_PENDING ?
                                        classes.pending :
                                        (user.status === USER_STATUS_REJECTED ? classes.rejected : classes.approved)}`}/>}
                                <Tooltip
                                    title={user.full_user_name + ' - ' + intl.formatMessage({id: 'users-modal-connected-min'}) + ': ' + Math.round(user.connected_secs / 60) + ' ' + intl.formatMessage({id: "minutes"})}>
                                    <Typography key={user.user_name}
                                                className={`${user.status === USER_STATUS_PENDING ?
                                                    classes.pending :
                                                    (user.status === USER_STATUS_REJECTED ? classes.rejected : classes.approved)} 
                                                    ${loggedUser.user_type === ROLE_STUDENT ? classes.ellipsisWide : classes.ellipsis}`}>
                                        {user.full_user_name}
                                    </Typography>
                                </Tooltip>
                            </div>
                            <AuthorizedElement roles={ROLES_NOT_STUDENT}>
                                <div className={classes.headerButtons}>
                                    {(user.status === USER_STATUS_PENDING || user.status === USER_STATUS_REJECTED) &&
                                    <IconButton onClick={() => handleAcceptUser(user)}
                                                disabled={user.user_name === loggedUser.user_name}
                                                className={classes.accept}>
                                        <DoneRoundedIcon/>
                                    </IconButton>}
                                    {(user.status === USER_STATUS_PENDING || user.status === USER_STATUS_APPROVED) &&
                                    <IconButton onClick={() => handleRejectUser(user)}
                                                disabled={user.user_name === loggedUser.user_name}
                                                className={classes.rejected}>
                                        <CloseRoundedIcon/>
                                    </IconButton>}
                                </div>
                            </AuthorizedElement>
                        </div>)}
                </div>
                <audio src={audio} ref={audioRef}/>
                {pendingUsers.length > 0 && <AlertDialog buttonCloseText={<FormattedMessage id="global_buttons_close"/>}
                                                         buttonAcceptText={<FormattedMessage
                                                             id="pending_users_accept"/>}
                                                         description={pendingComponent}
                                                         onClose={handlePendingAlert}
                                                         onAccept={() => handleActionAllUsers(true, pendingUsers)}
                                                         open={pendingAlert}
                                                         title={<FormattedMessage id="pending_users_title"/>}/>}
            </div>
        )
    } else {
        return <></>
    }
}

ControlStudentListComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    classInfo: PropTypes.object,

}
const mapStateToProps = ({commonState}) => ({
    activeUsers: commonState.activeUsers,
    prevActiveUsers: commonState.prevActiveUsers
});

export default compose(
    withStyles(styles),
    connect(mapStateToProps)
)(ControlStudentListComponent);
