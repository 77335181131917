import React, {useEffect, useRef, useState} from "react"
import moment from "moment";
import {makeStyles} from "@material-ui/core/styles";

import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";

import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

import {buildUrl, get} from "../../../../../../utils/axiosRequests";
import {
    ROOM_IMAGE_RELOAD_TIMEOUT,
    TIIVII_API_GET_CAPTURE, TIIVII_API_GET_CAPTURES
} from "../../../../../../utils/constants";
import {NUMBER_OF_CAROUSEL_IMAGES, NUMBER_OF_CAROUSEL_IMAGES_INTERVAL} from "./ScreenshotCarouselConstants";
import {showMessage} from "../../../../../common/NotificationSnack";
import defaultSnapshot from '../../../../../../images/blackboard_default_snapshot.png';

import {getStyles} from "./ScreenshotCarouselStyles";
import {Typography} from "@material-ui/core";
import {PropTypes} from "prop-types";

const useStyles = makeStyles(theme => getStyles(theme));

const DEFAULT_EXTENSION = 'image/png';

const ScreenshotCarouselComponent = React.forwardRef((props, ref) => {

    const {roomId, keepAlive} = props;
    const [image, setImage] = useState(defaultSnapshot);
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const [captures, setCaptures] = useState([]);
    const [modalCaptures, setModalCaptures] = useState([...captures]);
    const newArray = [...captures];

    const [currentIndex, setCurrentIndex] = useState(0);
    const [modalCurrentIndex, setModalCurrentIndex] = useState(0);

    const intervalReloadImageRef = useRef(null);


    useEffect(() => {
        if (keepAlive) {
            createIntervalReloadImage();
        } else {
            clearIntervalReloadImage();
        }
        return () => clearIntervalReloadImage();
        // eslint-disable-next-line
    }, [keepAlive]);

    const createIntervalReloadImage = () => {
        intervalReloadImageRef.current = setInterval(() => {
            getUrlsObject(NUMBER_OF_CAROUSEL_IMAGES_INTERVAL)
        }, ROOM_IMAGE_RELOAD_TIMEOUT);
    }

    const clearIntervalReloadImage = () => {
        clearInterval(intervalReloadImageRef.current);
    }

    useEffect(() => {
        getUrlsObject(NUMBER_OF_CAROUSEL_IMAGES);
        // eslint-disable-next-line
    }, [roomId]);


    const handleModal = () => {
        setOpen(!open)
        if (open) {
            setModalCurrentIndex(currentIndex)
        } else {
            setModalCaptures([...captures])
        }
    }


    // base64 take the header
    const header = !!image ? image.split(",") : [''];
    // base64 remove blanks and ;base64.
    const mimeTypeAsArray = header[0].match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/);
    const mimeType = !!mimeTypeAsArray && header[1] ? mimeTypeAsArray[0] : DEFAULT_EXTENSION;
    const extension = mimeType.split("/")[1];
    const isImage = !!header[1];

    const filterArray = (array) => {
        const newArray = [...array]
        return newArray.filter(element => element.data)
    }

    const getFilename = () =>
        `blackboard_capture_${roomId}_${moment().format('YYYYMMDD-HHmmss')}.${extension}`;

    const getUrlsObject = (number) => {
        get(`${buildUrl(TIIVII_API_GET_CAPTURES, {room_id: roomId})}?num_captures=${number}`, {}, false, false)
            .then(response => {
                const urlPromises = [];
                response.data.forEach(img => {
                    if (!newArray.find(item => item.id === img.id)) {
                        if (newArray.length === 16) {
                            newArray.pop();
                        }
                        newArray.push(img);
                        newArray.sort(function (x, y) {
                            return y.ts - x.ts;
                        })
                    }
                    if (!newArray.find(item => item.id === img.id).data) {
                        urlPromises.push(getUrl(img, newArray));
                    }
                })

                if (urlPromises.length) {
                    Promise.all(urlPromises).then(() => {
                        setCaptures(filterArray(newArray));
                        if (newArray.length) {
                            setImage(newArray[0].data)
                            setCurrentIndex(0)
                        }
                    }).catch(error => {
                        showMessage(error)
                    });
                }
            }).catch(error => console.log(error))
    }


    const getUrl = (img, newArray) => {
        const urlCapture = buildUrl(TIIVII_API_GET_CAPTURE, {room_id: roomId, url_hashed: img.url_hash});
        return new Promise(function (resolve, reject) {
            get(urlCapture, {responseType: "blob"}, false, false)
                .then(response => {
                    const reader = new window.FileReader();
                    reader.readAsDataURL(response.data);
                    reader.onload = () => {
                        newArray.find(element => element.id === img.id).data = reader.result
                        resolve();
                    }
                })
                .catch(() => {
                    resolve();
                })
        })
    }

    return (
        <div className={classes.screenshotBox} ref={ref}>
            <div className={classes.imageContainer}>
                <IconButton className={classes.screenButton} onClick={() => handleModal()} disabled={!captures.length}>
                    <OpenInNewRoundedIcon fontSize={"small"}/>
                </IconButton>
                <IconButton className={classes.downloadButton} href={isImage ? image : defaultSnapshot}
                            download={getFilename()} disabled={!captures.length}>
                    <GetAppRoundedIcon fontSize={"small"}/>
                </IconButton>
                <img alt={'Blackboard snapshot'}
                     src={captures.length && captures[currentIndex] && captures[currentIndex].data ? captures[currentIndex].data : defaultSnapshot}
                     onError={e => {
                         e.target.src = defaultSnapshot
                     }} className={classes.screenshot}/>
            </div>
            <div className={classes.controlsContainer}>
                <IconButton onClick={() => {
                    setCurrentIndex(currentIndex - 1)
                    setImage(captures[currentIndex - 1].data)
                    setModalCurrentIndex(currentIndex - 1)
                }} disabled={currentIndex === 0 || !captures.length}>
                    <ArrowBackIosRoundedIcon/>
                </IconButton>
                <Typography>{captures.length && captures.filter(element => element.data).length ?
                    currentIndex + 1 : 0} / {captures.length ? captures.filter(element => element.data).length : 0}</Typography>
                <IconButton onClick={() => {
                    setCurrentIndex(currentIndex + 1)
                    setImage(captures[currentIndex + 1].data)
                    setModalCurrentIndex(currentIndex + 1)
                }} disabled={!captures.length || currentIndex === captures.length - 1}>
                    <ArrowForwardIosRoundedIcon/>
                </IconButton>
            </div>
            <Modal open={open} onClose={handleModal} className={classes.modal}>
                <div>
                    <div className={classes.modalContainer}>
                        <IconButton className={classes.closeButton} onClick={() => handleModal()}>
                            <CloseRoundedIcon/>
                        </IconButton>
                        <IconButton className={classes.downloadButton}
                                    href={isImage && modalCaptures[modalCurrentIndex] ?
                                        modalCaptures[modalCurrentIndex].data : defaultSnapshot}
                                    download={getFilename()}>
                            <GetAppRoundedIcon/>
                        </IconButton>
                        <img alt={'Blackboard snapshot'}
                             src={modalCaptures.length && modalCaptures[modalCurrentIndex] ? modalCaptures[modalCurrentIndex].data : defaultSnapshot}
                             onError={e => e.target.src = defaultSnapshot}
                             className={classes.screenshotFull}/>
                    </div>
                    <div className={classes.controlsContainer}>
                        <IconButton onClick={() => {
                            setModalCurrentIndex(modalCurrentIndex - 1)
                        }} disabled={modalCurrentIndex === 0 || !modalCaptures.length}>
                            <ArrowBackIosRoundedIcon/>
                        </IconButton>
                        <Typography>{modalCaptures.length ? modalCurrentIndex + 1 : 0} / {modalCaptures.length ?
                            modalCaptures.length : 0}</Typography>
                        <IconButton onClick={() => {
                            setModalCurrentIndex(modalCurrentIndex + 1)
                        }} disabled={!modalCaptures.length || modalCurrentIndex === modalCaptures.length - 1}>
                            <ArrowForwardIosRoundedIcon/>
                        </IconButton>
                    </div>
                </div>
            </Modal>
        </div>
    );
});

ScreenshotCarouselComponent.propTypes = {
    roomId: PropTypes.string.isRequired,
    keepAlive: PropTypes.bool.isRequired
}
export default ScreenshotCarouselComponent;