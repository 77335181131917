import React, {useState} from "react";
import {buildUrl, post} from "../../../../../../utils/axiosRequests";
import {getStyles} from "./RoomInfoStyles";
import {IconButton, Typography} from "@material-ui/core";
import {
    SEVERITY_ERROR,
    TIIVII_API_STOP_ROOM
} from '../../../../../../utils/constants';
import {showMessage} from "../../../../../common/NotificationSnack";
import {useHistory} from "react-router-dom";
import {useIntl} from "react-intl";
import {ROUTE_INSTITUTION} from "../../../../../../utils/routes";
import Tooltip from '@material-ui/core/Tooltip';


import EditRoundedIcon from '@material-ui/icons/EditRounded';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import DesktopWindowsRoundedIcon from '@material-ui/icons/DesktopWindowsRounded';
import DesktopAccessDisabledRoundedIcon from '@material-ui/icons/DesktopAccessDisabledRounded';
import ConfirmationDialog from "../../../../../common/ConfirmationDialog";
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import InfoModalComponent from "./components/infoModalComponent/InfoModalComponent";

const useStyles = makeStyles(theme => getStyles(theme));

export default function RoomInfoComponent({classInfo, roomInfo, encoder, encoderAvailable, hidden, roomId, setEncoder, institutionId, handleOpenModalToEditClassroom, statusJson, stopIntervalRoomActive}) {

    const intl = useIntl();
    const history = useHistory();
    const classes = useStyles();

    const [openInfoModal, setOpenInfoModal] = useState(false);

    function handleCloseSession() {
        stopIntervalRoomActive();
        post(buildUrl(TIIVII_API_STOP_ROOM, {room_id: roomId}), {})
            .then(() => history.push(buildUrl(ROUTE_INSTITUTION, {institution_id: institutionId})))
            .catch(error => showMessage(error, SEVERITY_ERROR))
    }

    const handleOpenInfoModal = () => {
        setOpenInfoModal(!openInfoModal)
    }

    const encoderValue = encoder === 1

    return (
        <div className={classes.controlBox}>
            <div>
                <div className={classes.iconsContainer}>
                    <Tooltip title={intl.formatMessage({id: 'edit_class'})}>
                        <IconButton color={"secondary"} onClick={handleOpenModalToEditClassroom}>
                            <EditRoundedIcon fontSize="large"/>
                        </IconButton>
                    </Tooltip>
                    {encoderValue ?
                        <Tooltip
                            title={!encoderAvailable ? intl.formatMessage({id: 'encoder_disabled'}) : intl.formatMessage({id: 'stopProyector'})}>
                        <span>
                            <IconButton color={"secondary"} onClick={() => setEncoder(false)}
                                        disabled={encoderAvailable === 0}>
                        <DesktopAccessDisabledRoundedIcon fontSize="large"/>
                    </IconButton>
                        </span>
                        </Tooltip>
                        :
                        <Tooltip title={intl.formatMessage({id: 'startProyector'})}>
                        <span>
                            <IconButton color={"primary"} onClick={() => setEncoder(true)}
                                        disabled={encoderAvailable === 0}>
                            <DesktopWindowsRoundedIcon fontSize="large"/>
                        </IconButton>
                        </span>
                        </Tooltip>}
                    <Tooltip title={intl.formatMessage({id: 'classroom_info'})}>
                        <IconButton color={"secondary"} onClick={() => handleOpenInfoModal()}>
                            <InfoOutlinedIcon fontSize="large"/>
                        </IconButton>
                    </Tooltip>
                    <ConfirmationDialog
                        title={intl.formatMessage({id: 'class_close_connection_dialog_title'})}
                        helper={intl.formatMessage({id: 'class_close_connection_dialog_helper'})}
                        handleOk={handleCloseSession}>
                        {
                            handleClick =>
                                <Tooltip title={intl.formatMessage({id: 'endclassroom'})}>
                                    <IconButton color={"secondary"} onClick={handleClick}>
                                        <ExitToAppRoundedIcon fontSize="large"/>
                                    </IconButton>
                                </Tooltip>
                        }
                    </ConfirmationDialog>
                </div>
            </div>
            <div>
                {roomInfo && roomInfo.institution_description && <div>
                    <Tooltip title={roomInfo.institution_description}>
                        <Typography variant="inherit" className={classes.listElement}
                                    style={{fontWeight: "bold"}}>{roomInfo.institution_description}</Typography>
                    </Tooltip>
                </div>}
                {roomInfo && roomInfo.roomDescription && <div>
                    <Tooltip title={roomInfo.roomDescription}>
                        <div className={classes.listElement}>
                            <Typography variant="inherit"
                                        style={{fontWeight: "bold"}}>{intl.formatMessage({id: 'room'})}: </Typography>
                            <Typography variant="inherit">{roomInfo.roomDescription}</Typography>
                        </div>
                    </Tooltip>
                </div>}
                {classInfo && classInfo.classDescription && <div>
                    <Tooltip title={classInfo.classDescription}>
                        <div className={classes.listElement}>
                            <Typography variant="inherit"
                                        style={{fontWeight: "bold"}}>{intl.formatMessage({id: 'classroom'})}: </Typography>
                            <Typography variant="inherit">{classInfo.classDescription}</Typography>
                        </div>
                    </Tooltip>
                </div>}
                {classInfo && classInfo.teacherName && <div>
                    <Tooltip title={classInfo.teacherName}>
                        <div className={classes.listElement}>
                            <Typography variant="inherit"
                                        style={{fontWeight: "bold"}}>{intl.formatMessage({id: 'teacher'})}: </Typography>
                            <Typography variant="inherit">{classInfo.teacherName}</Typography>
                        </div>
                    </Tooltip>
                </div>}
            </div>
            <InfoModalComponent open={openInfoModal} handleClose={handleOpenInfoModal} statusJson={statusJson}
                                hidden={hidden}/>
        </div>
    )
}

RoomInfoComponent.propTypes = {
    encoder: PropTypes.number.isRequired,
    encoderAvailable: PropTypes.number.isRequired,
    roomId: PropTypes.string.isRequired,
    setEncoder: PropTypes.func.isRequired,
    institutionId: PropTypes.number.isRequired,
    handleOpenModalToEditClassroom: PropTypes.func.isRequired,
    stopIntervalRoomActive: PropTypes.func.isRequired
}