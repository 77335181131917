import {TIIVII_TENANT} from "./constants";

export const APP_BASE_ROUTE = '/' + process.env.REACT_APP_BASE_ROUTE + '/' + TIIVII_TENANT + '/';

export const ROUTE_LANDING = '/';
export const ROUTE_INSTITUTIONS = '/institutions';
export const ROUTE_INSTITUTIONS_COMPLETE = APP_BASE_ROUTE + 'institutions';
export const ROUTE_INSTITUTION = '/institutions/:institution_id';
export const ROUTE_INSTITUTION_COMPLETE = APP_BASE_ROUTE + 'institutions/:institution_id';
export const ROUTE_ROOMS = '/rooms';
export const ROUTE_ROOM = '/rooms/:room_id';
export const ROUTE_HISTORY = ROUTE_ROOM + '/institutions/:institution_id/history';
export const ROUTE_HELP = "/help";
export const ROUTE_PRIVACY_POLICY = "/privacy-policy";
export const ROUTE_PRIVACY_POLICY_COMPLETE = APP_BASE_ROUTE + 'privacy-policy';
