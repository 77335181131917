import React, {useState} from "react";
import './App.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {IntlProvider, useIntl} from "react-intl";
import ClassroomListComponent from "./components/classroomListComponent/ClassroomListComponent";
import AccessClassComponent from "./components/accessClassComponent/AccessClassComponent";
import NotificationSnack from "./components/common/NotificationSnack";
import GlobalLoadingComponent from "./components/globalLoadingComponent/GlobalLoadingComponent";
import InstitutionsComponent from "./components/institutionsListComponent/InstitutionsListComponent";
import AppBarComponent from "./components/appBarComponent/AppBarComponent";
import {MuiThemeProvider, createMuiTheme, makeStyles} from '@material-ui/core/styles';
import {
    APP_BASE_ROUTE, ROUTE_HELP, ROUTE_HISTORY,
    ROUTE_INSTITUTION,
    ROUTE_INSTITUTIONS,
    ROUTE_LANDING, ROUTE_PRIVACY_POLICY,
    ROUTE_ROOM,
    ROUTE_ROOMS
} from "./utils/routes";
import ErrorComponent from "./components/common/ErrorComponent";
import LandingComponent from "./components/landingComponent/LandingComponent";
import {ErrorBoundary} from "./components/errorBoundary/ErrorBoundaryComponent";
import {FooterComponent} from "./components/common/footerComponent/FooterComponent";
import HistoryTableComponent from "./components/historyTableComponent/HistoryTableComponent";
import {styles} from "./AppStyles";
import HelpComponent from "./components/helpComponent/HelpComponent";
import messages_es from "./translations/es.json";
import messages_en from "./translations/en.json";
import messages_gl from "./translations/gl.json";
import PrivacyPolicyContainer from "./components/common/privacyPolicyComponent/PrivacyPolicyContainer";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#049fe3'
        },
        secondary: {
            main: '#ff9800'
        }
    }
})

const useStyles = makeStyles(theme => styles(theme));
const messages = {
    'es': messages_es,
    'en': messages_en,
    'gl': messages_gl
};


export default function App() {
    const language = localStorage.getItem("locale") || navigator.language.split(/[-_]/)[0];
    const [locale, setLocale] = useState({locale: language, messages: messages[language]});

    const handleLanguageChanged = lang => {
        if (!!messages[lang]) {
            setLocale({locale: lang, messages: messages[lang]});
        }
    }
    const classes = useStyles();

    const [putGlobalFooter, setPutGlobalFooter] = useState(true);


    function NotFoundComponent() {
        const intl = useIntl();
        return <ErrorComponent error={intl.formatMessage({id: 'error_404'})}/>
    }

    return (
        <IntlProvider locale={locale.locale} messages={locale.messages} defaultLocale="es">
            <MuiThemeProvider theme={theme}>
                <div className={classes.app}>
                    <Router basename={APP_BASE_ROUTE}>
                        <AppBarComponent handleLanguageChanged={handleLanguageChanged}/>
                        <Switch>
                            <Route exact path={ROUTE_LANDING}>
                                <ErrorBoundary key={'landingErrorBoundary'}>
                                    <LandingComponent/>
                                </ErrorBoundary>
                            </Route>
                            <Route exact path={ROUTE_INSTITUTIONS}>
                                <ErrorBoundary key={'institutionErrorBoundary'}>
                                    <InstitutionsComponent/>
                                </ErrorBoundary>
                            </Route>
                            <Route exact path={ROUTE_INSTITUTION}>
                                <ErrorBoundary key={'classroomListErrorBoundary'}>
                                    <ClassroomListComponent/>
                                </ErrorBoundary>
                            </Route>
                            <Route exact path={ROUTE_ROOMS}>
                                <ErrorBoundary key={'roomsErrorBoundary'}>
                                    <ClassroomListComponent/>
                                </ErrorBoundary>
                            </Route>
                            <Route exact path={ROUTE_HELP}>
                                <ErrorBoundary key={'helpErrorBoundary'}>
                                    <HelpComponent/>
                                </ErrorBoundary>
                            </Route>
                            <Route exact path={ROUTE_ROOM} render={(matchProps) =>
                                <ErrorBoundary key={'classroomErrorBoundary'}>
                                    <AccessClassComponent
                                        {...matchProps}
                                        key={'classroomErrorBoundary'}
                                        handleChangePutGlobalFooter={(switchValue) => setPutGlobalFooter(switchValue)}
                                    />
                                </ErrorBoundary>
                            }/>
                            <Route exact path={ROUTE_HISTORY}>
                                <ErrorBoundary>
                                    <HistoryTableComponent/>
                                </ErrorBoundary>
                            </Route>
                            <Route exact path={ROUTE_PRIVACY_POLICY}>
                                <PrivacyPolicyContainer/>
                            </Route>
                            <Route path={'*'}>
                                <ErrorBoundary key={'errorCmpErrorBoundary'}>
                                    <NotFoundComponent/>
                                </ErrorBoundary>
                            </Route>
                        </Switch>
                        {putGlobalFooter && <FooterComponent classes={classes}/>}
                    </Router>
                    <NotificationSnack/>
                    <GlobalLoadingComponent/>
                </div>
            </MuiThemeProvider>
        </IntlProvider>
    );
}
