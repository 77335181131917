import React from 'react';

import {useKeycloak} from "@react-keycloak/web";

import ErrorComponent from "../ErrorComponent";
import {useIntl} from "react-intl";
import {hasRoles} from "../../../utils/keycloak";
import LoginComponent from "../../landingComponent/components/loginComponent/LoginComponent";


const withAuthorization = roles => Component => {

    const WithAuthorization = props => {
        const {keycloak, initialized} = useKeycloak();
        const intl = useIntl();

        const isAuthorized = () => {
            if (keycloak && roles) {
                return hasRoles(roles)
            }
            return false;
        }
        if (!initialized) {
            return <ErrorComponent error={intl.formatMessage({id: 'loading_description'})}/>
        } else {
            if (!keycloak.authenticated) {
                return <LoginComponent/>
            }
        }
        if (isAuthorized()) {
            return <Component {...props}/>
        }
        return <ErrorComponent error={intl.formatMessage({id: 'error_403'})}/>
    }
    return WithAuthorization;
};

export default withAuthorization;
