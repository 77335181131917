import React from 'react';
import * as Hls from 'hls.js';
import errorVideo from '../../../images/Error_Reproduccion_Video.png';
import {withStyles} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import {getStyles} from './HandleHlsStyles';

import PropTypes from "prop-types";
import {compose} from "redux";
import {autoplay} from "../../../utils/utils";

const INITIAL_STATE = {
    seconds: 0.0,
    poster: errorVideo,
    error: false
};

const styles = theme => (getStyles(theme));

class HandleHlsComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE, video: props.video};
        this.hls = null;
        this.videoRef = React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        if (state.video !== props.video) {
            return {poster: errorVideo, error: false, video: props.video}
        }
        return null;
    }

    componentDidMount() {
        const {video, handleDisableSwitch, play, handlePlayingState, handleAutoplayWarning} = this.props;
        const {poster} = this.state;
        if (poster) {
            handleDisableSwitch(video, true)
        }
        if (Hls.isSupported) {
            this.hls = new Hls();
            this.handleVideoHls(this.hls, video);
            this.interval = setInterval(this.updateCurrentTimestamp, 1000);
            if (play) {
                autoplay(this.videoRef, handlePlayingState, handleAutoplayWarning, play, poster);
            }
        } else {
            console.error('Hls is not supported')
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {handleDisableSwitch, video} = this.props
        if (prevProps.play !== this.props.play && video) {
            this.handlePlay(true)
        }
        if (prevState.error !== this.state.error) {
            handleDisableSwitch(video, this.state.error);
        }
        if (prevProps.video !== video && video) {
            handleDisableSwitch(video, this.state.error);
            this.handleVideoHls(this.hls, video);
            this.handlePlay(false);
        }
        if (prevState.poster !== this.state.poster) {
            handleDisableSwitch(video, this.state.error)
        }
    }

    componentWillUnmount() {
        this.hls.destroy();
        clearInterval(this.interval);
    }

    handleVideoHls = (hls, url) => {
        hls.attachMedia(this.videoRef.current);
        hls.on(Hls.Events.MEDIA_ATTACHED, () => {
            hls.loadSource(url);
            hls.on(Hls.Events.LEVEL_LOADED, (event, level) => {
                this.setState({error: false, poster: null});
                return level.details.fragments[0].programDateTime;
            });
        })
        hls.on(Hls.Events.ERROR, (event, data) => {
            if (data.fatal) {
                switch (data.type) {
                    case Hls.ErrorTypes.NETWORK_ERROR:
                        this.setState({poster: errorVideo, error: true});
                        hls.startLoad();
                        break;
                    case Hls.ErrorTypes.MEDIA_ERROR:
                        this.setState({poster: errorVideo, error: true});
                        hls.recoverMediaError();
                        this.forceUpdate();
                        break;
                    default:
                        hls.destroy();
                        break;
                }
            }
        })
    }

    handlePlay = value => {
        const {play, intl, video} = this.props;
        const {poster} = this.state;
        if (poster === null || !value) {
            this.videoRef.current.paused && play ?
                this.videoRef.current.play().catch(() => console.log(intl.formatMessage({id: "error_play"}), video)) :
                this.videoRef.current.pause()
        }
    };

    updateCurrentTimestamp = () => {
        if (!!this.videoRef.current && this.videoRef.current.currentTime) {
            this.setState({seconds: this.videoRef.current.currentTime})
        }
    }

    render() {
        const {classes, mute, size, width} = this.props;
        const {error, poster} = this.state;

        return (
            <>
                <video
                    id={size}
                    ref={this.videoRef}
                    controls={false}
                    height={"100%"}
                    width={width}
                    poster={poster}
                    muted={mute}
                />
                {poster !== null ? <div
                    className={error ? classes.error + ' ' + classes[size] : classes.loading + ' ' + classes[size]}>
                    <span className={classes.sign}> <b><FormattedMessage
                        id={`hls_load_${error ? "error" : "loading"}`}/></b></span>
                </div> : undefined}
            </>
        )
    }
}

HandleHlsComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    mute: PropTypes.bool.isRequired,
    size: PropTypes.string.isRequired,
    play: PropTypes.bool.isRequired,
    width: PropTypes.string.isRequired,
}

export default compose(
    injectIntl,
    withStyles(styles))(HandleHlsComponent);
