export const styles = theme => {
    return {
        footer: {
            height: "65px",
            width: "100%",
            position: "relative",
            boxShadow: "0px -2px 2px 2px rgba(0, 0, 0, 0.1)",
            backgroundColor: "white",
            [theme.breakpoints.down('lg')]: {
                display:"flex",
                justifyContent:"space-between",
                marginTop: 10,
                alignContent: "flex-end",
                boxShadow: "none",
            },
        },
        imageFooter: {
            position: "absolute",
            left: "20px",
            bottom: "10px",
            height: "42px",
            [theme.breakpoints.down('sm')]: {
                height: "30px",
                position: "static",
                margin: "20px"
            },
        },
        linksFooter: {
            cursor: "pointer",
            position: "absolute",
            right: "50px",
            top: "25px",
            color: "gray",
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.down('sm')]: {
                position: "static",
                marginRight: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-end",
                gap: "4px",
                fontSize: "0.7rem",
                textAlign: "right"
            }
        },
        link: {
            textDecoration: 'none',
            color: '#333',
            '&:hover': {
                textDecoration: 'underline',
            }
        },
        separation: {
            margin: "0 10px"
        }
    }
}