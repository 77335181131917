import React from "react";
import  svg from "../../../../images/institutions.svg"
import {styles} from "./InstitutionsIconStyles";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => styles(theme));
export default function InstitutionsIconComponent() {

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <img alt='' className={classes.img} src={svg}/>
        </div>
    );
}