import {CREATE_CLASSROOM, ADD_CLASSROOM_CREATE_PENDING} from "../actions/actionTypes";

const INITIAL_STATE = {
    classrooms: [],
    classroomPending: []
};

function classroom(state = INITIAL_STATE, action) {
    switch (action.type) {
        case CREATE_CLASSROOM : {
            return {
                classrooms: [...state.classrooms, action.classroom],
            };
        }
        case ADD_CLASSROOM_CREATE_PENDING: {
            return {
                classroomPending: [...state.classroomPending, action.classroomId],
            }
        }
        default :
            return state;
    }}

export default classroom;
