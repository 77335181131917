import React from "react";
import {getStyles} from "./StudentStatusStyles";
import {compose} from "redux";
import {withStyles, Button, Typography} from "@material-ui/core";
import {injectIntl} from "react-intl";
import {ROOM_INACTIVITY_DISCONNECT_TIMEOUT, ROOM_INACTIVITY_WARNING_TIMEOUT} from "../../../../../../utils/constants";
import PropTypes from "prop-types";

const styles = theme => (getStyles(theme));

const INITIAL_STATE = {
    active: true,
};

class StudentStatusComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE};
        this.warningTimeout = null;
        this.disconnectTimeout = null;
    }

    componentDidMount() {
        const {active} = this.state;
        if (active) {
            this.warningTimeout = setTimeout(this.warning, ROOM_INACTIVITY_WARNING_TIMEOUT);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.warningTimeout);
        clearTimeout(this.disconnectTimeout);
    }

    logout = () => {
        const {stopIntervalRoomActive, setKeepAlive} = this.props;
        this.setState({active: null});
        stopIntervalRoomActive();
        setKeepAlive(false);
    }

    warning = () => {
        const {handleOpenClassroomInformation} = this.props;
        this.setState({active: false});
        this.disconnectTimeout = setTimeout(this.logout, ROOM_INACTIVITY_DISCONNECT_TIMEOUT);
        window.innerWidth < 1280 && handleOpenClassroomInformation('infoConnectionRef');
    }

    resetTimer = () => {
        clearTimeout(this.disconnectTimeout);
        this.setState({active: true});
        this.warningTimeout = setTimeout(this.warning, ROOM_INACTIVITY_WARNING_TIMEOUT);
    }

    connectAgain = () => {
        const {setKeepAlive} = this.props;
        this.setState({active: true});
        this.warningTimeout = setTimeout(this.warning, ROOM_INACTIVITY_WARNING_TIMEOUT);
        setKeepAlive(true);
    }


    render() {
        const {classes, intl, roomInfo, classInfo} = this.props;
        const {active} = this.state;

        return (
            <div className={classes.list}>
                <div className={classes.statusHeader}>
                    <Typography variant="inherit"
                                style={{fontWeight: "bold"}}>{intl.formatMessage({id: 'state'})}: </Typography>
                    <Typography variant="inherit" style={{fontWeight: "bold"}} color={active ? "primary" : "secondary"}>
                        {active !== null ? intl.formatMessage({id: 'connected'}) : intl.formatMessage({id: 'disconnected'})}
                    </Typography>
                </div>
                <div>
                    {active === false &&
                    <Button className={classes.button} variant="contained" color={"primary"} onClick={this.resetTimer}
                            style={{color: "white"}}>
                        {intl.formatMessage({id: 'continue'})}
                    </Button>
                    }
                    {active === null &&
                    <div>
                        <p className={classes.statusHeader}>{intl.formatMessage({id: 'closed_session'})}</p>
                        <Button className={classes.button} variant="contained" color={"secondary"}
                                onClick={this.connectAgain}
                                style={{color: "white"}}>
                            {intl.formatMessage({id: 'reconnect'})}
                        </Button>
                    </div>
                    }
                </div>
                {roomInfo && classInfo && <div>
                    <div className={classes.listElement}>
                        <Typography variant="inherit"
                                    style={{fontWeight: "bold"}}>{roomInfo.institution_description}</Typography>
                    </div>
                    <div className={classes.listElement}>
                        <Typography variant="inherit"
                                    style={{fontWeight: "bold"}}>{intl.formatMessage({id: 'room'})}: </Typography>{roomInfo && roomInfo.roomDescription ? roomInfo.roomDescription : ""}
                    </div>
                    <div className={classes.listElement}>
                        <Typography variant="inherit"
                                    style={{fontWeight: "bold"}}>{intl.formatMessage({id: 'classroom'})}: </Typography>{classInfo && classInfo.classDescription ? classInfo.classDescription : ""}
                    </div>
                    <div className={classes.listElement}>
                        <Typography variant="inherit"
                                    style={{fontWeight: "bold"}}>{intl.formatMessage({id: 'teacher'})}: </Typography>{classInfo && classInfo.teacherName ? classInfo.teacherName : ""}
                    </div>
                </div>}
            </div>
        )
    }

}

StudentStatusComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    setKeepAlive: PropTypes.func.isRequired
}

export default compose(
    withStyles(styles),
    injectIntl,
)(StudentStatusComponent);
