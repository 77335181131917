import React, {useEffect, useRef, useState} from 'react';
import {Hidden, Link} from "@material-ui/core";
import {useIntl} from "react-intl";
import {showMessage} from "../common/NotificationSnack";
import {
    SEVERITY_ERROR,
    TIIVII_API_GET_ROOMS,
    TIIVII_API_GET_ROOMS_BY_INSTITUTION,
    ROOM_LIST_RELOAD_TIMEOUT,
    ROLES_NOT_STUDENT,
    ROLES_ALL
} from "../../utils/constants";
import {buildUrl, get} from "../../utils/axiosRequests";
import {useKeycloak} from "@react-keycloak/web";
import {useHistory, useParams} from "react-router-dom";

import AuthorizedElement from "../common/auth/AuthorizedElement";
import withAuthorization from "../common/auth/withAuthorization";
import ClassroomFormComponent from "../common/classroomFormComponent/ClassroomFormComponent";
import {
    ROUTE_HISTORY,
    ROUTE_INSTITUTION_COMPLETE,
    ROUTE_INSTITUTIONS_COMPLETE,
    ROUTE_ROOM,
    ROUTE_ROOMS
} from "../../utils/routes";
import {makeStyles} from "@material-ui/core/styles";
import PageHeaderComponent from "../common/pageHeaderComponent/PageHeaderComponent";
import Paper from "@material-ui/core/Paper";
import NoAllowedComponent from "../common/noAllowedComponent/NoAllowedComponent";
import {styles} from "../../AppStyles";
import TableListComponent from "./components/tableListComponent/TableListComponent";
import AccordionListComponent from "./components/accordionListComponent/AccordionListComponent";

import PropTypes from "prop-types";
import {setClassroomData} from "../../actions/classroomListActions";
import {compose} from "redux";
import {connect} from "react-redux";
import {turnOnLoadingActionCreator} from "../../actions/commonActions";
import {postAskForAccessService} from "../../services/commonServices";

const useStyles = makeStyles(theme => styles(theme));

function ClassroomListComponent(props) {

    const [rooms, setRooms] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [teacherName, setTeacherName] = useState('');
    const [roomDataToEdit, setRoomDataToEdit] = useState({});
    const classes = useStyles();
    const {keycloak} = useKeycloak();
    const history = useHistory();
    const intl = useIntl();
    const {institution_id} = useParams();
    const institution_idRef = useRef(null);
    institution_idRef.current = institution_id;

    useEffect(() => {
        const interval = setInterval(() => {
            const config = false
            const loading = false;
            getListRooms(institution_idRef.current, config, loading)
        }, ROOM_LIST_RELOAD_TIMEOUT);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        getListRooms(institution_id);
    }, [institution_id]);

    useEffect(() => {
        keycloak.loadUserProfile().then(response => {
            setTeacherName(response.firstName + ' ' + response.lastName);
        }).catch(error => showMessage(error, SEVERITY_ERROR));
    }, [keycloak]);

    const getListRooms = (institutionId, config = false, loading = true) => {
        const url = !!institutionId
            ? buildUrl(TIIVII_API_GET_ROOMS_BY_INSTITUTION, {institutionId})
            : TIIVII_API_GET_ROOMS;
        get(url, config, loading)
            .then(response => {
                setRooms(response.data);
            })
            .catch(error => showMessage(error, SEVERITY_ERROR));
    }

    const handleGoToRoom = roomId => {
        postAskForAccessService(roomId).then().catch();
        const url = buildUrl(ROUTE_ROOM, {room_id: roomId});
        history.push(url);
    }

    const handleGoToHistory = room => {
        const {setClassroomData} = props;

        const url = buildUrl(ROUTE_HISTORY, {room_id: room.room_id, institution_id: room.institution_id});
        setClassroomData(room);
        history.push(url);
    };

    const handleOpenToCreateRoom = (roomId, roomDescription, roomEventId) => {
        setRoomDataToEdit({
            roomId, roomDescription, roomEventId, classTeacher: teacherName
        })
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
        getListRooms(institution_id);
    };

    const SECTION_TITLE = intl.formatMessage({id: 'classroom_list'});
    const BREAD_CRUMBS = <AuthorizedElement roles={ROLES_NOT_STUDENT}> <span>
                                <Link className={classes.link} href={ROUTE_INSTITUTIONS_COMPLETE}
                                      underline="none">{intl.formatMessage({id: 'institution_list'})}</Link>
                                <span> > </span>
                                <Link className={classes.link}
                                      href={institution_id ? buildUrl(ROUTE_INSTITUTION_COMPLETE, {institution_id}) : ROUTE_ROOMS}
                                      underline="none"> {intl.formatMessage({id: 'classroom_list'})}</Link></span>
    </AuthorizedElement>

    if (rooms.length > 0) {
        return <div className={'pageContainer'}>
            <PageHeaderComponent title={SECTION_TITLE} breadCrumbs={BREAD_CRUMBS}/>
            {rooms.length > 0 ? <AuthorizedElement roles={ROLES_ALL}>
                    <Hidden mdDown>
                        <Paper elevation={5} className={classes.tableList}>
                            <TableListComponent rooms={rooms} handleGoToRoom={handleGoToRoom}
                                                handleOpenToCreateRoom={handleOpenToCreateRoom}
                                                handleGoToHistory={handleGoToHistory}/>
                        </Paper>
                    </Hidden>
                    <Hidden lgUp>
                        <AccordionListComponent rooms={rooms} handleGoToRoom={handleGoToRoom}
                                                handleOpenToCreateRoom={handleOpenToCreateRoom}
                                                handleGoToHistory={handleGoToHistory}/>
                    </Hidden>
                    <ClassroomFormComponent open={openModal}
                                            getListRooms={() => getListRooms(institution_id)}
                                            turnOnLoading={props.turnOnLoading}
                                            handleGoToRoom={handleGoToRoom}
                                            roomDataToEdit={roomDataToEdit}
                                            handleClose={handleClose}/>
                </AuthorizedElement> :
                <NoAllowedComponent title={'no_class_title'} subtitle={'no_class_subtitle'}/>}
        </div>
    } else {
        return <div className={'pageContainer'}>
            <PageHeaderComponent title={SECTION_TITLE} breadCrumbs={BREAD_CRUMBS}/>
            <NoAllowedComponent title={'no_class_title'} subtitle={'no_class_subtitle'}/>
        </div>
    }
    ;
}

const mapDispatchToProps = (dispatch) => ({
    setClassroomData: (data) => dispatch(setClassroomData(data)),
    turnOnLoading: () => dispatch(turnOnLoadingActionCreator())
});

ClassroomListComponent.propTypes = {
    setClassroomData: PropTypes.func,
    turnOnLoadingActionCreator: PropTypes.func
};

export default compose(
    withAuthorization(ROLES_ALL),
    connect(null, mapDispatchToProps))
(ClassroomListComponent)


