import React from 'react';
import TitlePageComponent from "./components/TitlePageComponent";
import BreadCrumbsComponent from "./components/BreadCrumbsComponent";
import { useStyles } from "./PageHeaderStyles";
import {Hidden} from "@material-ui/core";

import PropTypes from "prop-types";

export default function PageHeaderComponent(props) {

    const {title, breadCrumbs} = props;
    const classes = new useStyles();

    return (
        <div className={classes.root}>
            <TitlePageComponent title={title} classes={classes}/>
            <Hidden mdDown>
                <BreadCrumbsComponent breadCrumbs={breadCrumbs} classes={classes}/>
            </Hidden>
        </div>
    )
}

PageHeaderComponent.propTypes = {
    title: PropTypes.string.isRequired,
    breadCrumbs: PropTypes.object
}
