import {
    CHANGE_LANGUAGE,
    SET_CLASSROOM_ACTIVE_USERS_ACTION,
    TURN_OFF_LOADING_ACTION,
    TURN_ON_LOADING_ACTION,
    SET_LOGGED_USER_ACTION
} from "./actionTypes";

export const changeLanguage = (data) => ({
   type: CHANGE_LANGUAGE,
    data
});
export const turnOffLoadingActionCreator = () => ({
    type: TURN_OFF_LOADING_ACTION
});

export const turnOnLoadingActionCreator = () => ({
    type: TURN_ON_LOADING_ACTION
});

export const setClassroomActiveUserCreator = (data) => ({
    type: SET_CLASSROOM_ACTIVE_USERS_ACTION,
    data
})

export const setLoggedUserActionCreator = (data) => ({
    type: SET_LOGGED_USER_ACTION,
    data
})