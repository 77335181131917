import React from 'react';
import {useIntl} from "react-intl";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {getStyles} from "./InfoModalStyles";

import PropTypes from "prop-types";
import {setIconColor, setIconTooltip, setNotAvailableTooltip} from "../../../../../../../../utils/utils";
import CastRoundedIcon from "@material-ui/icons/CastRounded";
import VideocamRoundedIcon from "@material-ui/icons/VideocamRounded";
import PanoramaRoundedIcon from "@material-ui/icons/PanoramaRounded";
import SettingsInputHdmiRoundedIcon from "@material-ui/icons/SettingsInputHdmiRounded";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import VisibilityOffRoundedIcon from "@material-ui/icons/VisibilityOffRounded";
import {makeStyles} from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => getStyles(theme));

function InfoModalComponent(props) {

    const intl = useIntl();
    const classes = useStyles();

    const {open, handleClose, statusJson, hidden} = props;

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth={'sm'}>
            <DialogTitle id="classroom_info">
            </DialogTitle>
            <DialogContent>
                <div className={classes.container}>
                    <div className={classes.tableRow}>
                        <CastRoundedIcon fontSize={"large"} className={classes.icon}
                                         color={statusJson && statusJson.camera_config ? setIconColor(statusJson.camera_config) : "disabled"}/>
                        <Typography>{statusJson && statusJson.camera_config ?
                            setIconTooltip("class_icon_camera_config", statusJson.last_check, statusJson.camera_config, intl)
                            : setNotAvailableTooltip("class_icon_camera_config", intl)}</Typography>
                    </div>
                    <Divider/>
                    <div className={classes.tableRow}>
                        <VideocamRoundedIcon fontSize={"large"} className={classes.icon}
                                             color={statusJson && statusJson.camera_rtsp ? setIconColor(statusJson.camera_rtsp) : "disabled"}/>
                        <Typography>{statusJson && statusJson.camera_rtsp ?
                            setIconTooltip("class_icon_camera_rtsp", statusJson.last_check, statusJson.camera_rtsp, intl)
                            : setNotAvailableTooltip("class_icon_camera_rtsp", intl)}</Typography>
                    </div>
                    <Divider/>
                    <div className={classes.tableRow}>
                        <PanoramaRoundedIcon fontSize={"large"} className={classes.icon}
                                             color={statusJson && statusJson.camera_jpg_capture ? setIconColor(statusJson.camera_jpg_capture) : "disabled"}/>
                        <Typography>{statusJson && statusJson.camera_jpg_capture ?
                            setIconTooltip("class_icon_camera_jpg_capture", statusJson.last_check, statusJson.camera_jpg_capture, intl)
                            : setNotAvailableTooltip("class_icon_camera_jpg_capture", intl)}</Typography>
                    </div>
                    <Divider/>
                    <div className={classes.tableRow}>
                        <SettingsInputHdmiRoundedIcon fontSize={"large"} className={classes.icon}
                                                      color={statusJson && statusJson.encoder_rtsp ? setIconColor(statusJson.encoder_rtsp) : "disabled"}/>
                        <Typography>{statusJson && statusJson.encoder_rtsp ?
                            setIconTooltip("class_icon_encoder_rtsp", statusJson.last_check, statusJson.encoder_rtsp, intl)
                            : setNotAvailableTooltip("class_icon_encoder_rtsp", intl)}</Typography>
                    </div>
                    <Divider/>
                    <div className={classes.tableRow}>
                        {!hidden ?
                            <VisibilityRoundedIcon color={"primary"} fontSize={"large"} className={classes.icon}/> :
                            <VisibilityOffRoundedIcon color={"secondary"} fontSize={"large"} className={classes.icon}/>}
                        <Typography>{intl.formatMessage({id: 'class_icon_visibility_on'})}</Typography>
                    </div>
                </div>
            </DialogContent>
            <DialogActions className={classes.buttonsGroupMobile}>
                <Button onClick={handleClose} color="secondary" variant="contained"
                        style={{fontWeight: 'bold', color: '#fff'}}>
                    {intl.formatMessage({id: 'global_buttons_close'})}
                </Button>
            </DialogActions>
        </Dialog>)
}

InfoModalComponent.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleGoToRoom: PropTypes.func,
    getListRooms: PropTypes.func
}

export default InfoModalComponent;
