import {combineReducers} from "redux";
import classroom from './classroom';
import commonState from "./commonState";
import classroomListState from "./classroomListState";

export default combineReducers({
    classroom,
    classroomListState,
    commonState
})
