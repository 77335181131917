import React from "react";
import logoCinfo from '../../../images/logo_cinfo.svg'
import {makeStyles} from "@material-ui/core/styles";
import {styles} from './LoadingComponentStyles';
import {FormattedMessage} from 'react-intl';

import PropTypes from "prop-types";

const useStyles = makeStyles(theme => (styles(theme)));

export default function LoadingComponent({description, open}) {
    const classes = useStyles();
    return (
        <div className={!open ? `${classes.disable} ${classes.root} pageContainer` : `${classes.root} pageContainer`}>
            <div className={classes.imageContainer}>
                <img src={logoCinfo} className="App-logo" alt="logo"/>
                {description && <p className={classes.description}><FormattedMessage id="loading_description"/></p>}
            </div>
        </div>)
}

LoadingComponent.propTypes = {
    description: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
}